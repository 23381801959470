import React from 'react';
import { useSelector } from 'react-redux';
import { API_URL_1 } from 'supports/api-url/apiurl';

import menu from 'assets/images/svg/ic_menuburger.svg';
import ic_close from 'assets/images/svg/ic_close_white_lg.svg';
import ic_arrow from './image/ic_arrow_green.svg';
import ic_cart from './image/ic_cart.svg';
import ic_profile from './image/ic_profile.svg';

export default ({
  name,
  dropdownActive,
  setDropdownActive,
  handleActiveMenu,
  activeMenu,
  content,
  className,
  auth,
  isFullWidth = false,
}) => {
  const cart = useSelector((state) => state.cart);
  const authUser = useSelector((state) => state.auth);
  const { transaction } = authUser.user;
  const carts = Object.values(cart.cart);
  const handleClickMenu = async () => {
    await setDropdownActive();
    await handleActiveMenu(name);
  };

  const renderNavbarToggler = () => {
    if (name === 'onlineCourses') {
      return (
        <div
          className="navbar-button-tab cursor-pointer"
          style={{ width: '100%', zIndex: '2002' }}
          onClick={handleClickMenu}
          onKeyDown={handleClickMenu}
        >
          <img
            src={dropdownActive && activeMenu === name ? ic_close : menu}
            alt="menuburger"
          />
        </div>
      );
    }
    if (name === 'CartNav') {
      return (
        <div
          className="cartNavBox"
          onClick={handleClickMenu}
          onKeyDown={handleClickMenu}
        >
          <img src={ic_cart} alt="cart-navbar" className="cart-navbar" />

          {carts.length > 0 ? (
            <div className="countCart">{carts.length}</div>
          ) : null}
        </div>
      );
    }
    return (
      <div className="d-flex flex-row align-items-center">
        {name === 'ProfileNav' && (
          <div>
            <img
              src={
                auth &&
                auth.user.profilePicture === '/defaultprofilepicture.png'
                  ? ic_profile
                  : API_URL_1 + auth.user.profilePicture
              }
              alt="ic_profile"
              className="ic_profileNav"
            />
            {/* {transaction.length > 0 && (
              <div
                className="countTransactionNav"
                style={{
                  paddingLeft: `${transaction.length === 1 ? '0px' : '1px'}`,
                }}
              >
                {transaction.length}
              </div>
            )} */}
            {transaction ? (
              transaction.length > 0 ? (
                <div
                  className="countTransactionNav"
                  style={{
                    paddingLeft: `${transaction.length === 1 ? '0px' : '1px'}`,
                  }}
                >
                  {transaction.length}
                </div>
              ) : null
            ) : null}
          </div>
        )}
        <div
          className={`navItem ${
            dropdownActive && activeMenu === name ? 'isActive' : ''
          }`}
          onClick={handleClickMenu}
          onKeyDown={handleClickMenu}
        >
          <span>
            {name === 'ProfileNav' && auth && auth.user.email ? (
              <span>{`${
                auth.user.firstName.length < 8
                  ? `${auth.user.firstName}...`
                  : `${auth.user.firstName}`
              }`}</span>
            ) : (
              <span>{name}</span>
            )}
          </span>
          <img
            src={ic_arrow}
            alt="arrow-item"
            className={`arrow-nav ${
              dropdownActive && activeMenu === name ? 'rotateArrowNav' : ''
            }`}
          />
        </div>
      </div>
    );
  };
  return (
    <div
      style={{
        position: isFullWidth ? '' : 'relative',
        width: '100%',
        height: `${'104%'}`,
        zIndex: '2005',
      }}
    >
      {renderNavbarToggler()}
      <div
        className={`dropdownMenu transitionDropdown ${className} ${
          dropdownActive && activeMenu === name ? 'm-fadeIn' : 'm-fadeOut'
        }`}
      >
        {content}
      </div>
    </div>
  );
};
