import React, { useEffect, lazy, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useLocation } from 'react-router-dom';
import { hotjar } from 'react-hotjar';
import ReactGA from 'react-ga';
import TiktokPixel from 'tiktok-pixel';
import { SignInModal } from 'components/modals';
import { checkToken } from 'redux/modules/auth';
import { getCart } from 'redux/modules/cart';
import { fetchFeatureFlag } from 'redux/modules/feature-flag';
import { fetchGeolocation } from 'redux/modules/geolocation';
import 'utils/FAIcons';
import Mixpanel from 'supports/helpers/mixpanel';

import moment from 'moment-timezone';

// Helmet
import { Helmet } from 'react-helmet';
import PixelRegistrationConversionHelmet from 'components/PixelRegistrationConversionHelmet';

// CSS
import 'assets/css/util.css';
import 'assets/css/bootstrap.css';
import 'assets/css/app.css';
import 'assets/css/animate.css';
import 'assets/css/general.css';
import 'assets/css/fonts.css';
import 'assets/css/buttons.css';
import 'aos/dist/aos.css';
import 'assets/css/dropzone.css';

// Homepage old but still used
import 'pages/home-old/home.css';
// SignUpPage
import 'pages/sign-up/signup.css';
// WorkshopPage, OnlineWorkshop
import 'components/WorkshopEvent/WorkshopPage.css';

// Cart
import 'components/Cart/Cart.css';

// Courses -> disabled
// import 'pages/courses/courses.css';

// CoursesPageNew, srp_OnlineCourses
import 'components/Course/CoursesPage.css';
// InfoSession, ScholarshipDetail, ProgramPage, Jconlinepage, CodeStarter
import 'components/Program/ProgramPage/programpage.css';

// // NewsPage -> Moved to original source
// import 'pages/media-press/index.css';

// // AboutUs -> Moved to original source
// import 'pages/about-us/about.css';

// MaintenancePage & PageNotFdund
import 'pages/maintenance-page/maintenance-page.css';

// // Scholarship -> Moved to original source
// import 'pages/scholarship/scholarship.css';

// // ScholarshipDetail -> Moved to original source
// import 'pages/scholarship-detail/index.css';

// CorporateTraining
import 'components/corporateTraining/corporate-training.css';
// Dashboard
import 'pages/dashboard/dashboard.css';

// // Brandassets -> Moved to original source
// import 'pages/brandassets/brandassets.css';

// Alumnipagenew, srp_OnlineCourses
import 'pages/alumni/alumni.css';

// VerifyCertificate
import 'pages/verify-certificate/certificate.css';

// // OnlineCourses -> unused?
// import 'pages/onlineCourses/quiz.css';

// srp_OnlineCourses
import 'pages/onlineCourses/pages/component/srp_online.css';
// DigitalSign
import 'pages/digital-sign/digital-sign.css';
// StudentLearning
import 'pages/student-learning/student-learning.css';
// ExamTestPage
import 'pages/exam/exam-styles.css';
// UnderConscturctionPage
import UnderConstruction from 'components/UnderMaintenance/UnderMaintenance';

// MAIN
import Navbar from 'components/navbar';
import Footer from 'components/Footer';

const Homepage = lazy(() => import('pages/home'));

// AUTHENTICATION

const StudentInfo = lazy(() => import('pages/student-info'));
const StudentCertificate = lazy(() =>
  import('pages/student-info/certificate-page'),
);
const SignUpPage = lazy(() => import('pages/sign-up'));
const NewPasswordPage = lazy(() =>
  import('components/Authentication/NewPasswordPage'),
);
const ForgotPasswordPage = lazy(() =>
  import('components/Authentication/ForgotPasswordPage'),
);

// PROGRAMS
const WorkshopPage = lazy(() =>
  import('components/WorkshopEvent/WorkshopPage'),
);
// const CoursesPage = lazy(() => import('pages/courses'));
const CoursesPageNew = lazy(() => import('components/Course/CoursesPageNew'));
// const InfoSession = lazy(() => import('pages/info-session'));
// const TrialVideo = lazy(() => import('pages/trial-video'));
const InternationalProgramPage = lazy(() =>
  import('pages/international-program'),
);

// ADDITIONAL
const NewsPage = lazy(() => import('pages/media-press'));
const AboutUsPage = lazy(() => import('pages/about-us'));
const MaintenancePage = lazy(() =>
  import('pages/maintenance-page/Maintenance'),
);
const PageNotFound = lazy(() =>
  import('pages/maintenance-page/PageNotFound-new'),
);
const PrivacyPolicy = lazy(() => import('pages/privacy'));

const EmailConfirmation = lazy(() =>
  import('components/Additional/EmailConfirmation'),
);
const ChangePasswordSuccess = lazy(() =>
  import('components/Additional/ChangePasswordSuccess'),
);
const ChangePasswordRequest = lazy(() =>
  import('components/Additional/ChangePasswordRequest'),
);

const ScholarshipListPage = lazy(() => import('pages/scholarship'));
const ScholarshipDetailPage = lazy(() => import('pages/scholarship-detail'));

const CorporateTrainingPage = lazy(() => import('pages/corporate-training'));

const Dashboard = lazy(() => import('pages/dashboard'));

const CartPage = lazy(() => import('components/Cart/CartPage'));

const BrandAssets = lazy(() => import('pages/brandassets/brandassets'));
const AlumniPageNew = lazy(() => import('pages/alumni/alumnistories'));

// const OutcomeReport = lazy(() =>
//   import('components/OutcomeReport/OutcomeReport'),
// );

// const ProgramPage = lazy(() => import('components/program-details'));
const ProgramPage = lazy(() => import('pages/program'));
const ContactUsPage = lazy(() => import('pages/contact-us'));
const OnlineWorkshop = lazy(() =>
  import('components/WorkshopEvent/OnlineWorkshopPage'),
);

const VerifyCertificatePage = lazy(() => import('pages/verify-certificate'));
// const HiringPartner = lazy(() => import('pages/hiringpartner'));
// const OnlineCoursesPage = lazy(() => import('pages/onlineCourses'));
const SRP_OnlineCourses = lazy(() =>
  import('pages/onlineCourses/pages/srp_onlineCourses'),
);
// const ExecutiveProgramRoutes = lazy(() =>
//   import('components/executive-program/components/ExecutiveProgramRoutes'),
// );
const PartTimeRoutes = lazy(() => import('components/executive-program-new'));
const DigitalSign = lazy(() => import('pages/digital-sign'));
const StudentLearning = lazy(() => import('pages/student-learning'));
const ExamTestPage = lazy(() => import('pages/exam/ExamTestPage'));
const ExamResultPage = lazy(() => import('pages/exam/ExamResultPage'));
const PayLaterPage = lazy(() => import('pages/pay-later'));
const CommunityPartnerPage = lazy(() => import('pages/community-partner'));
const LocationPage = lazy(() => import('pages/location'));
const PromoPage = lazy(() => import('pages/promo'));
const PromoDetailPage = lazy(() => import('pages/promo-detail'));

moment.tz.setDefault('Asia/Jakarta');

const App = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { user, tokenChecked } = useSelector((state) => state.auth);
  const { showUnderMaintenancePage } = useSelector(
    (state) => state.featureFlag,
  );

  useEffect(() => {
    TiktokPixel.init('CFCB5RBC77U208RT9QS0');
  }, []);

  useEffect(() => {
    hotjar.initialize(3189843);
    ReactGA.initialize('UA-102082580-2', {
      gaOption: {
        siteSpeedSampleRate: 100,
      },
    });
  }, []);

  useEffect(() => {
    dispatch(checkToken());
    dispatch(fetchFeatureFlag());
    dispatch(fetchGeolocation());
  }, [dispatch]);

  useEffect(() => {
    if (user.id) {
      dispatch(getCart(user.id));
    }
  }, [dispatch, user.id]);

  // google analytics page views
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  useEffect(() => {
    if (tokenChecked) {
      Mixpanel.register({
        isUser: Boolean(user.id),
      });
    }
  }, [tokenChecked, user.id]);

  const showFooter = Boolean(
    !location.pathname.includes('/dashboard') &&
      !location.pathname.includes('/v/') &&
      !location.pathname.includes('/student-learning') &&
      !location.pathname.includes('/exam/') &&
      !showUnderMaintenancePage,
  );

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Purwadhika berdiri sejak 1987 sebagai training center teknologi digital dan telah mencetak +30,000 talenta digital berkualitas."
        />
      </Helmet>
      <div className="container-fluid">
        {/* {tokenChecked && user?.email && (
          <Helmet>
            Facebook Pixel Advanced Matching Parameter Code
            <script>
              {`
          fbq('init', '712810412755164', {
            em: '${user.email}',
            fn: '${user.firstName}',
            ln: '${user.lastName}'
          });
          fbq('track', 'PageView');
          `}
            </script>
          </Helmet>
        )} */}

        {location?.state?.registrationComplete && (
          <PixelRegistrationConversionHelmet />
        )}

        <div className="row body-app">
          <div className="col-12 p-0" style={{ minHeight: '80vh' }}>
            <Suspense fallback={<></>}>
              {showUnderMaintenancePage ? (
                <Switch>
                  <Route path="*" component={UnderConstruction} />
                </Switch>
              ) : (
                <>
                  <Navbar />
                  <Switch>
                    <Route exact path="/" component={Homepage} />
                    {/* <Route exact path="/home-old" component={HomepageOld} /> */}
                    <Route
                      exact
                      path="/exam/:examTestId"
                      component={ExamTestPage}
                    />
                    <Route
                      exact
                      path="/exam-result/:examTestId"
                      component={ExamResultPage}
                    />
                    {/* <Route
                      exact
                      path="/hiring-partner"
                      component={HiringPartner}
                    /> */}
                    {/* <Route path="/signin" component={SignInPage} /> */}
                    <Route path="/sign-up" component={SignUpPage} />
                    <Route
                      path="/forgot-password"
                      component={ForgotPasswordPage}
                    />
                    <Route path="/reset" component={NewPasswordPage} />
                    <Route path="/dashboard" component={Dashboard} />
                    <Route path="/cart" component={CartPage} />
                    <Route path="/event/:title/:id" component={WorkshopPage} />
                    <Route
                      path="/workshop/:title/:id"
                      component={WorkshopPage}
                    />
                    <Route
                      path="/online-workshop/:title"
                      component={OnlineWorkshop}
                    />
                    <Route
                      path="/corporate-training"
                      component={CorporateTrainingPage}
                    />
                    <Route path="/brand-assets" component={BrandAssets} />
                    <Route path="/alumni-stories" component={AlumniPageNew} />
                    <Route
                      exact
                      path="/scholarship"
                      component={ScholarshipListPage}
                    />
                    <Route
                      path="/scholarship/:slug/:scholarshipId"
                      component={ScholarshipDetailPage}
                    />
                    <Route path="/media-press" component={NewsPage} />
                    <Route path="/our-stories" component={AboutUsPage} />
                    {/* <Route path="/maintenance" component={MaintenancePage} /> */}
                    <Route path="/confirmation" component={EmailConfirmation} />
                    <Route
                      path="/reset-success"
                      component={ChangePasswordSuccess}
                    />
                    <Route
                      path="/reset-request"
                      component={ChangePasswordRequest}
                    />
                    <Route
                      path="/verify-certificate"
                      component={VerifyCertificatePage}
                    />
                    {/* <Route path="/brand-assets" component={BrandAssets} /> */}
                    <Route path="/courses/v2" component={SRP_OnlineCourses} />
                    <Route path="/courses" component={CoursesPageNew} />
                    {/* <Route path="/courses-new" component={CoursesPage} /> */}
                    {/* <Route
                      path="/job-connector-outcome-report"
                      component={OutcomeReport}
                    /> */}
                    <Route
                      path="/student-certificate/:certificate_number"
                      component={StudentCertificate}
                    />
                    <Route path="/student-form" component={StudentInfo} />
                    <Route path="/privacy" component={PrivacyPolicy} />
                    <Route
                      path="/underconstruction"
                      component={MaintenancePage}
                    />
                    {/* <Route
                      path="/v/:slug/:video_id"
                      component={OnlineCoursesPage}
                    /> */}
                    <Route path="/not-found" component={PageNotFound} />
                    {/* <Route path="/info-session/:slug" component={InfoSession} /> */}
                    {/* <Route path="/trial-video/:slug" component={TrialVideo} /> */}
                    <Route
                      path="/skill-accelerator/:individual_slug"
                      component={PartTimeRoutes}
                    />
                    <Route path="/digital-sign" component={DigitalSign} />
                    <Route
                      path="/student-learning"
                      component={StudentLearning}
                    />
                    <Route
                      path="/belajar-sekarang-bayar-setelah-kerja"
                      component={PayLaterPage}
                    />
                    {/* temporary disable */}
                    {/* <Route
                      path="/community-partner"
                      component={CommunityPartnerPage}
                    /> */}
                    <Route path="/location/:slug" component={LocationPage} />
                    <Route
                      path="/contact-us"
                      component={ContactUsPage}
                      render={(props) => <ContactUsPage {...props} />}
                    />
                    <Route
                      path="/international/:slug"
                      component={InternationalProgramPage}
                    />
                    <Route exact path="/promo" component={PromoPage} />
                    <Route path="/promo/:slug" component={PromoDetailPage} />
                    <Route path="/:slug" component={ProgramPage} />
                  </Switch>
                </>
              )}
              <SignInModal />
            </Suspense>
          </div>
        </div>
      </div>
      <Suspense fallback={<></>}>{showFooter && <Footer />}</Suspense>
    </>
  );
};

export default App;
