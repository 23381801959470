/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap';
import 'assets/css/icons.css';
import 'assets/css/forms.css';
import { useLocation } from 'react-router-dom';

import axios from 'supports/api';
import pathList from 'supports/helpers/pathList';
import { CircleSpinner } from 'react-spinners-kit';
import AdsConversionHelmet from 'components/AdsConversionHelmet';
import closeicon from 'assets/images/svg/crossbutton.svg';
import svginfo from 'assets/images/svg/svginfo.svg';
import svginfowarning from 'assets/images/svg/svginfowarning.svg';
import PButton from 'components/PButton';
import PInput from 'components/PInput';
import PTextArea from 'components/PTextArea';
import registersuccess from 'assets/images/jcmodalimages/registersuccess.png';
import contactsuccess from 'assets/images/jcmodalimages/contactsuccess.png';
import mailpricesuccess from 'assets/images/jcmodalimages/mailpricesuccess.png';
// ICONS

import chevrondown from 'assets/images/svg/form/chevrondown.svg';
import useMekariQontak from 'hooks/useMekariQontak';
import PInputPhoneNumber from 'components/PInputPhoneNumber';

const moment = require('moment');

const INITIAL_FORM = {
  email: {
    value: '',
    placeholder: 'Alamat email kamu',
    invalidMessage: 'Masukkan email Anda yang valid untuk mendaftar',
    // keyImage: 'email',
    invalidColor: 'red',
    validator: (value) => {
      const checkRegex = new RegExp(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g,
      );
      if (checkRegex.test(value)) {
        return true;
      }
      return false;
    },
  },
  phone: {
    value: '',
    placeholder: 'No. telephone',
    invalidMessage: 'Masukkan No Telepon anda untuk mendaftar',
    // keyImage: 'phone',
    invalidColor: 'red',
    validator: (value) => {
      if (value.length >= 8 && value.length <= 15) {
        return true;
      }
      return false;
    },
  },
  firstname: {
    value: '',
    placeholder: 'Nama Depan',
    invalidMessage: 'Masukkan nama depan Anda',
    // keyImage: 'user',
    invalidColor: 'red',
    validator: (value) => {
      if (value.length >= 1) {
        return true;
      }
      return false;
    },
  },
  lastname: {
    value: '',
    placeholder: 'Nama Belakang',
    invalidMessage: 'Masukkan nama belakang Anda',
    // keyImage: 'user',
    invalidColor: 'red',
    validator: (value) => {
      if (value.length >= 1) {
        return true;
      }
      return false;
    },
  },
  password: {
    value: '',
    placeholder: 'Masukkan Password Anda',
    // keyImage: 'password',
    invalidColor: 'red',
    invalidMessage: 'Harap mengisi password Anda dengan benar',
    // validator: value => {
    //   if (value.length >= 8 && value.length <= 13) {
    //     let small= 0
    //     let capital = 0
    //     let number = 0
    //     let special = 0
    //     value.forEach(val => {
    //       if (val.match(/[a-z]/g)) {
    //         small++;
    //       }
    //       if (val.match(/[A-Z]/g)) {
    //         capital++;
    //       }
    //       if (val.match(/[0-9]/g)) {
    //         number++;
    //       }
    //       if (val.match(/[-!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#]/g)) {
    //         special++;
    //       }
    //     });
    //     return true;
    //   }
    //   return false;
    // },
  },
  attendee: {
    value: '',
    placeholder: 'Nama lengkap peserta',
    invalidMessage: 'Masukkan nama peserta anda',
    // keyImage: 'phone',
    invalidColor: 'red',
    validator: (value) => {
      if (value.length >= 1) {
        return true;
      }
      return false;
    },
  },
  programcategory: {
    value: '',
    placeholder: 'Kategori Program ',
    invalidMessage: 'Harap memilih salah satu pilihan',
    invalidColor: 'red',
    validator: (value) => {
      if (value.length >= 1) {
        return true;
      }
      return false;
    },
  },
  module: {
    value: '',
    placeholder: 'Pilihan Module ',
    invalidMessage: 'Harap memilih salah satu pilihan',
    invalidColor: 'red',
    validator: (value) => {
      if (value.length >= 1) {
        return true;
      }
      return false;
    },
  },
  purwadhika: {
    value: '',
    placeholder: 'Darimana Anda tahu Purwadhika?',
    invalidMessage: 'Harap memilih salah satu pilihan',
    invalidColor: 'red',
    validator: (value) => {
      if (value.length >= 1) {
        return true;
      }
      return false;
    },
  },
  relative: {
    value: '',
    placeholder: 'Ada kerabat yang belajar di purwadhika?',
    invalidMessage: 'Isi salah satu pilihan',
    invalidColor: 'red',
    validator: (value) => {
      if (value.length >= 1) {
        return true;
      }
      return false;
    },
  },
  info: {
    value: '',
    placeholder: `Tuliskan informasi yang kamu ingin tahu lebih lanjut (Contoh: Jadwal kelas, Biaya program, Cicilan, & Kurikulum)`,
    // invalidFeedback: 'Mohon mengisi form informasi ini',
    invalidMessage: 'Mohon mengisi form informasi ini',
    invalidColor: 'red',
    validator: (value) => {
      if (value.length >= 1) {
        return true;
      }
      return false;
    },
  },
  programchoice: {
    placeholder: 'Pilih salah satu program pilihan anda',
    invalidMessage: 'Pilih salah satu program',
    invalidColor: 'red',
    validator: (value) => {
      if (value.length >= 1) {
        return true;
      }
      return false;
    },
  },
  locationchoice: {
    value: '',
    placeholder: 'Jadwal & lokasi pilihan kamu',
    invalidMessage: 'Pilih salah satu jadwal & lokasi program',
    invalidColor: 'red',
    validator: (value) => {
      if (value.length >= 1) {
        return true;
      }
      return false;
    },
  },

  studyMethod: {
    value: '',
    placeholder: 'Pilih Metode Belajar Program',
    invalidMessage: 'Pilih salah satu jadwal & lokasi program',
    invalidColor: 'red',
    validator: (value) => {
      if (value.length >= 1) {
        return true;
      }
      return false;
    },
  },

  studySchedule: {
    value: '',
    placeholder: ' Pilih Jadwal Belajar Program',
    invalidMessage: 'Pilih salah satu jadwal & lokasi program',
    invalidColor: 'red',
    validator: (value) => {
      if (value.length >= 1) {
        return true;
      }
      return false;
    },
  },
};

const ProgramPageModal = ({
  formIndex,
  open,
  toggle,
  defaultProgramID,
  defaultMethodID,
  defaultScheduleID,
}) => {
  const auth = useSelector((state) => state.auth.user);
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(open);
  const [form, setForm] = useState(0);

  const [dropdownProgram, setDropdownProgram] = useState([]);
  const [input, setInput] = useState({ ...INITIAL_FORM });
  const [defaultID, setDefaultID] = useState(defaultProgramID);

  const [picEmail, setPicEmail] = useState('');
  const [picPhone, setPicPhone] = useState('');
  const [type, setType] = useState('');
  // const [programHeader, setProgramHeader] = useState('');
  const [programHeaderSlug, setProgramHeaderSlug] = useState('');
  const [scheduleData, setScheduleData] = useState([]);
  const [productName, setProductName] = useState('');
  const [category, setCategory] = useState('');
  const [date] = useState('');
  const [endDate] = useState('');
  const [startTime] = useState('');
  const [endTime] = useState('');
  const [branch] = useState('');

  const [clear, setClear] = useState(true);
  const [loading, setLoading] = useState(false);
  const [mailChimp, setMailChimp] = useState('');

  const [DropdownCSS, setDropdownCSS] = useState(false);
  const [dropdownDisplay, setDropdownDisplay] = useState(
    'Pilih salah satu jadwal & lokasi program',
  );
  const [dropdownValue, setDropdownValue] = useState({});

  const [convert, setConvert] = useState(false);

  const [studyMethodList, setStudyMethodList] = useState([]);
  const [studyScheduleList, setStudyScheduleList] = useState([]);

  const [studyMethod, setStudyMethod] = useState({
    id: '',
    name: '',
  });
  const [studySchedule, setStudySchedule] = useState({
    id: '',
    name: '',
  });

  const { qontakNumber } = useMekariQontak();

  const handleAdsConversion = () => {
    setConvert(true);
    setTimeout(() => {
      setConvert(false);
    }, 3000);
  };

  const subscribeNewsletter = () => {
    axios
      .post(`/mailchimp/subscribe/${mailChimp}`, {
        email: input.email.value,
      })
      .then((res) => {
        return null;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getStudyMethodList = () => {
    const ph = location.pathname.split('/')[1];

    if (pathList.includes(ph)) {
      setStudyMethodList({
        result: [],
      });
      return;
    }

    axios
      .get(`/program/methods`)
      .then((res) => {
        setStudyMethodList(res.data.result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getStudyScheduleList = () => {
    const ph = location.pathname.split('/')[1];

    if (pathList.includes(ph)) {
      setStudyMethodList({
        result: [],
      });
      return;
    }

    axios
      .get(`/program/schedules`)
      .then((res) => {
        setStudyScheduleList(res.data.result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getData = (programType = 'allprogram') => {
    if (programType === 'codestarters') {
      axios
        .get(`/program/category/codestarters`)
        .then((res) => {
          setDropdownProgram(res.data.result);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      axios
        .get(`/program/category/allprogram`)
        .then((res) => {
          setDropdownProgram(res.data.result);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const resendEmailVerification = () => {
    axios
      .post(`/auth/resendemailconfirmation`)
      .then((res) => {
        window.alert('Terima Kasih, email telah dikirim kembali');
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    setIsOpen(open);
    setForm(formIndex);
    setDefaultID(defaultProgramID);
    const ph = location.pathname.split('/')[1];
    if (ph === 'codestarters') {
      getData('codestarters');
    } else {
      getData('allprogram');
      getStudyMethodList();
      getStudyScheduleList();
    }
  }, [formIndex, isOpen, open, defaultProgramID, location.pathname]);

  useEffect(() => {
    const getBranches = (slug) => {
      // const { search } = location;
      const ph = location.pathname.split('/')[1];

      if (pathList.includes(slug || ph)) {
        return;
      }

      if (defaultID) {
        axios
          .get(`/branch/get-by-slug/${slug || ph}`)
          .then((res) => {
            if (
              res.data.result.length === 1 &&
              res.data.result[0].programs.length === 1
            ) {
              const val = res.data.result[0];
              setDropdownDisplay([
                val.name,
                `${moment(val.programs[0].startDate).format(
                  'DD MMM YYYY',
                )} - ${moment(val.programs[0].endDate).format('DD MMM YYYY')}`,
                `${val.programs[0].days} | ${val.programs[0].startTime} - ${val.programs[0].endTime}`,
                val.programs[0].id,
              ]);
              setDropdownValue({
                product: val.programs[0].programHeader.name,
                date: `${moment(val.programs[0].startDate).format(
                  'DD MMM YYYY',
                )} - ${moment(val.programs[0].endDate).format('DD MMM YYYY')}`,
                startTime: val.programs[0].startTime,
                endTime: val.programs[0].endTime,
                branch: val.name,
                category: 'program',
                picEmail: val.programs[0].programHeader.pic.email,
              });
            } else {
              const array = res.data.result;
              array.forEach((val) => {
                val.programs.forEach((item) => {
                  if (item.id === defaultID) {
                    setDropdownDisplay([
                      val.name,
                      `${moment(item.startDate).format(
                        'DD MMM YYYY',
                      )} - ${moment(item.endDate).format('DD MMM YYYY')}`,
                      `${item.days} | ${item.startTime} - ${item.endTime}`,
                      item.id,
                    ]);
                    setDropdownValue({
                      product: item.programHeader.name,
                      date: `${moment(item.startDate).format(
                        'DD MMM YYYY',
                      )} - ${moment(item.endDate).format('DD MMM YYYY')}`,
                      startTime: item.startTime,
                      endTime: item.endTime,
                      branch: val.name,
                      category: 'program',
                      picEmail: item.programHeader.pic.email,
                    });
                  }
                });
              });
            }

            setScheduleData(res.data.result);
          })
          .catch((err) => {
            console.log(JSON.stringify(err));
          });
      } else {
        axios
          .get(`/branch/get-by-slug/${slug || ph}`)
          .then((res) => {
            setScheduleData(res.data.result);
            if (
              res.data.result.length === 1 &&
              res.data.result[0].programs.length === 1
            ) {
              const val = res.data.result[0];
              setDropdownDisplay([
                val.name,
                `${moment(val.programs[0].startDate).format(
                  'DD MMM YYYY',
                )} - ${moment(val.programs[0].endDate).format('DD MMM YYYY')}`,
                `${val.programs[0].days} | ${val.programs[0].startTime} - ${val.programs[0].endTime}`,
                val.programs[0].id,
              ]);
              setDropdownValue({
                product: val.programs[0].programHeader.name,
                date: `${moment(val.programs[0].startDate).format(
                  'DD MMM YYYY',
                )} - ${moment(val.programs[0].endDate).format('DD MMM YYYY')}`,
                startTime: val.programs[0].startTime,
                endTime: val.programs[0].endTime,
                branch: val.name,
                category: 'program',
                picEmail: val.programs[0].programHeader.pic.email,
              });
            }
          })
          .catch((err) => {
            console.log(JSON.stringify(err));
          });
      }
    };

    const getSetSchedule = async () => {
      await getBranches(programHeaderSlug);
    };

    if (scheduleData.length === 0 || defaultID !== null) {
      getSetSchedule();
    }
  }, [defaultID, location, programHeaderSlug, scheduleData.length]);

  useEffect(() => {
    const getMailchimpList = () => {
      axios
        .get(`/mailchimp/all`)
        .then((res) => {
          JSON.parse(res.data.response.body).lists.forEach((item) => {
            if (item.name.toLowerCase() === productName.toLowerCase()) {
              setMailChimp(item.id);
            }
          });
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const getBranches = (slug) => {
      const ph = location.pathname.split('/')[1];

      if (pathList.includes(slug || ph)) {
        return;
      }

      axios
        .get(`/branch/get-by-slug/${slug || ph}`)
        .then((res) => {
          setScheduleData(res.data.result);
          setDefaultID(null);
          if (res.data.result.length === 1) {
            const val = res.data.result[0];
            setDropdownDisplay([
              val.name,
              `${moment(val.programs[0].startDate).format(
                'DD MMM YYYY',
              )} - ${moment(val.programs[0].endDate).format('DD MMM YYYY')}`,
              `${val.programs[0].days} | ${val.programs[0].startTime} - ${val.programs[0].endTime}`,
              val.programs[0].id,
            ]);
            setDropdownValue({
              product: val.programs[0].programHeader.name,
              date: `${moment(val.programs[0].startDate).format(
                'DD MMM YYYY',
              )} - ${moment(val.programs[0].endDate).format('DD MMM YYYY')}`,
              startTime: val.programs[0].startTime,
              endTime: val.programs[0].endTime,
              branch: val.name,
              category: 'program',
              picEmail: val.programs[0].programHeader.pic.email,
            });
          }
        })
        .catch((err) => {
          console.log(JSON.stringify(err));
        });
    };

    const getProgramInfo = () => {
      const ph = location.pathname.split('/')[1];

      if (ph === 'event' || ph === 'workshop') {
        axios
          .get(`/program/event/${defaultID}`)
          .then((res) => {
            // setProgramHeader(res.data.result);
            setPicEmail(res.data.result.programHeader.pic.email);
            setPicPhone(res.data.result.programHeader.pic.phone);
            setProductName(res.data.result.programHeader.name);
            setCategory(res.data.result.category);
            setProgramHeaderSlug(res.data.result.programHeader.slug);
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (ph !== 'codestarters') {
        if (pathList.includes(ph)) {
          return;
        }

        axios
          .get(`/program/header/${ph}`)
          .then((res) => {
            if (res.data.result) {
              setType('programHeader');
              // setProgramHeader(res.data.result);
              setPicEmail(res.data.result.pic.email);
              setPicPhone(res.data.result.pic.phone);
              setProductName(res.data.result.name);
              setCategory(res.data.result.category);
              setProgramHeaderSlug(res.data.result.slug);
              getMailchimpList();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    };

    if (type === '') {
      getProgramInfo();
    }

    getBranches();
  }, [defaultID, location, productName, type]);

  useEffect(() => {
    setStudyMethod({
      id: defaultMethodID,
      name: studyMethodList.find((val) => val.id === Number(defaultMethodID))
        ?.name,
    });
    setStudySchedule({
      id: defaultScheduleID,
      name: studyScheduleList.find(
        (val) => val.id === Number(defaultScheduleID),
      )?.name,
    });
  }, [defaultMethodID, defaultScheduleID]);

  const renderDropdownProgram = () => {
    if (dropdownProgram.length !== 0) {
      return dropdownProgram.map((pHeader) => {
        return <option value={pHeader.slug}>{pHeader.name}</option>;
      });
    }
    return <option value="">Loading...</option>;
  };

  const renderStudyMethod = () => {
    if (studyMethodList.length !== 0) {
      return studyMethodList.map((method) => {
        return (
          <option name={method.name} value={method.id}>
            {method.name}
          </option>
        );
      });
    }
    return <option value="">Loading...</option>;
  };

  const renderStudySchedule = () => {
    if (studyScheduleList.length !== 0) {
      return studyScheduleList.map((schedule) => {
        return (
          <option name={schedule.name} value={schedule.id}>
            {schedule.name}
          </option>
        );
      });
    }
    return <option value="">Loading...</option>;
  };

  const clearWarning = () => {
    const tempObj = input;
    for (let i = 0; i < Object.keys(tempObj).length; i += 1) {
      tempObj[Object.keys(tempObj)[i]].valid = true;
    }

    setInput({ ...tempObj });
  };

  const handleChange = (elementName, event) => {
    if (!clear) {
      clearWarning();
      setClear(true);
    }

    setInput({
      ...input,
      [elementName]: {
        ...input[elementName],
        value: event?.target?.value || event,
      },
    });
  };

  const renderDropdownSchedule = () => {
    if (scheduleData.length !== 0) {
      return scheduleData.map((val) => {
        return val.programs.map((item) => {
          return (
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events
            <li
              onClick={() => {
                setDropdownDisplay([
                  val.name,
                  `${moment(item.startDate).format('DD MMM YYYY')} - ${moment(
                    item.endDate,
                  ).format('DD MMM YYYY')}`,
                  `${item.days} | ${item.startTime} - ${item.endTime}`,
                  item.id,
                ]);
                setDropdownValue({
                  product: item.programHeader.name,
                  date: `${moment(item.startDate).format(
                    'DD MMM YYYY',
                  )} - ${moment(item.endDate).format('DD MMM YYYY')}`,
                  startTime: item.startTime,
                  endTime: item.endTime,
                  branch: val.name,
                  category: 'program',
                  picEmail: item.programHeader.pic.email,
                });
              }}
            >
              <div className="font-weight-bold">{val.name}</div>
              <div>
                {`${moment(item.startDate).format('DD MMM YYYY')} - ${moment(
                  item.endDate,
                ).format('DD MMM YYYY')}`}
              </div>
              <div>{`${item.days} | ${item.startTime} - ${item.endTime}`}</div>
            </li>
          );
        });
      });
    }
    return (
      <li>
        <div className="font-weight-bold">
          Tidak tersedia jadwal program untuk saat ini
        </div>
      </li>
    );
  };

  const onSubmitClick = async () => {
    handleAdsConversion();
    setLoading(true);

    const defaultPIC = 'admission3@purwadhika.com';

    const submitData = {
      email: input.email.value,
      firstName: input.firstname.value,
      lastName: input.lastname.value,
      phone: input.phone.value,
      referral: input.purwadhika.value,
      hasRelative: input.relative.value,
      additional: input.info.value,

      picEmail: picEmail || defaultPIC,
      product: productName || 'Contact Form',
      category,
      form,
    };

    axios
      .post(`/enquiry/student-enquiry`, submitData)
      .then(() => {
        // open qontak whatsapp
        if (qontakNumber) {
          let whatsappMessage = '';
          if (productName?.length > 0) {
            whatsappMessage = window.encodeURIComponent(
              `Hi, saya ${input?.firstname?.value} ${input?.lastname?.value}.\nSaya tertarik dengan ${category} '${productName}' \n${date}\nPertanyaan:\n${input?.info?.value}`,
            );
          } else {
            whatsappMessage = window.encodeURIComponent(
              `Hi, saya ${input?.firstname?.value} ${input?.lastname?.value}.\n\nSaya ingin bertanya mengenai \nPertanyaan:\n${input?.info?.value}`,
            );
          }
          window.open(
            `https://wa.me/${qontakNumber}?text=${whatsappMessage}`,
            '_blank',
          );
        }

        setForm(3);
        setLoading(false);
        if (mailChimp !== '' || mailChimp.length !== 0) {
          subscribeNewsletter();
        }
      })
      .catch((err) => {
        setLoading(false);
        window.alert(JSON.stringify(err));
        console.log(JSON.stringify(err));
      });
  };

  const whatsappSubmit = () => {
    handleAdsConversion();
    const defaultPICphone = '6281311485800';
    setLoading(true);
    axios
      .post(`/enquiry/create`, {
        email: input.email.value,
        firstName: input.firstname.value,
        lastName: input.lastname.value,
        phone: input.phone.value,
        referral: input.purwadhika.value,
        hasRelative: input.relative.value,
        additional: input.info.value,

        product: productName,
        date,
        startTime,
        endTime,
        branch,
        category,
        //
      })
      .then((res) => {
        setLoading(false);
        if (mailChimp !== '' || mailChimp.length !== 0) {
          subscribeNewsletter();
        }
        let date = '';
        if (date && endDate) {
          date = moment(date).isSame(endDate)
            ? `${moment(date).format('DD MMM YYYY')}`
            : `${moment(date).format('DD MMM')} - ${moment(endDate).format(
                'DD MMM YYYY',
              )}`;
          date += ` ${startTime} - ${endTime}`;
        }
        let whatsappMessage = '';
        if (productName.length > 0) {
          whatsappMessage = window.encodeURIComponent(
            `Hi, saya ${input.firstname.value} ${input.lastname.value}.\nSaya tertarik dengan ${category} '${productName}' \n${date}\nPertanyaan:\n${input.info.value}`,
          );
        } else {
          whatsappMessage = window.encodeURIComponent(
            `Hi, saya ${input.firstname.value} ${input.lastname.value}.\n\nSaya ingin bertanya mengenai \nPertanyaan:\n${input.info.value}`,
          );
        }
        window.open(
          `https://wa.me/${
            qontakNumber || (picPhone.length > 0 ? picPhone : defaultPICphone)
          }?text=${whatsappMessage}`,
          '_blank',
        );
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        alert(
          'Sistem sedang bermasalah. Mohon kontak kami secara manual melalui workshop@purwadhika.com',
        );
      });
  };

  const onBtnWhatsappClick = () => {
    setLoading(true);
    let isValid = true;
    const tempForm = input;

    if (!tempForm.firstname.validator(tempForm.firstname.value)) {
      tempForm.firstname.valid = false;
      isValid = false;
    }
    if (!tempForm.lastname.validator(tempForm.lastname.value)) {
      tempForm.lastname.valid = false;
      isValid = false;
    }
    if (!tempForm.email.validator(tempForm.email.value)) {
      tempForm.email.valid = false;
      isValid = false;
    }
    if (!tempForm.phone.validator(tempForm.phone.value)) {
      tempForm.phone.valid = false;
      isValid = false;
    }
    if (!tempForm.purwadhika.validator(tempForm.purwadhika.value)) {
      tempForm.purwadhika.valid = false;
      isValid = false;
    }
    if (!tempForm.relative.validator(tempForm.relative.value)) {
      tempForm.relative.valid = false;
      isValid = false;
    }
    if (!tempForm.info.validator(tempForm.info.value)) {
      tempForm.info.valid = false;
      isValid = false;
    }

    setInput({ ...tempForm });
    if (!isValid) {
      setLoading(false);

      setClear(false);
    } else {
      setLoading(false);

      whatsappSubmit();
    }
  };

  const daftarClick = () => {
    setLoading(true);
    const defaultPIC = 'admission1@purwadhika.com';

    const submitData = {
      email: auth.email,
      firstName: auth.firstName,
      lastName: auth.lastName,
      phone: auth.phone,

      picEmail: dropdownValue.picEmail || defaultPIC,
      product: dropdownValue.product,
      date: dropdownValue.date,
      startTime: dropdownValue.startTime,
      endTime: dropdownValue.endTime,
      branch: dropdownValue.branch,
      category: dropdownValue.category,
      studyMethod: studyMethod.name,
      studySchedule: studySchedule.name,
      form,
    };

    axios
      .post(`/enquiry/student-enquiry`, submitData)
      .then(() => {
        setForm(3);
        setLoading(false);
        if (mailChimp !== '' || mailChimp.length !== 0) {
          subscribeNewsletter();
        }
      })
      .catch((err) => {
        setLoading(false);
        alert(
          'Sistem sedang bermasalah. Mohon kontak kami secara manual melalui admission1@purwadhika.com',
        );
        console.log(JSON.stringify(err));
      });
  };

  const daftarCodeStarterClick = () => {
    setLoading(true);

    axios
      .post(`/enquiry/create`, {
        email: auth.email,
        firstName: input.attendee.value,
        lastName: '',
        phone: auth.phone,
        referral: '',
        hasRelative: '',
        additional: 'Daftar CodeStarter Program',

        product: dropdownValue.product,
        date: dropdownValue.date,
        startTime: dropdownValue.startTime,
        endTime: dropdownValue.endTime,
        branch: dropdownValue.branch,
        category: dropdownValue.category,
      })
      .then((res) => {
        setForm(2);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(JSON.stringify(err));
      });

    axios
      .post(`/pic/contact`, {
        email: auth.email,
        firstName: auth.firstName,
        lastName: auth.lastName,
        phone: auth.phone,
        picEmail: dropdownValue.picEmail,
        product: dropdownValue.product,
        date: dropdownValue.date,
        startTime: dropdownValue.startTime,
        endTime: dropdownValue.endTime,
        branch: dropdownValue.branch,
        category: dropdownValue.category,
      })
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        alert(
          'Sistem sedang bermasalah. Mohon kontak kami secara manual melalui workshop@purwadhika.com',
        );
        console.log(JSON.stringify(err));
      });
  };

  const onBtnDaftarCodeStarterClick = () => {
    if (auth.email === '' || !dropdownValue.product) {
      setClear(false);
    } else {
      handleAdsConversion();
      daftarCodeStarterClick();
    }
  };

  const onBtnDaftarClick = () => {
    if (auth.email === '' || !dropdownValue.product) {
      setClear(false);
    } else {
      handleAdsConversion();
      daftarClick();
    }
  };

  const onBtnFreeTrialClick = async () => {
    let isValid = true;
    const tempForm = input;
    if (!tempForm.attendee.validator(tempForm.attendee.value)) {
      tempForm.attendee.valid = false;
      isValid = false;
    }

    if (!tempForm.email.validator(tempForm.email.value)) {
      tempForm.email.valid = false;
      isValid = false;
    }
    if (!tempForm.phone.validator(tempForm.phone.value)) {
      tempForm.phone.valid = false;
      isValid = false;
    }

    setInput({ ...tempForm });

    if (
      isValid &&
      dropdownDisplay.length === 4 &&
      programHeaderSlug.length > 0
    ) {
      handleAdsConversion();
      setLoading(true);
      const about = 'Ask for free trial';
      const additional = `Asking for free trial || Program Name : {${programHeaderSlug}} `;

      axios
        .post(`/enquiry/create`, {
          email: input.email.value,
          firstName: input.attendee.value,
          lastName: '',
          phone: input.phone.value,
          occupation: '',
          institution: '',
          city: '',
          referral: '',
          category: 'Code Starter',
          product: about,
          date: dropdownValue.date,
          startTime: dropdownValue.startTime,
          endTime: dropdownValue.endTime,
          branch: dropdownValue.branch,
          additional,
        })
        .then(() => {})
        .catch((err) => {
          console.log(JSON.stringify(err));
        });

      axios
        .post(`/pic/contact`, {
          email: input.email.value,
          firstName: input.attendee.value,
          lastName: '',
          phone: input.phone.value,
          category: 'Code Starter',
          product: about,
          date: dropdownValue.date,
          startTime: dropdownValue.startTime,
          endTime: dropdownValue.endTime,
          branch: dropdownValue.branch,
          additional,
          picEmail: 'codestarter@purwadhika.com',
        })
        .then(() => {
          setForm(3);
          setLoading(false);
        })
        .catch((err) => {
          console.log(JSON.stringify(err));
        });
    }
  };

  const onBtnClickLihatBiaya = () => {
    let isValid = true;
    const tempForm = input;
    if (!tempForm.firstname.validator(tempForm.firstname.value)) {
      tempForm.firstname.valid = false;
      isValid = false;
    }
    if (!tempForm.lastname.validator(tempForm.lastname.value)) {
      tempForm.lastname.valid = false;
      isValid = false;
    }
    if (!tempForm.email.validator(tempForm.email.value)) {
      tempForm.email.valid = false;
      isValid = false;
    }
    if (!tempForm.phone.validator(tempForm.phone.value)) {
      tempForm.phone.valid = false;
      isValid = false;
    }

    setInput({ ...tempForm });
    if (!isValid) {
      setClear(false);
    }
  };

  const onBtnClickHubungiKami = () => {
    let isValid = true;
    const tempForm = input;

    if (!tempForm.firstname.validator(tempForm.firstname.value)) {
      tempForm.firstname.valid = false;
      isValid = false;
    }
    if (!tempForm.lastname.validator(tempForm.lastname.value)) {
      tempForm.lastname.valid = false;
      isValid = false;
    }
    if (!tempForm.email.validator(tempForm.email.value)) {
      tempForm.email.valid = false;
      isValid = false;
    }
    if (!tempForm.phone.validator(tempForm.phone.value)) {
      tempForm.phone.valid = false;
      isValid = false;
    }
    if (!tempForm.purwadhika.validator(tempForm.purwadhika.value)) {
      tempForm.purwadhika.valid = false;
      isValid = false;
    }
    if (!tempForm.relative.validator(tempForm.relative.value)) {
      tempForm.relative.valid = false;
      isValid = false;
    }
    if (!tempForm.info.validator(tempForm.info.value)) {
      tempForm.info.valid = false;
      isValid = false;
    }

    setInput({ ...tempForm });
    if (!isValid) {
      setClear(false);
    } else {
      onSubmitClick();
    }
  };

  const renderContantViaEmailSuccess = () => {
    return (
      <div>
        <ModalHeader
          style={{
            padding: '16px 24px',
            borderRadius: '4px',
            border: '1px solid #32b280',
            backgroundColor: '#f4fbf9',
          }}
          className="d-block"
        >
          <div className="d-flex mb-2">
            <div
              style={{ fontWeight: 'bold', color: '#32b280' }}
              className="f-md-h5 f-mweb-body-small  mr-auto"
            >
              Pesan hubungi kami via email telah terkirim
            </div>

            <div
              style={{ height: '24px', width: '24px', cursor: 'pointer' }}
              onClick={toggle}
              onKeyDown={toggle}
              className="pb-2 pb-md-0"
            >
              <img alt=" closebutton" src={closeicon} />
            </div>
            {/* <div className="ml-auto bg-primary">Flex item 3</div> */}
          </div>
          <div className="f-md-body-alt  f-mweb-body-small pr-5">
            Tim kami akan segera menghubungi kamu melalui email. Jangan sampai
            terlewat ya.
          </div>
        </ModalHeader>
      </div>
    );
  };

  const renderFormLihatBiaya = () => {
    return (
      <div>
        <ModalHeader
          style={{ padding: '16px 16px 16px 32px' }}
          className="d-block"
        >
          <div className="d-flex">
            <div
              style={{ fontWeight: 'bold' }}
              className="f-md-h4 f-mweb-h2  mr-auto"
            >
              Lihat Biaya
            </div>
            <div
              style={{ height: '24px', width: '24px', cursor: 'pointer' }}
              onClick={toggle}
              onKeyDown={toggle}
            >
              <img alt=" closebutton" src={closeicon} />
            </div>
            {/* <div className="ml-auto bg-primary">Flex item 3</div> */}
          </div>
        </ModalHeader>
        <ModalBody style={{ padding: '24px 32px 40px 32px' }}>
          <div className="row p-0 mb-3">
            <div className="col-sm-6 pr-md-2 mb-3 mb-md-0">
              <div className="pwdk-form-icon-container">
                <PInput
                  id="firstname"
                  // invalidColor="yellow"
                  // invalidMessage="test red message"
                  required
                  className="pr-5"
                  name="firstname"
                  {...input.firstname}
                  onChange={(e) => handleChange('firstname', e)}
                  value={input.firstname.value}

                  // selected={togglephone}
                />
                <span className="font-icon-adjustment icon-ic_person_outline" />
              </div>
            </div>
            <div className="col-sm-6 pl-md-2">
              <div className="pwdk-form-icon-container">
                <PInput
                  type="text"
                  required
                  className="pr-5"
                  id="lastname"
                  name="lastname"
                  {...input.lastname}
                  onChange={(e) => handleChange('lastname', e)}
                  value={input.lastname.value}

                  // selected={togglephone}
                />
                <span className="font-icon-adjustment icon-ic_person_outline" />
              </div>
            </div>
          </div>
          <div className="pwdk-form-icon-container mb-3">
            <PInput
              type="email"
              id="email"
              required
              name="email"
              className="pr-5"
              {...input.email}
              onChange={(e) => handleChange('email', e)}
              value={input.email.value}

              // selected={togglephone}
            />
            <span className="font-icon-adjustment icon-ic_mail" />
          </div>
          <div className="pwdk-form-icon-container mb-3">
            <PInput
              type="number"
              id="phone"
              required
              name="phone"
              // className="mb-3 pwdk-form-icon"
              {...input.phone}
              onChange={(e) => handleChange('phone', e)}
              value={input.phone.value}

              // selected={togglephone}
            />
            <span className="font-icon-adjustment icon-ic_phone" />
          </div>
          <div
            className="alert"
            role="alert"
            style={{
              padding: '8px 40px 8px 10px',
              backgroundColor: '#f2faff',
              marginBottom: '40px',
            }}
          >
            <div className="d-flex flex-row justify-content-center align-items-center">
              <div style={{ marginRight: '10px' }}>
                <img
                  src={svginfo}
                  width="24px"
                  height="24px"
                  alt="infobutton"
                />
              </div>
              <div
                className="f-md-body-alt  f-mweb-body-small"
                style={{ color: '#6e6e6e' }}
              >
                Rincian biaya akan dikirimkan melalui email kamu. Pastikan kamu
                menuliskan alamat email dengan benar ya.
              </div>
            </div>
          </div>
          <div className="d-flex flex-row justify-content-center">
            <PButton
              id="whatsappbutton"
              size="large"
              className="text-center f-mweb-h6 pwdk-btn-sm-small"
              style={{ width: '100%' }}
              onClick={() => onBtnClickLihatBiaya()}
              // color="alt"
            >
              Kirimkan saya rincian biaya
            </PButton>
          </div>
        </ModalBody>
      </div>
    );
  };

  const renderFormHubungiKami = () => {
    return (
      <div>
        <ModalHeader
          style={{ padding: '16px 16px 16px 32px' }}
          className="d-block"
        >
          <div className="d-flex">
            <div
              style={{ fontWeight: 'bold' }}
              className="f-md-h4 f-mweb-h2  mr-auto"
            >
              Hubungi Kami
            </div>
            <div
              style={{ height: '24px', width: '24px', cursor: 'pointer' }}
              onClick={toggle}
              onKeyDown={toggle}
            >
              <img alt=" closebutton" src={closeicon} />
            </div>
            {/* <div className="ml-auto bg-primary">Flex item 3</div> */}
          </div>
        </ModalHeader>
        <ModalBody style={{ padding: '24px 32px 40px 32px' }}>
          <div className="row p-0 mb-3">
            <div className="col-md-6 pr-md-2 mb-3 mb-md-0">
              {/* <PInput
                type="text"
                id="firstname"
                name="firstname"
                className="pwdk-form-icon"
                {...input.firstname}
                onChange={e => handleChange('firstname', e)}
                value={input.firstname.value}
                required
                style={{
                  backgroundImage: `url(${user})`,
                }}
                // selected={togglephone}
              /> */}

              <div className="pwdk-form-icon-container">
                <PInput
                  type="text"
                  id="firstname"
                  name="firstname"
                  // className="pwdk-form-icon"
                  {...input.firstname}
                  onChange={(e) => handleChange('firstname', e)}
                  value={input.firstname.value}
                  required
                  className="pr-5"
                  // style={{
                  //   backgroundImage: `url(${user})`,
                  // }}
                  // selected={togglephone}
                />
                <span className="font-icon-adjustment icon-ic_person_outline" />
              </div>
            </div>
            <div className="col-md-6 pl-md-2">
              <div className="pwdk-form-icon-container">
                <PInput
                  type="text"
                  id="lastname"
                  required
                  className="pr-5"
                  name="lastname"
                  {...input.lastname}
                  onChange={(e) => handleChange('lastname', e)}
                  value={input.lastname.value}

                  // selected={togglephone}
                />
                <span className="font-icon-adjustment icon-ic_person_outline" />
              </div>
            </div>
          </div>
          <div className="pwdk-form-icon-container mb-3">
            <PInput
              type="email"
              required
              className="pr-5"
              id="email"
              name="email"
              // className="mb-3"
              {...input.email}
              onChange={(e) => handleChange('email', e)}
              value={input.email.value}

              // selected={togglephone}
            />
            <span className="font-icon-adjustment icon-ic_mail" />
          </div>

          <div className="mb-3">
            <PInputPhoneNumber
              {...input.phone}
              value={input.phone.value}
              onChange={(e) => handleChange('phone', e)}
            />
          </div>

          <select
            type="select"
            required
            className="py-3 pwdk-form pwdk-form-icon select-dropdown-adjustment"
            style={{
              width: '100%',
              backgroundImage: `url(${chevrondown})`,
            }}
            onChange={(e) => handleChange('purwadhika', e)}

            // selected={state.togglepurwadhika}
          >
            <option value="" disabled selected hidden>
              {INITIAL_FORM.purwadhika.placeholder}
            </option>
            <option disabled>--Advertising--</option>
            <option value="Google Search">Google Search</option>
            <option value="Billboard">Billboard</option>
            <option disabled>--Social Media Purwadhika--</option>
            <option value="Instagram Purwadhika (non ads)">
              Instagram Purwadhika (non ads)
            </option>
            <option value="Instagram Ads">Instagram Ads</option>
            <option value="Youtube Channel Purwadhika">
              Youtube Channel Purwadhika
            </option>
            <option value="Youtube Ads">Youtube Ads</option>
            <option value="Tik Tok Purwadhika">Tik Tok Non Ads</option>
            <option value="Tik Tok Ads">Tik Tok Ads</option>
            <option value="Podcast">Podcast</option>
            <option value="Facebook Page Purwadhika">
              Facebook Page Purwadhika
            </option>
            <option value="Twitter">Twitter</option>
            <option value="Linkedin">Linkedin</option>
            <option value="Medium">Medium</option>
            <option disabled>--Other--</option>
            <option value="Friends / Family Referral">
              Friends / Family Referral
            </option>
            <option value="Media Coverage (online/magazine/newspaper/etc)">
              Media Coverage (online/magazine/newspaper/etc)
            </option>
            <option value="Walk-in">Walk-in</option>
            {/* <option value="Lainnya">Lainnya</option> */}
          </select>

          <select
            required
            className="py-3 mt-3 pwdk-form pwdk-form-icon select-dropdown-adjustment"
            style={{ width: '100%', backgroundImage: `url(${chevrondown})` }}
            onChange={(e) => handleChange('relative', e)}

            // selected={state.togglepurwadhika}
          >
            <option value="" disabled selected hidden>
              {INITIAL_FORM.relative.placeholder}
            </option>
            <option value="Ya">Ya</option>
            <option value="Tidak">Tidak</option>
          </select>

          <PTextArea
            // placeholder=""
            required
            id="info"
            className="mt-3"
            {...input.info}
            onChange={(e) => handleChange('info', e)}
            value={input.info.value}
            maxLength={140}
          />
          {/* <div
            style={{
              color: '#b3b3b3',
              fontFamily: 'CircularStd',
              fontSize: '11px',
            }}
            className="mt-2 text-right"
          >
            <p>{`${character} / 140 Characters`}</p>
          </div> */}
          {loading ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '100%',
              }}
            >
              <CircleSpinner size={40} color="#53b59f" loading />
            </div>
          ) : (
            <div className="mt-4 row">
              <div className="col-md-6 pr-md-2">
                <PButton
                  id="emailbutton"
                  size="large"
                  color="alt"
                  className="text-center pwdk-btn-sm-small"
                  style={{ width: '100%' }}
                  onClick={() => onBtnClickHubungiKami()}
                >
                  Hubungi via Email
                </PButton>
              </div>
              <div className="col-md-6 pl-md-2 mt-3 mt-md-0">
                <PButton
                  id="whatsappbutton"
                  onClick={() => onBtnWhatsappClick()}
                  size="large"
                  className="text-center f-mweb-h6 pwdk-btn-sm-small"
                  style={{ width: '100%' }}
                  // color="alt"
                >
                  Hubungi via Whatsapp
                </PButton>
              </div>
            </div>
          )}
        </ModalBody>
      </div>
    );
  };

  const renderDaftarSekarangCodestarter = () => {
    return (
      <div>
        <ModalHeader
          style={{ padding: '16px 16px 16px 32px' }}
          className="d-block"
        >
          <div className="d-flex">
            <div
              style={{ fontWeight: 'bold' }}
              className="f-md-h4 f-mweb-h2 mr-auto"
            >
              Pilihan Jadwal Program Code Starter
            </div>
            <div
              style={{ height: '24px', width: '24px', cursor: 'pointer' }}
              onClick={toggle}
              onKeyDown={toggle}
            >
              <img alt=" closebutton" src={closeicon} />
            </div>
            {/* <div className="ml-auto bg-primary">Flex item 3</div> */}
          </div>
        </ModalHeader>
        <ModalBody style={{ padding: '24px 32px 40px 32px' }}>
          {auth.accountVerified ? null : (
            <div
              className="alert mb-3"
              role="alert"
              style={{
                padding: '8px 30px 8px 10px',
                backgroundColor: '#fffbf4',
              }}
            >
              <div className="d-flex flex-row justify-content-center align-items-center">
                <div style={{ marginRight: '10px' }}>
                  <img
                    src={svginfowarning}
                    width="24px"
                    height="24px"
                    alt="infobutton"
                  />
                </div>
                <div
                  className="f-md-body-alt f-mweb-body-small"
                  f-mweb-body-small
                  style={{ color: '#6e6e6e' }}
                >
                  <span style={{ color: '#ffa630' }}>
                    {' '}
                    Mohon{' '}
                    <span
                      className="font-weight-md-bold"
                      // onClick={() => setTooltip(true)}
                    >
                      verifikasi ulang
                    </span>{' '}
                    email kamu untuk mendaftar program ini.{' '}
                  </span>
                  Belum mendapat link verifikasi? - klik{' '}
                  <u
                    style={{ cursor: 'pointer' }}
                    onClick={() => resendEmailVerification()}
                    onKeyDown={() => null}
                  >
                    disini
                  </u>{' '}
                  untuk kirim ulang.
                </div>
              </div>
            </div>
          )}

          <div className="pwdk-form-icon-container">
            <PInput
              type="text"
              id="attendee"
              name="attendee"
              {...input.attendee}
              onChange={(e) => handleChange('attendee', e)}
              value={input.attendee.value}
              required
              className="pr-5"
            />
            <span className="font-icon-adjustment icon-ic_person_outline" />
          </div>

          <select
            id="programchoice"
            required
            // className="py-3 pwdk-form pwdk-form-icon"
            {...input.programchoice}
            onChange={(e) => {
              setProgramHeaderSlug(e.target.value);
              setScheduleData([]);
              setDropdownDisplay('Pilih salah satu jadwal & lokasi program');
              setDropdownValue({});
            }}
            className="py-3 mt-3 pwdk-form pwdk-form-icon select-dropdown-adjustment"
            style={{ width: '100%', backgroundImage: `url(${chevrondown})` }}
            value={programHeaderSlug}
          >
            <option value="" disabled selected hidden>
              {INITIAL_FORM.programchoice.placeholder}
            </option>
            {renderDropdownProgram()}
          </select>
          {/* <select
            type="select"
            id="locationchoice"
            required
            className="mt-3 mb-4 py-3 pwdk-form pwdk-form-icon select-dropdown-adjustment"
            style={{ width: '100%', backgroundImage: `url(${chevrondown})` }}
            {...input.locationchoice}
            onChange={e => handleChange('locationchoice', e)}
            value={input.locationchoice.value}
          >
            <option value="" disabled selected hidden>
              {INITIAL_FORM.locationchoice.placeholder}
            </option>
            <option value="Ya">
              <span className="font-weight-bold">adjustment</span> <br />{' '}
              <span>adjustment</span>
              ya <br /> bas
            </option>
            <option value="Tidak">
              <span className="font-weight-bold">adjustment</span> <br />{' '}
              <span>adjustment</span>
            </option>
          </select> */}

          <div
            className={`custom-dropdown-container ${
              DropdownCSS ? 'custom-dropdown-active' : ''
            } mb-4 mt-3`}
            onClick={() => setDropdownCSS(!DropdownCSS)}
            style={{
              height: 'fit-content',
              backgroundColor: `${
                dropdownDisplay.length !== 4
                  ? '#ffffff'
                  : 'rgba(179, 179, 179, 0.08)'
              }`,
            }}
            onKeyDown={() => setDropdownCSS(!DropdownCSS)}
          >
            <div className="custom-dropdown-placeholder f-md-body-alt f-mweb-body-small pb-3">
              {dropdownDisplay.length !== 4 ? (
                <div className="pb-5" style={{ color: '#b3b3b3' }}>
                  {INITIAL_FORM.locationchoice.placeholder}
                </div>
              ) : (
                <div className="f-md-body-alt f-mweb-body-small">
                  <div className="font-weight-bold">{dropdownDisplay[0]}</div>
                  <div>{dropdownDisplay[1]}</div>
                  <div>{dropdownDisplay[2]}</div>
                </div>
              )}
              {/* <div className="font-weight-bold">Purwadhika Campus BSD</div>
              <div>20 Jan 2020 - 27 Apr 2020</div>
              <div>| Mon - Fri 09.00 - 16.00</div> */}
            </div>

            <ul className="custom-dropdown-options f-md-body-alt f-mweb-body-small">
              {renderDropdownSchedule()}
            </ul>
          </div>

          <div
            className="d-flex flex-row justify-content-end align-items-center"
            // id="question"
          >
            {auth.accountVerified ? null : (
              <div className="d-none d-md-block">
                <div
                  className="d-flex flex-column justify-content-center align-items-center
                mr-2 pwdk-tooltip-container pwdk-tooltip-arrow-bottom pwdk-tooltip-center tooltipstyles"
                  id="question"
                >
                  ?
                  <div className="pwdk-tooltip-bottom pwdk-tooltip">
                    Mohon verifikasi email kamu untuk mendaftar
                  </div>
                </div>
              </div>
            )}

            {loading ? (
              <PButton
                color="primary"
                id="emailbutton"
                style={{ width: '156px' }}
                className="text-center w-sm-full d-flex align-items-center justify-content-center"
              >
                <Spinner
                  color="light"
                  style={{ width: '20px', height: '20px' }}
                />
              </PButton>
            ) : (
              <PButton
                // disabled=
                color={
                  !programHeaderSlug ||
                  dropdownDisplay.length !== 4 ||
                  !auth.accountVerified ||
                  input.attendee.value.length === 0
                    ? 'disabled'
                    : 'primary'
                }
                id="emailbutton"
                className="text-center w-sm-full"
                onClick={() => onBtnDaftarCodeStarterClick()}
                style={{ width: '156px' }}
                disabled={
                  !programHeaderSlug ||
                  dropdownDisplay.length !== 4 ||
                  !auth.accountVerified ||
                  input.attendee.value.length === 0
                }

                // color="alt"
              >
                Daftar Sekarang
              </PButton>
            )}
          </div>
        </ModalBody>
      </div>
    );
  };

  const renderFreeTrialCodestarterNoLogin = () => {
    return (
      <div>
        <ModalHeader
          style={{ padding: '16px 16px 16px 32px' }}
          className="d-block"
        >
          <div className="d-flex">
            <div
              style={{ fontWeight: 'bold' }}
              className="f-md-h4 f-mweb-h2  mr-auto"
            >
              Daftar Info Session
            </div>
            <div
              style={{ height: '24px', width: '24px', cursor: 'pointer' }}
              onClick={toggle}
              onKeyDown={toggle}
            >
              <img alt=" closebutton" src={closeicon} />
            </div>
            {/* <div className="ml-auto bg-primary">Flex item 3</div> */}
          </div>
        </ModalHeader>
        <ModalBody style={{ padding: '24px 32px 40px 32px' }}>
          <div className="pwdk-form-icon-container mb-3">
            <PInput
              type="text"
              id="attendee"
              name="attendee"
              // className="pwdk-form-icon"
              {...input.attendee}
              onChange={(e) => handleChange('attendee', e)}
              value={input.attendee.value}
              required
              className="pr-5"
              // style={{
              //   backgroundImage: `url(${user})`,
              // }}
              // selected={togglephone}
            />
            <span className="font-icon-adjustment icon-ic_person_outline" />
          </div>

          <div className="pwdk-form-icon-container mb-3">
            <PInput
              type="email"
              required
              className="pr-5"
              id="email"
              name="email"
              // className="mb-3"
              {...input.email}
              onChange={(e) => handleChange('email', e)}
              value={input.email.value}

              // selected={togglephone}
            />
            <span className="font-icon-adjustment icon-ic_mail" />
          </div>

          <div className="pwdk-form-icon-container mb-3">
            <PInput
              type="number"
              className="pr-5"
              id="phone"
              required
              name="phone"
              // className="mb-3"
              {...input.phone}
              onChange={(e) => handleChange('phone', e)}
              value={input.phone.value}

              // selected={togglephone}
            />
            <span className="font-icon-adjustment icon-ic_phone" />
          </div>

          <select
            type="select"
            required
            className="py-3 pwdk-form pwdk-form-icon select-dropdown-adjustment"
            style={{
              width: '100%',
              backgroundImage: `url(${chevrondown})`,
            }}
            value={programHeaderSlug}
            onChange={(e) => {
              setProgramHeaderSlug(e.target.value);
              setScheduleData([]);
              setDropdownDisplay('Pilih salah satu jadwal & lokasi program');
              setDropdownValue({});
            }}

            // selected={state.togglepurwadhika}
          >
            <option value="" disabled selected hidden>
              {INITIAL_FORM.programchoice.placeholder}
            </option>

            {renderDropdownProgram()}
          </select>

          <div
            className={`custom-dropdown-container ${
              DropdownCSS ? 'custom-dropdown-active' : ''
            } mb-4 mt-3`}
            onClick={() => setDropdownCSS(!DropdownCSS)}
            style={{
              height: 'fit-content',
              backgroundColor: `${
                dropdownDisplay.length !== 4
                  ? '#ffffff'
                  : 'rgba(179, 179, 179, 0.08)'
              }`,
            }}
            onKeyDown={() => setDropdownCSS(!DropdownCSS)}
          >
            <div className="custom-dropdown-placeholder f-md-body-alt f-mweb-body-small pb-3">
              {dropdownDisplay.length !== 4 ? (
                <div className="pb-5" style={{ color: '#b3b3b3' }}>
                  {INITIAL_FORM.locationchoice.placeholder}
                </div>
              ) : (
                <div className="f-md-body-alt f-mweb-body-small">
                  <div className="font-weight-bold">{dropdownDisplay[0]}</div>
                  <div>{dropdownDisplay[1]}</div>
                  <div>{dropdownDisplay[2]}</div>
                </div>
              )}
            </div>
            <ul className="custom-dropdown-options f-md-body-alt f-mweb-body-small">
              {renderDropdownSchedule()}
            </ul>
          </div>
          <div
            className="d-flex flex-row justify-content-end align-items-center"
            // id="question"
          >
            {/* <GeneralTooltips
              position="top"
              toggle={tooltip}
              id="question"
              content="Mohon verifikasi email kamu untuk mendaftar"
            /> */}

            {loading ? (
              <PButton
                color="primary"
                id="emailbutton"
                className="text-center w-sm-full d-flex align-items-center justify-content-center"
              >
                <Spinner
                  color="light"
                  style={{ width: '20px', height: '20px' }}
                />
              </PButton>
            ) : (
              <PButton
                // disabled=
                color="primary"
                id="emailbutton"
                className="text-center w-sm-full"
                onClick={() => onBtnFreeTrialClick()}

                // color="alt"
              >
                Daftar Sekarang
              </PButton>
            )}
          </div>
        </ModalBody>
      </div>
    );
  };

  const renderRegisterSuccess = () => {
    return (
      <div>
        <ModalBody className="modalbodysuccess">
          <div style={{ position: 'absolute', top: '10px', right: '30px' }}>
            <div
              style={{ height: '24px', width: '24px', cursor: 'pointer' }}
              onClick={toggle}
              onKeyDown={toggle}
              className="pt-2"
            >
              <img alt=" closebutton" src={closeicon} />
            </div>
          </div>

          <div className="d-flex flex-column justify-content-center align-items-center">
            <div
              className="f-md-h3 f-mweb-h2 text-center px-3 px-md-0"
              style={{ color: '#505050' }}
            >
              Terima kasih! Team kami akan menghubungi kamu
            </div>
            <img
              src={registersuccess}
              className="imagemodalsuccess"
              alt="Register Success"
            />
            <div className="f-md-body-alt text-center  d-md-block d-none">
              Terima kasih! pendaftaran kamu sedang diproses, team kami akan
              segera{' '}
              <span className="font-weight-md-bold"> menghubungi kamu </span>{' '}
              untuk melanjutkan proses selanjutnya. Jangan sampai terlewatkan
              ya.
            </div>
            <div
              className="d-md-none d-block f-mweb-body-small text-center mt-3"
              style={{ color: '#6e6e6e' }}
            >
              Terima kasih! pendaftaran kamu sedang diproses, team kami akan
              segera menghubungi kamu untuk melanjutkan proses selanjutnya.
            </div>
          </div>
        </ModalBody>
      </div>
    );
  };

  const renderContactSuccess = () => {
    return (
      <div>
        <ModalBody className="modalbodysuccess">
          <div style={{ position: 'absolute', top: '10px', right: '30px' }}>
            <div
              style={{ height: '24px', width: '24px', cursor: 'pointer' }}
              onClick={toggle}
              onKeyDown={toggle}
              className="pt-2"
            >
              <img alt=" closebutton" src={closeicon} />
            </div>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <div
              className="f-md-h3 f-mweb-h2 text-center"
              style={{ color: '#505050' }}
            >
              Hi! Terima kasih telah menghubungi kami
            </div>
            <img
              src={contactsuccess}
              className="imagemodalsuccess"
              alt="Contact Success"
            />
            <div
              className="f-md-body-alt f-mweb-body-small text-center mt-3"
              style={{ color: '#6e6e6e' }}
            >
              Terima kasih atas ketertarikan kamu dengan Purwadhika, tim kami
              akan segera untuk menghubungi kamu. Pastikan kamu tidak
              melewatkannya ya.
            </div>
          </div>
        </ModalBody>
      </div>
    );
  };

  const renderMailPriceSuccess = () => {
    return (
      <div>
        <ModalBody className="modalbodysuccess">
          <div className="d-flex flex-column justify-content-center align-items-center">
            <div
              className="f-md-h3 f-mweb-h2 text-center"
              style={{ color: '#505050' }}
            >
              Informasi rincian biaya telah kami kirim
            </div>
            <img
              src={mailpricesuccess}
              className="imagemodalsuccess"
              alt="Mail Price SUccess"
            />
            <div
              className="f-md-body-alt f-mweb-body-small text-center mt-3"
              style={{ color: '#6e6e6e' }}
            >
              Terima kasih, Kami telah mengirimkan informasi khusus terkait
              dengan program kami ke email kamu ya. Tunggu apa lagi?{' '}
              <span className="font-weight-bold">
                Cek email kamu sekarang!{' '}
              </span>
            </div>
          </div>
        </ModalBody>
      </div>
    );
  };

  const renderProgramOptions = () => {
    return (
      <div>
        <ModalHeader
          style={{ padding: '16px 16px 16px 32px' }}
          className="d-block"
        >
          <div className="d-flex">
            <div
              style={{ fontWeight: 'bold' }}
              className="f-md-h4 f-mweb-h2 mr-auto"
            >
              Jadwal Program Pilihan Kamu
            </div>
            <div
              style={{ height: '24px', width: '24px', cursor: 'pointer' }}
              onClick={toggle}
              onKeyDown={toggle}
            >
              <img alt=" closebutton" src={closeicon} />
            </div>
          </div>
        </ModalHeader>
        <ModalBody style={{ padding: '24px 32px 40px 32px' }}>
          {auth.accountVerified ? null : (
            <div
              className="alert mb-3"
              role="alert"
              style={{
                padding: '8px 30px 8px 10px',
                backgroundColor: '#fffbf4',
              }}
            >
              <div className="d-flex flex-row justify-content-center align-items-center">
                <div style={{ marginRight: '10px' }}>
                  <img
                    src={svginfowarning}
                    width="24px"
                    height="24px"
                    alt="infobutton"
                  />
                </div>
                <div
                  className="f-md-body-alt f-mweb-body-small"
                  f-mweb-body-small
                  style={{ color: '#6e6e6e' }}
                >
                  <span style={{ color: '#ffa630' }}>
                    Mohon{' '}
                    <span className="font-weight-md-bold">
                      verifikasi ulang
                    </span>{' '}
                    email kamu untuk mendaftar program ini.{' '}
                  </span>
                  Belum mendapat link verifikasi? - klik{' '}
                  <u
                    style={{ cursor: 'pointer' }}
                    onClick={() => resendEmailVerification()}
                    onKeyDown={() => null}
                  >
                    disini
                  </u>{' '}
                  untuk kirim ulang.
                </div>
              </div>
            </div>
          )}

          <select
            id="programchoice"
            required
            {...input.programchoice}
            onChange={(e) => {
              setProgramHeaderSlug(e.target.value);
              setScheduleData([]);
              setDropdownDisplay('Pilih salah satu jadwal & lokasi program');
              setDropdownValue({});
            }}
            className="py-3 mt-3 pwdk-form pwdk-form-icon select-dropdown-adjustment"
            style={{ width: '100%', backgroundImage: `url(${chevrondown})` }}
            value={programHeaderSlug}
          >
            <option value="" disabled selected hidden>
              {INITIAL_FORM.programchoice.placeholder}
            </option>
            {renderDropdownProgram()}
          </select>

          <select
            id="programchoice"
            required
            {...input.studyMethod}
            onChange={(e) => {
              setStudyMethod({
                ...studyMethod,
                id: e.target.value,
                name: studyMethodList.find(
                  (val) => val.id === Number(e.target.value),
                ).name,
              });
            }}
            className="py-3 mt-3 pwdk-form pwdk-form-icon select-dropdown-adjustment"
            style={{ width: '100%', backgroundImage: `url(${chevrondown})` }}
            value={studyMethod.id}
          >
            <option value="" disabled selected hidden>
              {INITIAL_FORM.studyMethod.placeholder}
            </option>
            {renderStudyMethod()}
          </select>
          <select
            id="programchoice"
            required
            {...input.studySchedule}
            onChange={(e) => {
              setStudySchedule({
                ...studySchedule,
                id: e.target.value,
                name: studyScheduleList.find(
                  (val) => val.id === Number(e.target.value),
                ).name,
              });
            }}
            className="py-3 mt-3 pwdk-form pwdk-form-icon select-dropdown-adjustment"
            style={{ width: '100%', backgroundImage: `url(${chevrondown})` }}
            value={studySchedule.id}
          >
            <option value="" disabled selected hidden>
              {INITIAL_FORM.studySchedule.placeholder}
            </option>
            {renderStudySchedule()}
          </select>

          <div
            className={`custom-dropdown-container ${
              DropdownCSS ? 'custom-dropdown-active' : ''
            } mb-4 mt-3`}
            onClick={() => setDropdownCSS(!DropdownCSS)}
            style={{
              height: 'fit-content',
              backgroundColor: `${
                dropdownDisplay.length !== 4
                  ? '#ffffff'
                  : 'rgba(179, 179, 179, 0.08)'
              }`,
            }}
            onKeyDown={() => setDropdownCSS(!DropdownCSS)}
          >
            <div className="custom-dropdown-placeholder f-md-body-alt f-mweb-body-small pb-3">
              {dropdownDisplay.length !== 4 ? (
                <div className="pb-5" style={{ color: '#b3b3b3' }}>
                  {INITIAL_FORM.locationchoice.placeholder}
                </div>
              ) : (
                <div className="f-md-body-alt f-mweb-body-small">
                  <div className="font-weight-bold">{dropdownDisplay[0]}</div>
                  <div>{dropdownDisplay[1]}</div>
                  <div>{dropdownDisplay[2]}</div>
                </div>
              )}
              {/* <div className="font-weight-bold">Purwadhika Campus BSD</div>
              <div>20 Jan 2020 - 27 Apr 2020</div>
              <div>| Mon - Fri 09.00 - 16.00</div> */}
            </div>

            <ul className="custom-dropdown-options f-md-body-alt f-mweb-body-small">
              {renderDropdownSchedule()}
            </ul>
          </div>

          <div
            className="d-flex flex-row justify-content-end align-items-center"
            // id="question"
          >
            {auth.accountVerified ? null : (
              <div className="d-none d-md-block">
                <div
                  className="d-flex flex-column justify-content-center align-items-center
                mr-2 pwdk-tooltip-container pwdk-tooltip-arrow-bottom pwdk-tooltip-center tooltipstyles"
                  id="question"
                >
                  ?
                  <div className="pwdk-tooltip-bottom pwdk-tooltip">
                    Mohon verifikasi email kamu untuk mendaftar
                  </div>
                </div>
              </div>
            )}
            {/* <PButton
              color={
                !programHeaderSlug ||
                dropdownDisplay.length !== 4 ||
                !auth.accountVerified
                  ? 'disabled'
                  : 'primary'
              }
              id="emailbutton"
              pill
              className="text-center w-sm-full"
              onClick={() => onBtnDaftarClick()}
              style={{ width: '156px' }}
              disabled={
                !programHeaderSlug ||
                dropdownDisplay.length !== 4 ||
                !auth.accountVerified
              }
              // color="alt"
            >
              {loading ? (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                  }}
                >
                  <RotateSpinner color="#53b59f" loading size={22} />
                </div>
              ) : (
                'Daftar Sekarang'
              )}
            </PButton> */}
            {loading ? (
              <PButton
                color="primary"
                id="emailbutton"
                style={{ width: '156px' }}
                className="text-center w-sm-full d-flex align-items-center justify-content-center"
              >
                <Spinner
                  color="light"
                  style={{ width: '20px', height: '20px' }}
                />
              </PButton>
            ) : (
              <PButton
                // disabled=
                color={
                  !programHeaderSlug ||
                  dropdownDisplay.length !== 4 ||
                  !auth.accountVerified ||
                  !studyMethod.id ||
                  !studySchedule.id
                    ? 'disabled'
                    : 'primary'
                }
                id="emailbutton"
                className="text-center w-sm-full"
                onClick={() => onBtnDaftarClick()}
                style={{ width: '156px' }}
                disabled={
                  !programHeaderSlug ||
                  dropdownDisplay.length !== 4 ||
                  !auth.accountVerified
                }

                // color="alt"
              >
                Daftar Sekarang
              </PButton>
            )}
          </div>
        </ModalBody>
      </div>
    );
  };

  const renderForm = () => {
    switch (form) {
      case 0:
        return renderFormLihatBiaya();
      case 'Hubungi Kami':
        return renderFormHubungiKami();
      case 2:
        return renderRegisterSuccess();
      case 3:
        return renderContactSuccess();
      case 4:
        return renderMailPriceSuccess();
      case 'Daftar Sekarang':
        return renderProgramOptions();
      case 6:
        return renderContantViaEmailSuccess();
      case 7:
        return renderDaftarSekarangCodestarter();
      case 8:
        return renderFreeTrialCodestarterNoLogin();
      default:
        return null;
    }
  };

  return (
    <Modal isOpen={open} size="lg" toggle={toggle} className="modalprogrampage">
      {convert ? <AdsConversionHelmet /> : null}
      {renderForm()}
    </Modal>
  );
};

export default ProgramPageModal;
