import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';

const useMekariQontak = () => {
  // !!!!
  // temp disable firebase
  // !!!!

  // // syncs firebase generalSetting data
  // const environment = process?.env?.REACT_APP_ENVIRONMENT;

  // useFirestoreConnect([
  //   {
  //     collection: 'generalSetting',
  //     doc: environment,
  //     storeAs: 'optionWhatsappNumber',
  //   },
  // ]);

  // // qontakNumber should bypass all picNumber if data is present on firestore
  // const qontakNumber = useSelector(
  //   (state) => state?.firestore?.data?.optionWhatsappNumber?.qontakNumber,
  // );

  // // to disable using qontakNumber, set qontakNumber to empty string on firebase

  const qontakNumber = '6285186029606';

  return { qontakNumber };
};

export default useMekariQontak;
