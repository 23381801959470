import React from 'react';
import ImageUnderMaintenance from 'assets/images/svg/under-construction.svg';
import Facebook from 'assets/images/svg/facebook.svg';
import Twitter from 'assets/images/svg/twitter.svg';
import Instagram from 'assets/images/svg/instagram.svg';
import Linkedin from 'assets/images/svg/linkedin.svg';
import './UnderMaintenance.css';
import { Link } from 'react-router-dom';

const IconSocialMedia = ({ icon, link }) => {
  return (
    <Link to={{ pathname: link }} target="_blank">
      <div className="wrapper-icon">
        <img src={icon} alt="social-media" />
      </div>
    </Link>
  );
};

const UnderMaintenance = () => {
  return (
    <div className="page-under-maintenance">
      <img
        src={ImageUnderMaintenance}
        alt="under-maintenance"
        className="image"
      />
      <div className="d-flex align-items-center flex-column pwdk-mt-2">
        <div className="main-text">
          This page is <span className="f--brand-color">under maintenance</span>
          !
        </div>
        <div className="sub-text">
          Terima kasih atas kesabaran anda, kami akan segera kembali!
        </div>
        <div className="sub-text">follow social media kami</div>
        <div className="d-flex pwdk-mt-2">
          <IconSocialMedia
            icon={Instagram}
            link="https://www.instagram.com/purwadhikaschool/"
          />
          <IconSocialMedia
            icon={Facebook}
            link="https://www.facebook.com/purwadhikadigitalschool"
          />
          <IconSocialMedia
            icon={Twitter}
            link="https://twitter.com/PurwadhikaClass"
          />
          <IconSocialMedia
            icon={Linkedin}
            link="https://www.linkedin.com/company/purwadhika-school"
          />
        </div>
      </div>
    </div>
  );
};

export default UnderMaintenance;
