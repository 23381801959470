import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Headroom from 'headroom.js';

import Navbar from './Navbar';
import LNavbar from './LanguageNavbar';
import NavbarMobile from './NavbarMobile';

import './index.css';

const NavBar = () => {
  const location = useLocation();
  const [activeMenu, setActiveMenu] = useState('');
  const [dropdownActive, setDropdownActive] = useState(false);
  const [loading, setLoading] = useState(true);
  const handleActiveMenu = (menu) => {
    if (activeMenu === menu) {
      setActiveMenu('');
      setDropdownActive(false);
    } else {
      setActiveMenu(menu);
    }
  };
  const handleNonActiveDropdown = () => {
    setDropdownActive(false);
    setActiveMenu('');
  };

  const RenderOverlay = () => {
    return (
      <div
        className={`overlay ${dropdownActive ? 'showNavbar' : ''}`}
        onClick={() => {
          setDropdownActive(false);
          setActiveMenu('');
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            setDropdownActive(false);
            setActiveMenu('');
          }
        }}
      />
    );
  };
  const setHeadroom = () => {
    const headroom = new Headroom(document.querySelector('#headerNav'), {
      offset: 0,
      tolerance: 5,
      classes: {
        initial: 'animated',
        pinned: 'slideDown',
        unpinned: 'slideUp',
      },
    });
    headroom.init();
  };

  const isExamPath =
    location.pathname.includes('/exam/') ||
    location.pathname.includes('/exam-result/');
  const isVPath = location.pathname.includes('/v/');
  const isDashboardPath = location.pathname.includes('/dashboard');
  // const isExecutivePath = location.pathname.includes('/executive-');
  const isCorporatePath = location.pathname.includes('/corporate-');
  const isStudentLearningPath = location.pathname.includes(
    '/student-learning/',
  );

  const showContainerDashboard =
    isDashboardPath || isVPath || isStudentLearningPath || isExamPath;

  const hideBoxShadow =
    isVPath ||
    isStudentLearningPath ||
    // isExecutivePath ||
    isCorporatePath ||
    isExamPath;

  const isCTPath = location.pathname.includes('/corporate-training');

  useEffect(() => {
    if (!(isDashboardPath || isVPath || isStudentLearningPath)) {
      setHeadroom();
    }
    setLoading(false);
  }, [location.pathname]);

  return (
    <>
      {RenderOverlay()}
      <header
        id="headerNav"
        className={`d-none d-lg-block ${
          isDashboardPath ? 'positionAbsolute' : ''
        }  ${isCorporatePath ? 'border-0' : ''}`}
        // tempo fix position absolute
      >
        <div
          className={` ${isCTPath ? `globalNavNew-ct` : `globalNavNew`}`}
          style={{
            height: '28px',
            color: 'blue',
          }}
        >
          <div
            className={`${
              showContainerDashboard
                ? 'container-nav-languange-dashboard'
                : 'container-nav'
            } `}
          >
            <LNavbar />
          </div>
        </div>

        <div
          className="globalNav"
          style={{
            boxShadow: hideBoxShadow ? 'none' : '',
            background: isCorporatePath ? 'none' : '',
          }}
        >
          <div
            className={`${
              showContainerDashboard
                ? 'container-nav-dashboard'
                : 'container-nav'
            } `}
          >
            {!loading && (
              <Navbar
                activeMenu={activeMenu}
                handleActiveMenu={handleActiveMenu}
                setDropdownActive={() => setDropdownActive(true)}
                dropdownActive={dropdownActive}
                handleNonActiveDropdown={handleNonActiveDropdown}
              />
            )}
          </div>
        </div>
      </header>
      <NavbarMobile
        toggle={() => setDropdownActive(!dropdownActive)}
        dropdownActive={dropdownActive}
      />
    </>
  );
};

export default NavBar;
