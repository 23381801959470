import React from 'react';
import 'assets/css/card.css';

const PCard = ({
  id,
  key,
  children,
  className = '',
  type = 'primary',
  hoverable,
  style,
}) => {
  let hoverCSS = '';
  if (type === 'primary' && hoverable) {
    hoverCSS = 'pwdk-card-hover-primary';
  }

  // if (type === 'special' && hoverable) {
  //   hoverCSS = 'pwdk-card-hover-special';
  // }

  return (
    <div
      id={id}
      key={key}
      className={`pwdk-card pwdk-card-${type} ${hoverCSS} ${className}`}
      style={{ ...style }}
    >
      {children}
    </div>
  );
};

export default PCard;
