import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import idn from 'assets/images/flags/idn.png';
import en from 'assets/images/flags/eng.png';
import { useLocation } from 'react-router-dom';
import tw from './image/twitter.png';
import fb from './image/fb.png';
import ig from './image/ig.png';
import lin from './image/In.png';

import tw2 from './image/twitter-white.png';
import fb2 from './image/fb-white.png';
import ig2 from './image/ig-white.png';
import lin2 from './image/in-white.png';
import LanguageModal from './ChangeLanguageModal';

export default () => {
  const { t } = useTranslation('home', {
    keyPrefix: 'home.navbar',
  });
  const language = t('language');
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const isCTPath = location.pathname.includes('/corporate-training');
  // alert(`${isCTPath}`);
  const renderNavbarTitle = () => {
    return (
      <div
        style={{ textDecoration: 'none', cursor: 'pointer', zIndex: '1002' }}
        onClick={() => setIsOpen(true)}
        onKeyDown={() => setIsOpen(true)}
      >
        {language === 'Indonesian' ? (
          <img src={idn} alt="flag" />
        ) : (
          <img src={en} alt="flag" />
        )}
        <u
          className=" d-md-inline "
          style={{
            paddingLeft: '5px',
            paddingTop: '100px',
            color: `${isCTPath ? `white` : `#6e6e6e`}`,
            fontSize: '13px',
          }}
        >
          {language}
        </u>
      </div>
    );
  };

  const renderNavbarContent = () => {
    return (
      <>
        <a
          className="logo-span"
          href="https://www.facebook.com/purwadhikadigitalschool"
        >
          <img src={`${isCTPath ? fb2 : fb}`} alt="fb" />
        </a>

        <a className="logo-span" href="https://twitter.com/PurwadhikaClass">
          <img src={`${isCTPath ? tw2 : tw}`} alt="tw" />
        </a>
        <a
          className="logo-span"
          href="https://www.linkedin.com/company/purwadhika-school"
        >
          <img src={`${isCTPath ? lin2 : lin}`} alt="lin" />
        </a>
        <a
          className="logo-span"
          href="https://www.instagram.com/purwadhikaschool"
        >
          <img src={`${isCTPath ? ig2 : ig}`} alt="ig" />
        </a>
      </>
    );
  };

  return (
    <nav className=" d-none d-md-flex pr-3" style={{ width: '100%' }}>
      <div />
      {renderNavbarTitle()}
      <ul className=" ml-auto">{renderNavbarContent()}</ul>
      <LanguageModal open={isOpen} toggle={() => setIsOpen(false)} />
    </nav>
  );
};
