import React from 'react';
import 'assets/css/forms.css';
import chevrondown from 'assets/images/svg/form/chevrondown.svg';
import { Alert } from 'reactstrap';

const PSelect = ({
  id,
  key,
  children,
  className,
  style,
  value,
  onBlur,
  onChange,
  valid = true,
  invalidColor = 'red',
  invalidMessage = '',
  disabled = false,
  ...props
}) => {
  const RenderInvalidMessage = () => {
    if (!valid) {
      return (
        <Alert
          className={`pwdk-label pwdk-label-${invalidColor} mt-2 mb-0 w-100`}
          style={{ border: 'none', marginTop: '-0.5rem !important;' }}
        >
          {invalidMessage}
        </Alert>
      );
    }

    return null;
  };

  return (
    <>
      <select
        id={id}
        key={key}
        className={`pwdk-form ${className} pwdk-form pwdk-form-icon ${
          disabled ? 'disabled' : ''
        }`}
        style={{
          ...style,
          width: '100%',
          backgroundImage: `url(${chevrondown})`,
        }}
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        disabled={disabled}
        {...props}
      >
        {children}
      </select>

      <RenderInvalidMessage />
    </>
  );
};

export default PSelect;
