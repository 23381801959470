import React, { useState } from 'react';

import styled from 'styled-components';
import theme from 'utils/theme';
import { Link, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import arrowGreen from 'assets/images/svg/arrowgreen_right.svg';
import {
  FullTimeNew,
  SkillAcceleratorNew,
  EventAndWorkshop,
  PurwadhikaCollege,
} from './links';

const Container = styled.div`
  width: 100vw;
`;

const Wrapper = styled.div`
  max-width: 1650px;
  margin-right: auto;
  margin-left: auto;
  padding: 42px 15px;
  display: grid;
  grid-template-columns: auto auto 1fr;
`;

const CategoryWrapper = styled.div`
  margin-right: 72px;
`;

const CategoryTitle = styled.div``;

const CategoryText = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #4f4f4f;
  margin-bottom: 1rem;
  transition: all 0.3s;
  cursor: pointer;
  display: flex;
  align-items: center;
  ${theme.breakpoints.down('lg')} {
    font-weight: 900;
    font-size: 12px;
    line-height: 15px;
    color: #505050;
    margin-bottom: 0.5rem;
  }
  &:hover {
    color: #32b280;
  }
`;

const Icon = styled.img`
  width: 18px;
  aspect-ratio: 1;
  margin-left: 1rem;
`;

const Separator = styled.div`
  width: 1px;
  background: #e0e0e0;

  margin-right: 48px;
`;

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: ${({ subProgramLength }) =>
    subProgramLength > 2 ? '1fr 1fr 1.5fr 1fr' : '1fr 1fr 1fr'};
  column-gap: ${({ subProgramLength }) =>
    subProgramLength > 2 ? '10px' : '80px'};
`;

const PurwadhikaCollegeContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 80px;
`;

const ProgramWrapper = styled.div``;

const ProgramTitle = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  color: #505050;
  margin-bottom: 1rem;
  ${theme.breakpoints.down('lg')} {
    font-weight: 900;
    font-size: 12px;
    line-height: 15px;
    color: #505050;
    margin-bottom: 0.5rem;
  }
`;

const ProgramTitleWrapper = styled.div`
  display: flex;
`;

const NewProgramTitleWrapper = styled.div``;

const DescWrapper = styled.div``;

const ProgramSub = styled.div`
  font-weight: 900;
  font-size: 11px;
  line-height: 14px;
  color: #bdbdbd;
  margin-bottom: 1rem;
  text-transform: uppercase;
  ${theme.breakpoints.down('lg')} {
    font-weight: 900;
    font-size: 12px;
    line-height: 15px;
    color: #505050;
    text-transform: none;
  }
`;

const ProgramDesc = styled.div`
  font-weight: 450;
  font-size: 14px;
  line-height: 18px;
  color: #828282;
`;

const ProgramList = styled.div``;

const List = styled.div`
  font-weight: 450;
  font-size: 14px;
  line-height: 18px;
  color: #4f4f4f;
  transition: all 0.3s;
  &:hover {
    color: #32b280;
  }
  ${theme.breakpoints.down('lg')} {
    font-size: 14px;
    line-height: 18px;
  }
`;

const NewProgram = styled.div`
  font-weight: 900;
  font-size: 9px;
  line-height: 11px;
  color: #32b280;
  padding: 4px;
  margin-top: 3px;
  background: #ebf7f2;
  margin-left: 0.5rem;
  ${theme.breakpoints.down('lg')} {
    font-size: 9px;
    line-height: 11px;
    margin-top: -3px;
  }
`;

const NewLocation = styled.span`
  color: ${theme.palette.green.primary};
  font-weight: 900;
  font-size: 10px;
  background-color: ${theme.palette.primary.lighter};
  padding: 4px;
  margin-left: 4px;
  height: fit-content;
`;

const programs = [
  {
    title: 'Job Connector Bootcamp',
    desc: 'job_connector',
    subPrograms: [
      {
        title: 'Job Connector Bootcamp (In Bahasa)',
        listPrograms: FullTimeNew.jobConnector,
      },
      {
        title: 'Job Connector Bootcamp (In English)',
        listPrograms: FullTimeNew.jobConnectorInternational,
      },
    ],
  },
  {
    title: 'Skill Accelerator Bootcamp',
    desc: 'accelerator_bootcamp',
    subPrograms: [
      {
        title: 'Data',
        listPrograms: SkillAcceleratorNew.data,
      },
      {
        title: 'Digital Marketing',
        listPrograms: SkillAcceleratorNew.digitalMarketing,
      },
      {
        title: 'UI/UX Design',
        listPrograms: SkillAcceleratorNew.uiuxDesign,
      },
    ],
  },
  {
    title: 'Events & Workshops',
    desc: 'event_and_workshop',
    subPrograms: [
      {
        title: '',
        listPrograms: EventAndWorkshop,
      },
    ],
  },
  {
    title: 'Purwadhika College',
    desc: 'purwadhika_college',
    isNew: true,
    subPrograms: [
      {
        title: 'Bachelor',
        listPrograms: PurwadhikaCollege.Bachelor,
      },
      {
        title: 'Master',
        listPrograms: PurwadhikaCollege.Master,
      },
      {
        title: 'MBA',
        listPrograms: PurwadhikaCollege.MBA,
      },
    ],
  },
];

const ProgramNew = ({ handleNonActiveDropdown }) => {
  const { t } = useTranslation('home', { keyPrefix: 'home.navbar.program' });

  const [handleProgram, setHandleProgram] = useState('Job Connector Bootcamp');

  const ProgramListContent = ({ program }) => {
    return (
      <>
        <DescWrapper>
          <ProgramTitleWrapper>
            <ProgramTitle>{program.title}</ProgramTitle>
            {program.isNew ? (
              <NewProgramTitleWrapper>
                <NewProgram>New Program</NewProgram>
              </NewProgramTitleWrapper>
            ) : null}
          </ProgramTitleWrapper>

          <ProgramDesc>{t(program.desc)}</ProgramDesc>
        </DescWrapper>
        {program.subPrograms.map((subProgram) => {
          return (
            <ProgramWrapper key={subProgram.title}>
              {subProgram.title ? (
                <ProgramSub>{subProgram.title}</ProgramSub>
              ) : null}
              <ProgramList>
                {subProgram.listPrograms.map((item) => {
                  const PathwayHref = `https://college.purwadhika.com/pathways${item.slug}`;
                  return (
                    <>
                      {program.desc === `purwadhika_college` ? (
                        <div className="mb-2">
                          <a
                            target="_blank"
                            href={PathwayHref}
                            rel="noopener noreferrer"
                          >
                            <List>{item.item}</List>
                            {item.isNew ? (
                              <NewProgram>New Program</NewProgram>
                            ) : null}
                          </a>
                        </div>
                      ) : (
                        <Link
                          key={item.item}
                          to={item.slug}
                          style={{ textDecoration: 'none' }}
                          onClick={handleNonActiveDropdown}
                          className="d-flex align-items-center mb-2"
                        >
                          <List>{item.item}</List>{' '}
                          {item.isNew ? (
                            <NewProgram>New Program</NewProgram>
                          ) : null}
                        </Link>
                      )}
                    </>
                  );
                })}
              </ProgramList>
            </ProgramWrapper>
          );
        })}
      </>
    );
  };

  return (
    <Container>
      <Wrapper className="container-nav">
        <CategoryWrapper>
          {programs.map((program) => {
            return (
              <CategoryTitle
                key={program.title}
                onClick={() => setHandleProgram(program.title)}
              >
                <CategoryText
                  style={{
                    color: handleProgram === program.title ? '#32B280' : '',
                  }}
                >
                  {program.title}
                  {handleProgram === program.title ? (
                    <Icon src={arrowGreen} alt="arrow-right" />
                  ) : null}
                </CategoryText>
              </CategoryTitle>
            );
          })}
        </CategoryWrapper>

        <Separator />

        {programs.map((program) => {
          if (handleProgram !== program.title) {
            return null;
          }

          if (program.desc === `purwadhika_college`) {
            return (
              <PurwadhikaCollegeContentWrapper key={program.title}>
                <ProgramListContent program={program} />
              </PurwadhikaCollegeContentWrapper>
            );
          }

          return (
            <ContentWrapper
              key={program.title}
              subProgramLength={program.subPrograms.length}
            >
              <ProgramListContent program={program} />
            </ContentWrapper>
          );
        })}
      </Wrapper>
    </Container>
  );
};

export default ProgramNew;
export { ProgramSub, List, NewProgram, NewLocation };
