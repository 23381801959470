const firebaseConfig = {
  apiKey: 'AIzaSyA1knZUXMUNsYcXyvalcTprDHuaESC5iAw',
  authDomain: 'purwadhika-42ca7.firebaseapp.com',
  databaseURL: 'https://purwadhika-42ca7.firebaseio.com',
  projectId: 'purwadhika-42ca7',
  storageBucket: 'purwadhika-42ca7.appspot.com',
  messagingSenderId: '578465137757',
  appId: '1:578465137757:web:dd2c94c8bbffdd5a0cf9b4',
  measurementId: 'G-ZLEQ84S669',
};

export default firebaseConfig;
