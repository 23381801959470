// below rule can be safely turned off thanks to immer used by redux toolkit
/* eslint-disable no-param-reassign */

// Reducers can directly reassign state, or return a new state, BUT NOT BOTH
// Detailed docs on state reassignment in reducers here https://redux-toolkit.js.org/api/createreducer/
// There are pitfalls to immer however, which you can read here https://immerjs.github.io/immer/docs/pitfalls

import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export const initialState = {
  ip: '',
  city: '',
  post_code: '',
  country: '',
  country_code: '',
  latitude: 0,
  longitude: 0,
};

const { actions, reducer } = createSlice({
  name: 'geolocation',
  initialState,
  reducers: {
    setGeolocation: (state, action) => {
      state.ip = action.payload.ip;
      state.city = action.payload.city;
      state.post_code = action.payload.post_code;
      state.country = action.payload.country;
      state.country_code = action.payload.country_code;
      state.latitude = action.payload.latitude;
      state.longitude = action.payload.longitude;
    },
  },
});

export default reducer;

export const { setGeolocation } = actions;

export const fetchGeolocation = () => async (dispatch) => {
  try {
    const { data } = await axios.get('https://ip.nf/me.json', {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
    });

    dispatch(setGeolocation(data.ip));
  } catch (err) {
    console.log(err);
  }
};
