import mixpanel from 'mixpanel-browser';
import { mixpanelToken } from 'supports/config';

mixpanel.init(mixpanelToken, {
  debug: process.env.REACT_APP_ENVIRONMENT !== 'production',
});

const actions = {
  identify: (id) => {
    mixpanel.identify(id);
  },
  register: (props) => {
    mixpanel.register(props);
  },
  track: (name, props) => {
    mixpanel.track(name, props);
  },
  people: {
    set: (props) => {
      mixpanel.people.set(props);
    },
  },
  reset: () => {
    mixpanel.reset();
  },
};

const Mixpanel = actions;

export default Mixpanel;
