import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import qs from 'querystring';
import { API_URL_1 } from 'supports/api-url/apiurl';
import { PButton } from 'components';

import ic_group from './image/ic_group.svg';
import ic_rp from './image/ic_Rp.svg';
import ic_emptycart from './image/cart-empty.svg';

const Cart = ({ cart, handleNonActiveDropdown }) => {
  const renderTotalPrice = (item) => {
    if (item[0].productType === 'online_course') {
      if (item[0].data.courseDiscount) {
        const discount = parseInt(item[0].data.courseDiscount.percentage, 10);
        return (
          item[0].price -
          (item[0].price * discount) / 100
        ).toLocaleString();
      }
      return item[0].price.toLocaleString();
    }
    return `${(item[0].price * item.length).toLocaleString()}`;
  };
  return (
    <div className="pwdk-pb-2">
      <div className="pwdk-pb-2 pwdk-pt-2 pwdk-pl-4 headerNavCart">
        <div className="headCartNav">Total : 0{cart.length} Pesanan</div>
      </div>
      {cart.map((item, index) => {
        // console.log(item)
        return (
          <div
            className="d-flex flex-row border-top pwdk-pt-2 pwdk-pb-2 pwdk-pl-4 pwdk-pr-4"
            key={String(index)}
          >
            <img
              src={
                item[0].data.image
                  ? API_URL_1 + item[0].data.image
                  : API_URL_1 + item[0].data.thumbnail
              }
              alt="thumbnail"
              className="cartListImage"
            />
            <div className="pwdk-ml-2">
              <div
                className="f-subheading-bold f--brand-color"
                style={{ textTransform: 'uppercase' }}
              >
                {item[0].certificate
                  ? 'Certificate Printing'
                  : item[0].data.category}
              </div>

              {item[0].data.name ? (
                <div className="titleCartList">
                  {item[0].data.name.length >= 30
                    ? `${item[0].data.name.substr(0, 30)}...`
                    : item[0].data.name}
                </div>
              ) : (
                <div className="titleCartList">
                  {item[0].data.title.length >= 30
                    ? `${item[0].data.title.substr(0, 30)}...`
                    : item[0].data.title}
                </div>
              )}
              <div className="d-flex additionalCart">
                <img src={ic_group} alt="ic_group" />
                <div className="ml-1">{`0${item.length} Peserta`}</div>
                <img src={ic_rp} alt="ic_rp" className="pwdk-ml-2" />
                <div className="ml-1">{renderTotalPrice(item)}</div>
              </div>
            </div>
          </div>
        );
      })}
      <div className="pwdk-pl-4 pwdk-pr-4 pwdk-pt-2 border-top">
        <Link
          to="/cart"
          style={{ textDecoration: 'none' }}
          onClick={handleNonActiveDropdown}
        >
          <PButton size="medium" className="w-100">
            Lihat Semua Pesanan
          </PButton>
        </Link>
        {/* <Link
          to="/cart"
          style={{ textDecoration: 'none' }}
          onClick={handleNonActiveDropdown}
        >
          <div className="btnCartNav">Lihat Keranjang</div>
        </Link> */}
      </div>
    </div>
  );
};

const CartEmpty = ({ handleNonActiveDropdown }) => {
  return (
    <div className="d-flex flex-column text-center pwdk-pl-3 pwdk-pt-3 pwdk-pr-3 pwdk-pb-3">
      <img src={ic_emptycart} alt="cart-empty" className="cartEmptyNav" />
      <div className="f-md-h6 pwdk-mt-2">Keranjang kamu kosong nih..</div>
      <div className="f-body-alt f--dark-gray pwdk-mt-2 pwdk-mb-3">
        Yuk lihat semua program kami dan temukan berbagai macam pilihan menarik
        hanya untuk kamu.
      </div>
      <Link
        to={`/courses/v2?${qs.stringify({
          program: 7,
          metode: 1,
          topic: 0,
          level: 0,
          durationId: '',
          priceId: 0,
          page: 1,
        })}`}
        style={{ textDecoration: 'none' }}
        onClick={handleNonActiveDropdown}
      >
        <PButton className="w-100">Lihat Semua Program</PButton>
      </Link>
    </div>
  );
};

export default ({ handleNonActiveDropdown }) => {
  const cart = useSelector((state) => state.cart);
  const carts = Object.values(cart.cart);
  if (carts.length > 0) {
    return (
      <Cart cart={carts} handleNonActiveDropdown={handleNonActiveDropdown} />
    );
  }
  return <CartEmpty handleNonActiveDropdown={handleNonActiveDropdown} />;
};
