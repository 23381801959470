import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { API_URL_1 } from 'supports/api-url/apiurl';
import axios from 'supports/api';
import { signOut } from 'redux/modules/auth';

// import ic_dashboard from './image/ic_dashboard.svg';

import ic_home from 'pages/dashboard-old/images/ic_home.svg';
// import ic_add_photo from '../../pages/dashboard-old/images/ic_add_photo.svg';
import ic_logout from 'pages/dashboard-old/images/ic_exit_to_app.svg';
import ic_contact from 'pages/dashboard-old/images/ic_perm_phone_msg.svg';
import ProgramPageModal from 'components/Program/ProgramPage/ProgramPageModal';
import { PButton } from 'components';
import moment from 'moment';
import ic_profile from './image/ic_profile.svg';

export default ({ handleNonActiveDropdown }) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const { t } = useTranslation('dashboard', {
    keyPrefix: 'dashboard.navbar',
  });
  const { firstName, lastName, email } = auth.user;

  const [programModal, setProgramModal] = useState(false);
  const [form, setForm] = useState(0);

  const lang = localStorage.getItem('i18nextLng');
  moment.locale(lang);
  const resendEmailVerification = () => {
    axios
      .post(`/auth/resendemailconfirmation`, { lang })
      .then((res) => {
        window.alert('Email verifikasi telah dikirim');
      })
      .catch((err) => {
        console.log(err);
        window.alert('Terjadi kesalahan saat mengirim verifikasi ulang');
      });
  };
  return (
    <>
      <ProgramPageModal
        toggle={() => setProgramModal(false)}
        formIndex={form}
        open={programModal}
        // defaultProgramID={this.state.programId}
      />
      <div className=" pwdk-pt-4" style={{ minWidth: '333px' }}>
        <div className=" profile-nav-border-bottom">
          <div className="d-flex flex-row pwdk-pl-4 pwdk-pr-4 pb-3">
            <img
              src={
                auth &&
                auth.user.profilePicture === '/defaultprofilepicture.png'
                  ? ic_profile
                  : API_URL_1 + auth.user.profilePicture
              }
              alt="ic_profile"
              className="user_profile_navbar mr-3"
            />
            <div className="d-flex flex-column">
              <div className="f-h6">{`${firstName} ${lastName}`}</div>
              <div
                className="d-flex flex-row align-items-center mt-1"
                style={{ position: 'relative' }}
              >
                <div className="f-body-alt f--dark-gray">{email}</div>
              </div>
            </div>
          </div>
        </div>
        {!auth.accountVerified ? (
          <div className="verifyBox border-top">
            <div className="f-md-h6">{t('verification.title')}</div>
            <div className="f-body-alt f--dark-gray pwdk-mt-2">
              {t('verification.subtitle')}
            </div>
            <PButton
              className="btnNavVerify"
              style={{
                backgroundColor: '#ffa630',
                border: '1px solid #ffa630',
              }}
              onClick={() => resendEmailVerification()}
            >
              {t('verification.button_send')}
            </PButton>
          </div>
        ) : null}
        <div className=" profile-nav-border-bottom">
          <Link
            to="/"
            style={{ textDecoration: 'none' }}
            onClick={() => {
              handleNonActiveDropdown();
            }}
          >
            <div className=" pwdk-pl-4 pwdk-pr-4 py-2">
              <div className="tab-profile-navbar py-2">
                <img
                  src={ic_home}
                  className="icon_navbar mr-3"
                  alt="contact_icon"
                />
                <span className="pt-1">Home</span>
              </div>
            </div>
          </Link>
        </div>
        <div className=" profile-nav-border-bottom">
          <div className=" pwdk-pl-4 pwdk-pr-4 py-2">
            <div
              className="tab-profile-navbar py-2"
              onClick={() => {
                setProgramModal(true);
                setForm('Hubungi Kami');
              }}
            >
              <img
                src={ic_contact}
                className="icon_navbar mr-3"
                alt="contact_icon"
              />
              <span className="pt-1">{t('verification.need_help')}</span>
            </div>
          </div>
        </div>
        <div>
          <div className=" pwdk-pl-4 pwdk-pr-4 py-2">
            <Link
              to="/"
              style={{ textDecoration: 'none' }}
              onClick={() => {
                handleNonActiveDropdown();
                dispatch(signOut());
              }}
            >
              <div className="tab-profile-navbar py-2 mb-2">
                <img
                  src={ic_logout}
                  className="icon_navbar mr-3"
                  alt="contact_icon"
                />
                <span className="pt-1">Logout</span>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};
