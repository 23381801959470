import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link, useLocation } from 'react-router-dom';
import qs from 'querystring';
import { Collapse } from 'reactstrap';

import { toggleModal, signOut } from 'redux/modules/auth';
import { API_URL_1 } from 'supports/api-url/apiurl';
// import FeatureFlagWrapper from 'components/feature-flag-wrapper';
import { PButton } from 'components';
import { useTranslation } from 'react-i18next';
import axios from 'supports/api';

// import executiveProgram from 'components/executive-program';
import globe from 'assets/images/globe.png';
import moment from 'moment';
import Logo from './image/logo.svg';
import ic_close from './image/ic_close.svg';
import ic_menuburger from './image/ic_menuburger.svg';
import ic_arrow from './image/ic_arrowsub.svg';
import ic_profile from './image/ic_profile.svg';
import ic_dashboard from './image/ic_dashboard.svg';
import ic_cart from './image/ic_cart.svg';
import LanguageModal from './ChangeLanguageModal';

// import LanguageModal from './ChangeLanguageModal';

import {
  // FullTime,
  JobConnector,
  // AfterHour,
  // FulltimeOnline,
  // WorkshopsOnline,
  ExecutiveProgram,
  // FullTimeRemote,
  // AfterHourRemote,
  EventAndWorkshop,
  JobAccelerator,
  FullTimeNew,
  SkillAcceleratorNew,
  PurwadhikaCollege,
} from './links';

import { ProgramSub, List, NewProgram, NewLocation } from './ProgramNew';

// const {
//   dataScience,
//   digitalMarketing,
//   uiuxDesign,
//   softwareDevelopment,
// } = ExecutiveProgram;

const programItems = {
  fullTime: [
    {
      title: 'Job Connector Bootcamp (In Bahasa)',
      list: FullTimeNew.jobConnector,
    },
    {
      title: 'Job Connector Bootcamp (In English)',
      list: FullTimeNew.jobConnectorInternational,
    },
  ],
  skillAccelerator: [
    {
      title: 'Data',
      list: SkillAcceleratorNew.data,
    },
    {
      title: 'Digital Marketing',
      list: SkillAcceleratorNew.digitalMarketing,
    },
    {
      title: 'UI/UX Design',
      list: SkillAcceleratorNew.uiuxDesign,
    },
  ],
};

const purwadhikaCollege = [
  {
    title: 'Bachelor',
    list: PurwadhikaCollege.Bachelor,
  },
  {
    title: 'Master',
    list: PurwadhikaCollege.Master,
  },
  {
    title: 'MBA',
    list: PurwadhikaCollege.MBA,
  },
];
const LocationsItems = () => {
  return {
    onlineMethod: [
      {
        item: 'Online Learning',
        slug: '/location/online',
      },
    ],
    onCampusMethod: [
      {
        item: 'BSD',
        slug: '/location/bsd',
      },
      {
        item: 'Jakarta',
        slug: '/location/jakarta',
      },
      {
        item: 'Batam',
        slug: '/location/batam',
      },
      {
        item: 'Bandung',
        slug: '/location/bandung',
        isNew: true,
      },
      {
        item: 'Jogja',
        slug: '/location/jogja',
        isNew: true,
      },
    ],
  };
};

const SubMenuItem = ({ items, toggle, subname }) => {
  if (!items) return null;
  return items.map((item, index) => {
    return (
      <Link
        to={item.slug}
        className="linkSubMenu d-flex"
        onClick={toggle}
        key={String(index)}
      >
        <div className="SubMenuItem">{item.item}</div>
        {item?.isNew && subname === 'ON CAMPUS METHOD' && (
          <NewLocation>New Location</NewLocation>
        )}
        {item?.isNew && subname !== 'ON CAMPUS METHOD' && (
          <div className="container-sideTitleNav">
            <div className="sideTitleNav">New Program</div>
          </div>
        )}
      </Link>
    );
  });
};

const SubMenuItemWithTitle = ({ items, toggle, isCollege, subname }) => {
  if (!items) return null;
  return items?.map((item) => {
    return (
      <div className="mb-4">
        <ProgramSub>{item?.title}</ProgramSub>
        {item?.list.map((list, index) => {
          const PathwayHref = `https://college.purwadhika.com/pathways${list.slug}`;
          return (
            <>
              {isCollege ? (
                <a target="_blank" href={PathwayHref} rel="noopener noreferrer">
                  <List>{list.item}</List>
                  {list.isNew ? <NewProgram>New Program</NewProgram> : null}
                </a>
              ) : (
                <Link
                  to={list.slug}
                  className="d-flex align-items-center mb-2"
                  onClick={toggle}
                  key={String(index)}
                >
                  <List>{list.item}</List>
                  {list.isNew ? <NewProgram>New Program</NewProgram> : null}
                </Link>
              )}
            </>
          );
        })}
      </div>
    );
  });
};

const SubMenu = ({
  submenu,
  setsubMenu,
  subname,
  listMenu,
  toggle,
  isWithTitle = false,
  isCollege = false,
  isNew = false,
}) => {
  return (
    <div className="border-bottom pwdk-ml-2 pwdk-mr-2">
      <div
        className=""
        onClick={() => {
          if (submenu === subname) {
            setsubMenu('');
          } else {
            setsubMenu(subname);
          }
        }}
        onKeyDown={() => {
          if (submenu === subname) {
            setsubMenu('');
          } else {
            setsubMenu(subname);
          }
        }}
      >
        <div className="subMenuNav d-flex ">
          <div>
            {subname}
            {listMenu && (
              <img
                src={ic_arrow}
                alt="ic-arrow-sub"
                className={`arrow-subitem ${
                  submenu === subname ? 'rotateArrowSubItem' : ''
                }`}
              />
            )}
          </div>

          <div className="ml-3 ">
            {isNew && <NewProgram>New Program</NewProgram>}
          </div>
        </div>
      </div>
      <Collapse isOpen={submenu === subname}>
        {isWithTitle ? (
          <SubMenuItemWithTitle
            items={listMenu}
            toggle={toggle}
            isCollege={isCollege}
          />
        ) : (
          <SubMenuItem items={listMenu} toggle={toggle} subname={subname} />
        )}
      </Collapse>
    </div>
  );
};

// const ProgramMenu = ({ menu, name, submenu, setsubMenu, toggle }) => {
//   return (
//     <>
//       <Collapse isOpen={menu === name}>
//         <SubMenu
//           submenu={submenu}
//           setsubMenu={setsubMenu}
//           listMenu={JobConnector}
//           subname="JOB CONNECTOR"
//           toggle={toggle}
//         />
//         {/* <SubMenu
//           submenu={submenu}
//           setsubMenu={setsubMenu}
//           listMenu={JobAccelerator}
//           subname="JOB ACCELERATOR"
//           toggle={toggle}
//         /> */}
//         <SubMenu
//           submenu={submenu}
//           setsubMenu={setsubMenu}
//           listMenu={ExecutiveProgram}
//           subname="DIGITAL EXECUTIVE PROGRAM"
//           toggle={toggle}
//         />
//         <SubMenu
//           submenu={submenu}
//           setsubMenu={setsubMenu}
//           listMenu={EventAndWorkshop}
//           subname="EVENTS AND WORKSHOPS"
//           toggle={toggle}
//         />

//         {/* <Link
//           style={{ textDecoration: 'none' }}
//           to="/codestarters"
//           onClick={toggle}
//         >
//           <SubMenu
//             submenu={submenu}
//             setsubMenu={setsubMenu}
//             subname="KIDS & TEENS"
//             toggle={toggle}
//           />
//         </Link> */}
//         <Link
//           style={{ textDecoration: 'none' }}
//           to={{ pathname: 'https://bachelordegree.purwadhika.com' }}
//           target="_blank"
//           onClick={toggle}
//         >
//           <SubMenu
//             submenu={submenu}
//             setsubMenu={setsubMenu}
//             subname="DEGREE PROGRAM"
//             toggle={toggle}
//           />
//         </Link>
//       </Collapse>
//     </>
//   );
// };

const ProgramMenu = ({ menu, name, submenu, setsubMenu, toggle }) => {
  return (
    <>
      <Collapse isOpen={menu === name}>
        <SubMenu
          submenu={submenu}
          setsubMenu={setsubMenu}
          listMenu={programItems.fullTime}
          subname="JOB CONNECTOR BOOTCAMP"
          toggle={toggle}
          isWithTitle
        />
        <SubMenu
          submenu={submenu}
          setsubMenu={setsubMenu}
          listMenu={programItems.skillAccelerator}
          subname="SKILL ACCELERATOR BOOTCAMP"
          toggle={toggle}
          isWithTitle
        />
        <SubMenu
          submenu={submenu}
          setsubMenu={setsubMenu}
          listMenu={EventAndWorkshop}
          subname="EVENTS & WORKSHOPS"
          toggle={toggle}
        />
        <SubMenu
          submenu={submenu}
          setsubMenu={setsubMenu}
          listMenu={purwadhikaCollege}
          subname="PURWADHIKA COLLEGE"
          toggle={toggle}
          isWithTitle
          isCollege
          isNew
        />
      </Collapse>
    </>
  );
};

const LocationMenu = ({ menu, name, toggle }) => {
  const [onlineMethodMenu, setOnlineMethodMenu] = useState('ONLINE METHOD');
  const [onCampusMethodMenu, setOnCampusMethodMenu] = useState(
    'ON CAMPUS METHOD',
  );
  return (
    <>
      <Collapse isOpen={menu === name}>
        <SubMenu
          submenu={onlineMethodMenu}
          setsubMenu={setOnlineMethodMenu}
          listMenu={LocationsItems().onlineMethod}
          subname="ONLINE METHOD"
          toggle={toggle}
        />
        <SubMenu
          submenu={onCampusMethodMenu}
          setsubMenu={setOnCampusMethodMenu}
          listMenu={LocationsItems().onCampusMethod}
          subname="ON CAMPUS METHOD"
          toggle={toggle}
        />
      </Collapse>
    </>
  );
};

// const ExecutiveMenu = ({ menu, name, submenu, setsubMenu, toggle }) => {
//   return (
//     <>
//       <Collapse isOpen={menu === name}>
//         <SubMenu
//           submenu={submenu}
//           setsubMenu={setsubMenu}
//           listMenu={dataScience}
//           subname="DATA SCIENCE"
//           toggle={toggle}
//         />
//         <SubMenu
//           submenu={submenu}
//           setsubMenu={setsubMenu}
//           listMenu={digitalMarketing}
//           subname="DIGITAL MARKETING"
//           toggle={toggle}
//         />
//         <SubMenu
//           submenu={submenu}
//           setsubMenu={setsubMenu}
//           listMenu={uiuxDesign}
//           subname="UIUX DESIGN"
//           toggle={toggle}
//         />
//         <SubMenu
//           submenu={submenu}
//           setsubMenu={setsubMenu}
//           listMenu={softwareDevelopment}
//           subname="SOFTWARE DEVELOPMENT"
//           toggle={toggle}
//         />
//       </Collapse>
//     </>
//   );
// };

const CorporateMenu = ({ menu, name, submenu, setsubMenu, toggle }) => {
  return (
    <>
      <Collapse isOpen={menu === name}>
        <Link
          style={{ textDecoration: 'none' }}
          to="/corporate-training"
          onClick={toggle}
        >
          <SubMenu
            submenu={submenu}
            setsubMenu={setsubMenu}
            subname="CORPORATE TRAINING"
            toggle={toggle}
          />
        </Link>
        {/* <Link
          style={{ textDecoration: 'none' }}
          to="/courses?topic=0&format=4&location=0"
          onClick={toggle}
        >
          <SubMenu
            submenu={submenu}
            setsubMenu={setsubMenu}
            subname="CORPORATE SHORT COURSE"
            toggle={toggle}
          />
        </Link> */}
        <a
          style={{ textDecoration: 'none' }}
          href="https://pcn.purwadhika.com"
          onClick={toggle}
        >
          <SubMenu
            submenu={submenu}
            setsubMenu={setsubMenu}
            subname="HIRING PARTNER"
          />
        </a>
      </Collapse>
    </>
  );
};

const InternationalProgramsMenu = ({
  menu,
  name,
  submenu,
  setsubMenu,
  toggle,
}) => {
  return (
    <>
      <Collapse isOpen={menu === name}>
        <Link
          style={{ textDecoration: 'none' }}
          to="/international/full-stack-web-development-bootcamp"
          onClick={toggle}
        >
          <SubMenu
            submenu={submenu}
            setsubMenu={setsubMenu}
            subname="Full Stack Development"
            toggle={toggle}
          />
        </Link>
        <Link
          style={{ textDecoration: 'none' }}
          to="/international/data-science-machine-learning-bootcamp"
          onClick={toggle}
        >
          <SubMenu
            submenu={submenu}
            setsubMenu={setsubMenu}
            subname="Data Science & Machine Learning"
            toggle={toggle}
          />
        </Link>
        <Link
          style={{ textDecoration: 'none' }}
          to="/international/digital-marketing-bootcamp"
          onClick={toggle}
        >
          <SubMenu
            submenu={submenu}
            setsubMenu={setsubMenu}
            subname="Digital Marketing"
          />
        </Link>

        <Link
          style={{ textDecoration: 'none' }}
          to="/international/ui-ux-design-bootcamp"
          onClick={toggle}
        >
          <SubMenu
            submenu={submenu}
            setsubMenu={setsubMenu}
            subname="UI/UX Design"
          />
        </Link>
      </Collapse>
    </>
  );
};

const AlumniMenu = ({ menu, name, submenu, setsubMenu, toggle }) => {
  return (
    <>
      <Collapse isOpen={menu === name}>
        <Link to="/alumni-stories" className="linkSubMenu" onClick={toggle}>
          <SubMenu
            submenu={submenu}
            setsubMenu={setsubMenu}
            subname="ALUMNI STORIES"
          />
        </Link>
        <Link
          style={{ textDecoration: 'none' }}
          to={{ pathname: 'https://alumni.purwadhika.com/' }}
          target="_blank"
          onClick={toggle}
        >
          <SubMenu
            submenu={submenu}
            setsubMenu={setsubMenu}
            subname="ALUMNI CLUB"
            toggle={toggle}
          />
        </Link>
      </Collapse>
    </>
  );
};

// const RemoteLearningMenu = ({ menu, name, submenu, setsubMenu, toggle }) => {
//   return (
//     <>
//       <Collapse isOpen={menu === name}>
//         <SubMenu
//           submenu={submenu}
//           setsubMenu={setsubMenu}
//           listMenu={FullTimeRemote}
//           subname="JOB CONNECTOR FULL TIME"
//           toggle={toggle}
//         />
//         <SubMenu
//           submenu={submenu}
//           setsubMenu={setsubMenu}
//           listMenu={AfterHourRemote}
//           subname="JOB CONNECTOR AFTER HOUR"
//           toggle={toggle}
//         />
//         {/* <SubMenu
//           submenu={submenu}
//           setsubMenu={setsubMenu}
//           listMenu={PartTimeRemote}
//           subname="PART TIME"
//           toggle={toggle}
//         /> */}
//         <Link
//           style={{ textDecoration: 'none' }}
//           to="/courses?topic=0&format=4&location=0"
//           onClick={toggle}
//         >
//           <SubMenu
//             submenu={submenu}
//             setsubMenu={setsubMenu}
//             subname="EVENT & WORKSHOP"
//             toggle={toggle}
//           />
//         </Link>
//         <Link
//           style={{ textDecoration: 'none' }}
//           to="/corporate"
//           onClick={toggle}
//         >
//           <SubMenu
//             submenu={submenu}
//             setsubMenu={setsubMenu}
//             subname="CORPORATE TRAINING"
//             toggle={toggle}
//           />
//         </Link>
//         <Link
//           style={{ textDecoration: 'none' }}
//           to="/codestarters"
//           onClick={toggle}
//         >
//           <SubMenu
//             submenu={submenu}
//             setsubMenu={setsubMenu}
//             subname="KIDS & TEENS"
//             toggle={toggle}
//           />
//         </Link>
//       </Collapse>
//     </>
//   );
// };

// const videoLearning = ({ menu, name, submenu, setsubMenu, toggle }) => {
//   return (
//     <>
//       <Collapse isOpen={menu === name}>
//         <FeatureFlagWrapper flag="showOnlineJC">
//           <SubMenu
//             submenu={submenu}
//             setsubMenu={setsubMenu}
//             listMenu={FulltimeOnline}
//             subname="JOB CONNECTOR"
//             toggle={toggle}
//           />
//         </FeatureFlagWrapper>
//         <SubMenu
//           submenu={submenu}
//           setsubMenu={setsubMenu}
//           listMenu={WorkshopsOnline}
//           subname="WORKSHOPS"
//           toggle={toggle}
//         />
//       </Collapse>
//     </>
//   );
// };

// const StudentMenu = ({ menu, name, submenu, setsubMenu, toggle }) => {
//   return (
//     <>
//       <Collapse isOpen={menu === name}>
//         {/* <a
//           href="https://purwadhika-career.com/"
//           target="_blank"
//           rel="noopener noreferrer"
//           className="linkSubMenu"
//           onClick={toggle}
//         >
//           <SubMenu
//             submenu={submenu}
//             setsubMenu={setsubMenu}
//             subname="CAREER NETWORK"
//           />
//         </a> */}
//         <Link to="/alumni-stories" className="linkSubMenu" onClick={toggle}>
//           <SubMenu
//             submenu={submenu}
//             setsubMenu={setsubMenu}
//             subname="STUDENT STORIES"
//           />
//         </Link>
//         <Link to="/scholarship" className="linkSubMenu" onClick={toggle}>
//           <SubMenu
//             submenu={submenu}
//             setsubMenu={setsubMenu}
//             subname="SCHOLARSHIP"
//           />
//         </Link>
//       </Collapse>
//     </>
//   );
// };

const AboutMenu = ({ menu, name, submenu, setsubMenu, toggle }) => {
  return (
    <>
      <Collapse isOpen={menu === name}>
        <Link to="/our-stories" className="linkSubMenu" onClick={toggle}>
          <SubMenu
            submenu={submenu}
            setsubMenu={setsubMenu}
            subname="OUR STORY"
          />
        </Link>
        <Link to="/scholarship" className="linkSubMenu" onClick={toggle}>
          <SubMenu
            submenu={submenu}
            setsubMenu={setsubMenu}
            subname="SCHOLARSHIP"
          />
        </Link>
        <Link to="/promo" className="linkSubMenu" onClick={toggle}>
          <SubMenu submenu={submenu} setsubMenu={setsubMenu} subname="PROMO" />
        </Link>
        <Link
          to="/belajar-sekarang-bayar-setelah-kerja"
          className="linkSubMenu"
          onClick={toggle}
        >
          <SubMenu
            submenu={submenu}
            setsubMenu={setsubMenu}
            subname="BELAJAR SEKARANG, BAYAR SETELAH KERJA!"
          />
        </Link>
        <Link to="/media-press" className="linkSubMenu" onClick={toggle}>
          <SubMenu
            submenu={submenu}
            setsubMenu={setsubMenu}
            subname="MEDIA & PRESS"
          />
        </Link>
        {/* <a
          href="https://purwadhika-career.com/"
          target="_blank"
          rel="noopener noreferrer"
          className="linkSubMenu"
          onClick={toggle}
        >
          <SubMenu
            submenu={submenu}
            setsubMenu={setsubMenu}
            subname="CAREER NETWORK"
          />
        </a> */}

        {/* temporary disable */}
        {/* <Link to="/community-partner" className="linkSubMenu" onClick={toggle}>
          <SubMenu
            submenu={submenu}
            setsubMenu={setsubMenu}
            subname="PURWADHIKA COMMUNITY PARTNER"
          />
        </Link> */}
        {/* <Link to="/underconstruction" className="linkSubMenu" onClick={toggle}>
          <SubMenu submenu={submenu} setsubMenu={setsubMenu} subname="CAREER" />
        </Link> */}
      </Collapse>
    </>
  );
};

const Menu = ({ menu, setMenu, name, Content, toggle }) => {
  const [submenu, setsubMenu] = useState('');

  return (
    <div>
      <div
        className={`menuNavMoobile border-bottom ${
          menu === name ? 'borderLeft' : ''
        }`}
        onClick={() => {
          if (menu === name) {
            setMenu('');
          } else {
            setMenu(name);
          }
        }}
        onKeyDown={() => {
          if (menu === name) {
            setMenu('');
          } else {
            setMenu(name);
          }
        }}
      >
        {name}
        <img
          src={ic_arrow}
          alt="ic-arrow"
          className={`arrow-item ${menu === name ? 'rotateArrowItem' : ''}`}
        />
      </div>
      <Content
        menu={menu}
        name={name}
        submenu={submenu}
        setsubMenu={setsubMenu}
        toggle={toggle}
      />
    </div>
  );
};

const MenuLanguage = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <div
        className={`menuNavMoobile border-bottom `}
        onClick={() => setIsOpen(true)}
        onKeyDown={() => setIsOpen(true)}
      >
        <img src={globe} alt="ic_cart_mobile" className="" />
        <span style={{ paddingLeft: '5px' }}>Language</span>
        <div>
          <img src={ic_arrow} alt="ic-arrow" className="arrow-item" />
        </div>
      </div>

      <LanguageModal open={isOpen} toggle={() => setIsOpen(false)} />
    </div>
  );
};

const lang = localStorage.getItem('i18nextLng');
moment.locale(lang);
const resendEmailVerification = () => {
  axios
    .post(`/auth/resendemailconfirmation`, { lang })
    .then((res) => {
      // window.alert('Email verifikasi telah dikirim');
    })
    .catch((err) => {
      console.log(err);
      // window.alert('Terjadi kesalahan saat mengirim verifikasi ulang');
    });
};

const Profile = ({ auth, menu, name, setMenu, toggle, toggleSignOut }) => {
  const { t } = useTranslation('dashboard', {
    keyPrefix: 'dashboard.navbar',
  });
  return (
    <div className="profileNavMobile border-bottom">
      <img
        src={ic_arrow}
        alt="ic-arrow"
        className={`arrow-item-profile ${
          menu === name ? 'rotateArrowItem' : ''
        }`}
      />
      <div
        className="pwdk-pl-2 pwdk-pl-2 pwdk-pr-2 pwdk-pt-3 pwdk-pb-3"
        onClick={() => {
          if (menu === name) {
            setMenu('');
          } else {
            setMenu(name);
          }
        }}
        onKeyDown={() => {
          if (menu === name) {
            setMenu('');
          } else {
            setMenu(name);
          }
        }}
      >
        <div className="d-flex align-items-center">
          <img
            src={
              auth && auth.user.profilePicture === '/defaultprofilepicture.png'
                ? ic_profile
                : API_URL_1 + auth.user.profilePicture
            }
            alt="ic_profileMobile"
            className="ic_profileNavMobile"
          />
          <div className="ml-2">
            <div
              className={`f-sm-h6 ${menu === name ? 'colorBrandProfile' : ''}`}
            >
              {auth.user.firstName} {auth.user.lastName}
            </div>
            <div className="f-mweb-body-small f--light-gray">
              {auth.user.email}
            </div>
          </div>
        </div>
      </div>
      <Collapse isOpen={menu === name}>
        <div className="pwdk-pl-2 pwdk-pl-2 pwdk-pr-2 pwdk-pb-3">
          <Link
            to="/dashboard"
            style={{ textDecoration: 'none' }}
            onClick={toggle}
          >
            <PButton className="w-100 mb-2" style={{ borderRadius: '6px' }}>
              <img
                src={ic_dashboard}
                alt="ic_dashboard-M"
                className="ic_dashboardMobile"
              />
              <span className="f--16">Dashboard</span>
            </PButton>
          </Link>
          <Link
            to="/"
            style={{ textDecoration: 'none' }}
            onClick={toggleSignOut}
          >
            <PButton
              className="w-100"
              style={{ borderRadius: '6px', backgroundColor: '#c2c2c21e' }}
              color="ghost"
              // pill
            >
              <span className="f--16">Keluar</span>
            </PButton>
          </Link>
          {!auth.accountVerified && (
            <div className="border-top pwdk-pt-3 text-center">
              <div className="f-sm-h6">{t('verification.title')}</div>
              <div className="f-body-alt f--dark-gray pwdk-mt-2">
                {t('verification.subtitle')}
              </div>
              <PButton
                className="btnVerifyMobile"
                onClick={() => resendEmailVerification()}
              >
                {t('verification.button_send')}
              </PButton>
            </div>
          )}
        </div>
      </Collapse>
    </div>
  );
};

export default ({ toggle, dropdownActive }) => {
  const auth = useSelector((state) => state.auth);
  const cart = useSelector((state) => state.cart);

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation('dashboard', {
    keyPrefix: 'dashboard.navbar',
  });
  const toggleSignIn = () => {
    toggle();
    dispatch(toggleModal());
  };
  const toggleSignOut = () => {
    toggle();
    dispatch(signOut());
  };
  const [menu, setMenu] = useState('');
  // const [submenu, setsubMenu] = useState('');
  // const [openMenu, setOpenMenu] = useState(false);

  // const isExecutivePath = location.pathname.includes('/executive-');
  const isCorporatePath = location.pathname.includes('/corporate-');

  const setClassNameForExecutiveAndCorporatePath = () => {
    // if (isExecutivePath) {
    //   return 'executive-program-nav';
    // }
    if (isCorporatePath) {
      return 'corporate-training-nav';
    }
    return 'headerNavMobile';
  };

  const renderLogo = () => {
    // if (isExecutivePath) {
    //   return (
    //     <Link
    //       className="navLogoMobile executive-logo-nav"
    //       to="/"
    //       style={{ textDecoration: 'none' }}
    //     >
    //       <div className="" />
    //     </Link>
    //   );
    // }
    if (isCorporatePath) {
      return (
        <Link
          className="navLogoMobile corporate-logo-nav"
          to="/"
          style={{ textDecoration: 'none' }}
        >
          <div className="" />
        </Link>
      );
    }
    return (
      <Link to="/">
        <img src={Logo} alt="navlogo-mobile" className="navLogoMobile" />
      </Link>
    );
  };

  return (
    <header
      className={`d-lg-none ${setClassNameForExecutiveAndCorporatePath()}`}
    >
      <div className="navbar-green-line" style={{ height: '2.5px' }} />
      <div className="globalNav">
        <div className="container-nav">
          <nav className="d-lg-none mobileNav">
            {renderLogo()}
            <div
              onClick={toggle}
              onKeyDown={toggle}
              style={{ position: 'relative' }}
            >
              <img
                src={dropdownActive ? ic_close : ic_menuburger}
                alt="btn-mobile"
                className="btnMobile"
              />
              {!dropdownActive &&
                Object.values(cart.cart).length > 0 &&
                auth.user.email && <div className="notifMobileNav" />}
            </div>
          </nav>
        </div>
      </div>
      <div className={`${dropdownActive ? 'border-bottom' : ''}`}>
        {auth.user.email && (
          <Collapse isOpen={dropdownActive}>
            <Profile
              menu={menu}
              setMenu={setMenu}
              // Content={ProgramMenu}
              name="Profile"
              auth={auth}
              toggle={toggle}
              toggleSignOut={toggleSignOut}
            />
          </Collapse>
        )}
        <Collapse isOpen={dropdownActive}>
          <Menu
            menu={menu}
            setMenu={setMenu}
            Content={ProgramMenu}
            name="Programs"
            toggle={toggle}
          />
        </Collapse>
        <Collapse isOpen={dropdownActive}>
          <Menu
            menu={menu}
            setMenu={setMenu}
            Content={LocationMenu}
            name="Locations"
            toggle={toggle}
          />
        </Collapse>
        {/* <Collapse isOpen={dropdownActive}>
          <Menu
            menu={menu}
            setMenu={setMenu}
            Content={InternationalProgramsMenu}
            name="International Programs"
            toggle={toggle}
          />
        </Collapse> */}
        {/* <Collapse isOpen={dropdownActive}>
          <Menu
            menu={menu}
            setMenu={setMenu}
            Content={ExecutiveMenu}
            name="For Professional"
            toggle={toggle}
          />
        </Collapse> */}
        <Collapse isOpen={dropdownActive}>
          <Menu
            menu={menu}
            setMenu={setMenu}
            Content={CorporateMenu}
            name="For Corporate"
            toggle={toggle}
          />
        </Collapse>
        <Collapse isOpen={dropdownActive}>
          <Menu
            menu={menu}
            setMenu={setMenu}
            Content={AlumniMenu}
            name="Alumni"
            toggle={toggle}
          />
        </Collapse>
        {/* <Collapse isOpen={dropdownActive}>
          <Menu
            menu={menu}
            setMenu={setMenu}
            Content={RemoteLearningMenu}
            name="Remote Learning"
            toggle={toggle}
          />
        </Collapse> */}
        {/* <Collapse isOpen={dropdownActive}>
          <Menu
            menu={menu}
            setMenu={setMenu}
            Content={videoLearning}
            name="Video Learning"
            toggle={toggle}
          />
        </Collapse> */}
        {/* <Collapse isOpen={dropdownActive}>
          <Menu
            menu={menu}
            setMenu={setMenu}
            Content={StudentMenu}
            name="Students"
            toggle={toggle}
          />
        </Collapse> */}
        <Collapse isOpen={dropdownActive}>
          <Menu
            menu={menu}
            setMenu={setMenu}
            Content={AboutMenu}
            name="About"
            toggle={toggle}
          />
        </Collapse>

        <Collapse isOpen={dropdownActive}>
          <MenuLanguage />
        </Collapse>

        {!auth.user.email && (
          <Collapse isOpen={dropdownActive}>
            <div
              className="loginNav border-bottom"
              onClick={toggleSignIn}
              onKeyDown={toggleSignIn}
            >
              <div className="f-mweb-body-small f--light-gray">
                {t('already_have_an_account')}
              </div>
              <div className="f-sm-h6 mt-1">Login</div>
              <img
                src={ic_arrow}
                alt="ic-arrow"
                className="arrow-item position"
              />
            </div>
            <Link
              to={{
                pathname: '/sign-up',
                state: { from: history.location.pathname },
              }}
              style={{ textDecoration: 'none' }}
              onClick={toggle}
            >
              <div className="loginNav">
                <div className="f-mweb-body-small f--light-gray">
                  {t('dont_have_an_account_yet')}
                </div>
                <div className="f-sm-h6 mt-1">{t('register_now')}</div>
                <img
                  src={ic_arrow}
                  alt="ic-arrow"
                  className="arrow-item position"
                />
              </div>
            </Link>
          </Collapse>
        )}
        {auth.user.email && (
          <Collapse isOpen={dropdownActive}>
            <Link
              to="/cart"
              style={{ textDecoration: 'none' }}
              onClick={toggle}
            >
              <div className="menuNavMoobile border-bottom">
                Cart
                <img
                  src={ic_cart}
                  alt="ic_cart_mobile"
                  className="ic_cart_mobile"
                />
                <div className="countCartMobile">
                  {Object.values(cart.cart).length}
                </div>
              </div>
            </Link>
          </Collapse>
        )}
      </div>
    </header>
  );
};
