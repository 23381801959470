import palette from './palette';

const breakpoints = {
  xs: '0px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
};

const theme = {
  breakpoints: {
    up: (size) => `@media (min-width: ${breakpoints[size]})`,
    down: (size) => `@media (max-width: ${breakpoints[size]})`,
  },
  palette: palette.light,
};

export default theme;
