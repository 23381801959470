import React from 'react';
import 'assets/css/buttons.css';
import ic_right from 'assets/images/svg/ic_right-brand.svg';

const PNudeButton = ({
  id,
  key,
  children,
  className,
  style,
  onClick = () => {},
}) => {
  return (
    <div
      id={id}
      key={key}
      className={`pwdk-nude-btn ${className || ''}`}
      style={{ ...style }}
      onClick={onClick}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
          e.target.click();
        }
      }}
      role="button"
      tabIndex={0}
    >
      {children}
      <img src={ic_right} alt="icon-right" className="mb-1" />
    </div>
  );
};

export default PNudeButton;
