import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  userProgress: 0,
  selectedCourse: null,
};

const { actions, reducer } = createSlice({
  name: 'studentLearning',
  initialState,
  reducers: {
    updateUserProgress: (state, action) => ({
      ...state,
      userProgress: action.payload,
    }),
    setSelectedCourse: (state, action) => ({
      ...state,
      selectedCourse: action.payload,
    }),
  },
});

export default reducer;

export const { updateUserProgress, setSelectedCourse } = actions;
