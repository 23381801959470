// ----------------------------------------------------------------------

function createGradient(color1, color2) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

// SETUP COLORS
const PASTEL = {
  green: '#EBF7F2',
  blue: '#D4EDFC',
  red: '#FDD5D6',
  orange: '#FFE3D6',
  yellow: '#FFF3E3',
  bluegrey: '#F7F8FA',
};
const GREEN = {
  primary: '#32B280',
  1: '#237C59',
  2: '#288E66',
  3: '#2DA073',
  100: '#004D3F',
  200: '#146153',
  300: '#287567',
  400: '#3C897B',
};
const BLUE = {
  1: '#0078C2',
  2: '#0082D3',
  3: '#018DE5',
  4: '#0197F6',
  100: '#4378FF',
  200: '#578CFF',
  300: '#6BA0FF',
  400: '#7FB4FF',
};
const RED = {
  1: '#CC3D42',
  2: '#D85055',
  3: '#E36367',
  4: '#EF767A',
};
const YELLOW = {
  1: '#CC7400',
  2: '#DD8510',
  3: '#EE9520',
  4: '#FFA630',
};
const PINK = {
  100: '#FEA0F6',
  200: '#FFB4FF',
  300: '#FFC8FF',
  400: '#FFDCFF',
};
const ORANGE = {
  1: '#C24D1B',
  2: '#D35A26',
  3: '#E36730',
  4: '#F4743B',
  100: '#FF6F02',
  200: '#FF8316',
  300: '#FF972A',
  400: '#FFAB3E',
};

const PRIMARY = {
  lighter: '#EBF7F2',
  light: '#DCf7F2',
  main: '#32B280',
  dark: '#004D3F',
  darker: '#004D3F',
};
const SECONDARY = {
  lighter: '#7FB4FF',
  light: '#7FB4FF',
  main: '#c9eeff',
  dark: '#4378ff',
  darker: '#4378ff',
};
// SETUP SEVERITY
const INFO = {
  light: '#E7FFFF',
  main: '#E7FFFF',
  dark: BLUE.dark,
};
const SUCCESS = {
  lighter: '#DCF7F2',
  light: '#DCF7F2',
  main: '#287567',
  dark: '#146153',
  darker: '#146153',
};
const WARNING = {
  lighter: '#FFF6EA',
  light: '#FFF6EA',
  main: '#FFF6EA',
  dark: '#FFAB3E',
  darker: '#FFAB3E',
};
const ERROR = {
  lighter: 'rgba(239, 118, 122, 0.1)',
  light: 'rgba(239, 118, 122, 0.1)',
  main: '#EF5D8E',
  dark: '#EF767A',
  darker: '#EF767A',
};

const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
  1: '#505050',
  2: '#4F4F4F',
  3: '#828282',
  4: '#BDBDBD',
  5: '#E0E0E0',
  6: '#F2F2F2',
};

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main),
};

const COMMON = {
  common: { black: '#000000', white: '#ffffff' },
  primary: { ...PRIMARY, contrastText: '#ffffff' },
  secondary: { ...SECONDARY, contrastText: '#ffffff' },
  info: { ...INFO, contrastText: '#ffffff' },
  success: { ...SUCCESS, contrastText: GREY[100] },
  warning: { ...WARNING, contrastText: GREY[800] },
  error: { ...ERROR, contrastText: '#ffffff' },
  grey: GREY,
  green: GREEN,
  orange: ORANGE,
  blue: BLUE,
  red: RED,
  yellow: YELLOW,
  pink: PINK,
  pastel: PASTEL,
  gradients: GRADIENTS,
  divider: GREY[500_24],
  action: {
    hoverPrimary: 'rgba(50, 178, 128, 0.05)',
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

const palette = {
  // LIGHT MODE
  light: {
    ...COMMON,
    mode: 'light',
    text: {
      primary: GREY[800],
      secondary: GREY[600],
      disabled: GREY[500],
    },
    background: {
      paper: '#ffffff',
      default: '#ffffff',
      neutral: GREY[200],
    },
    action: {
      active: GREY[600],
      ...COMMON.action,
    },
  },

  // DARK MODE
  dark: {
    ...COMMON,
    mode: 'dark',
    text: {
      primary: '#ffffff',
      secondary: GREY[500],
      disabled: GREY[600],
    },
    background: {
      paper: GREY[800],
      default: GREY[900],
      neutral: GREY[500_16],
    },
    action: {
      active: GREY[500],
      ...COMMON.action,
    },
  },
};

export default palette;
