import React from 'react';
import { Helmet } from 'react-helmet';

const PixelRegistrationConversionHelmet = () => {
  return (
    <div>
      <Helmet>
        <script>
          {`
          fbq('track', 'CompleteRegistration');
          `}
        </script>
      </Helmet>
    </div>
  );
};

export default PixelRegistrationConversionHelmet;
