import React from 'react';
import 'assets/css/messages.css';

const PMessage = ({
  id,
  key,
  children,
  style,
  className,
  color = 'default',
}) => {
  return (
    <div
      id={id}
      key={key}
      className={`pwdk-msg pwdk-msg-${color} ${className}`}
      style={{ ...style }}
    >
      {children}
    </div>
  );
};

export default PMessage;
