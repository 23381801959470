import React from 'react';
import { Link } from 'react-router-dom';

export default ({ handleNonActiveDropdown }) => {
  return (
    <div className="pwdk-pl-4 pwdk-pr-4 pwdk-pt-4 pwdk-pb-4">
      <Link
        to="/our-stories"
        style={{ textDecoration: 'none' }}
        onClick={handleNonActiveDropdown}
      >
        <div className="subtitleNavi">Our Story</div>
      </Link>
      <Link
        to="/scholarship"
        style={{ textDecoration: 'none' }}
        onClick={handleNonActiveDropdown}
      >
        <div className="subtitleNavi">Scholarship</div>
      </Link>
      <Link
        to="/promo"
        style={{ textDecoration: 'none' }}
        onClick={handleNonActiveDropdown}
      >
        <div className="subtitleNavi">Promo</div>
      </Link>
      <Link
        to="/belajar-sekarang-bayar-setelah-kerja"
        style={{ textDecoration: 'none' }}
        onClick={handleNonActiveDropdown}
      >
        <div className="subtitleNavi">
          Belajar Sekarang, Bayar Setelah Kerja!
        </div>
      </Link>
      <Link
        to="/media-press"
        style={{ textDecoration: 'none' }}
        onClick={handleNonActiveDropdown}
      >
        <div className="subtitleNavi">Media & Press</div>
      </Link>
      {/* temporary disable */}
      {/* <Link
        to="/community-partner"
        style={{ textDecoration: 'none' }}
        onClick={handleNonActiveDropdown}
      >
        <div className="subtitleNavi">Purwadhika Community Partner</div>
      </Link> */}

      {/* <Link
        to="/underconstruction"
        style={{ textDecoration: 'none' }}
        onClick={handleNonActiveDropdown}
      >
        <div className="subtitleNavi">Career</div>
      </Link> */}
    </div>
  );
};
