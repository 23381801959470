import React from 'react';
import styled from 'styled-components';
import PhoneInput from 'react-phone-input-2';

import theme from 'utils/theme';
import { Alert } from 'reactstrap';

import 'react-phone-input-2/lib/bootstrap.css';

const InputPhone = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  .react-tel-input {
    box-shadow: none;
    .form-control {
      width: 100%;
      height: 53px;
      padding-top: 0;
      font-size: 14px;
      box-shadow: none;
      padding-bottom: 0;
      border-color: #b3b3b3;
      font-family: CircularStd;
      background-color: ${({ value }) => value && '#f9f9f9'};
      :focus {
        box-shadow: none;
        border-color: #6e6e6e;
      }
      ::placeholder {
        color: #b3b3b3;
      }
      ${theme.breakpoints.down('sm')} {
        height: 50px;
        font-size: 12px;
      }
    }
    .selected-flag {
      padding-left: 16px;
      :before {
        border: none;
      }
      :focus {
        :before {
          border: none;
          box-shadow: none;
        }
      }
    }
    .selected-flag.open:before {
      border: none;
      box-shadow: none;
    }
  }
`;

// const phoneRegExp = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;
const phoneRegExp = /^\d{8,}$/;

const RenderInvalidMessage = ({ valid, invalidColor, invalidMessage }) => {
  if (!valid) {
    return (
      <Alert
        className={`pwdk-label pwdk-label-${invalidColor} mt-2 mb-0 w-100`}
        style={{ border: 'none' }}
      >
        {invalidMessage}
      </Alert>
    );
  }

  return null;
};

const PInputPhoneNumber = ({
  value,
  onBlur,
  onChange,
  placeholder,
  valid = true,
  invalidMessage,
  invalidColor = 'red',
  defaultPhoneCode = 'id',
  ...otherProps
}) => {
  return (
    <>
      <div className="pwdk-form-icon-container">
        <InputPhone value={value}>
          <PhoneInput
            value={value}
            onBlur={onBlur}
            onChange={onChange}
            placeholder={placeholder}
            country={defaultPhoneCode}
            inputProps={{ ...otherProps }}
          />
        </InputPhone>
        <span className="font-icon-adjustment icon-ic_phone" />
      </div>
      <RenderInvalidMessage
        valid={valid}
        invalidColor={invalidColor}
        invalidMessage={invalidMessage}
      />
    </>
  );
};

export default PInputPhoneNumber;
export { phoneRegExp };
