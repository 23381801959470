import React from 'react';
import 'assets/css/forms.css';
import { Alert } from 'reactstrap';

const PTextArea = ({
  id,
  key,
  maxLength = null,
  minLength = 0,
  value = '',
  placeholder,
  onBlur,
  onChange,
  className,
  required,
  style,
  rows = 3,
  cols,
  valid = true,
  invalidColor = 'red',
  invalidMessage = '',
}) => {
  const maxLengthParsed = parseInt(maxLength, 10);
  let maxCSS = '';
  if (maxLengthParsed > 0) {
    maxCSS = 'pwdk-textarea-container';
  }

  const RenderInvalidMessage = () => {
    if (!valid) {
      return (
        <Alert
          className={`pwdk-label pwdk-label-${invalidColor} mt-2 mb-0 w-100`}
          style={{ border: 'none' }}
        >
          {invalidMessage}
        </Alert>
      );
    }

    return null;
  };

  return (
    <div
      id={id}
      key={key}
      className={`${maxCSS} ${className} ${
        minLength !== 0 ? 'min-length' : ''
      }`}
      style={{ ...style }}
      data-maxchar={maxLengthParsed}
      data-currentchar={value.length}
      data-minchar={minLength}
    >
      <textarea
        required={required}
        className="pwdk-form"
        maxLength={maxLengthParsed}
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        placeholder={placeholder}
        rows={rows}
        cols={cols}
      />

      <RenderInvalidMessage />
    </div>
  );
};

export default PTextArea;
