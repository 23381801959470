import React, { useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import PButton from 'components/PButton';

import closeicon from 'assets/images/svg/crossbutton.svg';
import chevrondown from 'assets/images/svg/form/chevrondown.svg';

import { useTranslation } from 'react-i18next';
import moment from 'moment';

const ChangeLanguageModal = ({ open, toggle }) => {
  //   const [input, setInput] = useState({});
  const { t } = useTranslation('home', {
    keyPrefix: 'home.navbar',
  });

  const INITIAL_FORM = {
    Language: {
      value: '',
      placeholder: `${t('language')}*`,
      invalidFeedback: 'Pick One',
      invalid: false,
    },
  };
  const [input, setInput] = useState({ ...INITIAL_FORM });

  const handleChange = (elementName, event) => {
    setInput({
      ...input,
      [elementName]: {
        ...input[elementName],
        value: event.target.value,
      },
    });
  };

  const ChangeLanguage = () => {
    const lang = localStorage.setItem('i18nextLng', ...input.Language.value);
    moment.locale(lang);
    toggle();
    window.location.reload();
  };

  const renderChangeLanguage = () => {
    return (
      <div>
        <ModalHeader
          style={{ padding: '16px 16px 16px 32px', borderBottom: '0px' }}
          className="d-block"
        >
          <div className="d-flex">
            <div
              style={{ fontWeight: 'bold' }}
              className="f-md-h4 f-mweb-h2  mr-auto"
            >
              Change Language
            </div>
            <div className="d-none d-md-block" onClick={toggle} onKeyPress>
              <img
                alt=" closebutton"
                src={closeicon}
                className="closeModalCorporate"
              />
            </div>
          </div>
        </ModalHeader>
        <ModalBody style={{ paddingTop: '0px' }}>
          <select
            required
            className="pwdk-form mb-md-3 mb-2 pwdk-form pwdk-form-icon"
            style={{ width: '100%', backgroundImage: `url(${chevrondown})` }}
            onChange={(e) => handleChange('Language', e)}
            value={input.Language.value}
          >
            <option value="" disabled selected hidden>
              {INITIAL_FORM.Language.placeholder}
            </option>
            <option value="en">English*</option>
            <option value="in">Indonesian*</option>
          </select>

          <div style={{ fontSize: '12px', paddingBottom: '20px' }}>
            *Some pages are will remain in Indonesian
          </div>
          <PButton
            color="primary"
            style={{ width: '100%' }}
            onClick={() => ChangeLanguage()}
          >
            <div style={{ fontSize: '16px' }}>Change Language</div>
          </PButton>

          <div style={{ paddingBottom: '15px' }} />

          <PButton
            color="ghost"
            style={{ width: '100%' }}
            onClick={toggle}
            className="d-block d-md-none"
          >
            <div style={{ fontSize: '16px' }}>Cancel</div>
          </PButton>
        </ModalBody>
      </div>
    );
  };

  return (
    <>
      <Modal
        isOpen={open}
        toggle={toggle}
        style={{ maxWidth: '392px', maxHeight: '245px' }}
      >
        {renderChangeLanguage()}
      </Modal>
    </>
  );
};

export default ChangeLanguageModal;
