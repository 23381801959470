// below rule can be safely turned off thanks to immer used by redux toolkit
/* eslint-disable no-param-reassign */

// Reducers can directly reassign state, or return a new state, BUT NOT BOTH
// Detailed docs on state reassignment in reducers here https://redux-toolkit.js.org/api/createreducer/
// There are pitfalls to immer however, which you can read here https://immerjs.github.io/immer/docs/pitfalls

import axios from 'supports/api';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  cart: {},
  subtotal: 0,
  totalDiscount: 0,
  totalPrice: 0,
  cartChecked: false,
  referralCode: '',
};

const { actions, reducer } = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    gettingCart: (state, action) => ({ ...state, ...action.payload }),
    updateCart: (state, action) => ({ ...state, ...action.payload }),
    finishLoading: (state) => ({ ...state, cartChecked: true }),
    emptyCart: () => initialState,
  },
});

export default reducer;

export const {
  gettingCart,
  updateCart,
  emptyCart,
  loadingCart,
  finishLoading,
} = actions;

export const getCart = (userId, referralCode) => {
  return (dispatch) => {
    const storageCart = localStorage.getItem(`cart_${userId}`);

    if (storageCart) {
      const localCart = JSON.parse(storageCart);
      const groupCart = {};

      const body = {
        transactionDetails: localCart,
        referralCode,
      };

      axios
        .post(`/transaction/parse-bills`, body)
        .then((res) => {
          res.data.result.forEach((item) => {
            if (!Object.keys(groupCart).includes(item.data.id.toString())) {
              groupCart[item.data.id] = [];
            }
            groupCart[item.data.id].push(item);
          });

          dispatch(
            gettingCart({
              cart: groupCart,
              subtotal: res.data.subtotal,
              totalDiscount: res.data.totalDiscount,
              totalPrice: res.data.totalPrice,
              referralCode: res.data.referralCode,
              cartChecked: true,
            }),
          );
        })
        .catch((err) => {
          console.log(err);
          dispatch(finishLoading());
        });
    } else {
      dispatch(finishLoading());
    }
  };
};

export const addToCart = (cart, userId) => {
  return (dispatch) => {
    let localCart = [];
    const storageCart = JSON.parse(localStorage.getItem(`cart_${userId}`));
    if (storageCart) {
      localCart = [...storageCart];
    }
    cart.forEach((item) => {
      // checks if its program and localcart.length > 0
      if (item.productType === 'program' && localCart.length) {
        const foundIndex = localCart.findIndex((local) => {
          return local.id === item.id;
        });
        if (foundIndex >= 0) {
          // if there is a cart found (by program id), concat attendees
          localCart[foundIndex].attendees = localCart[
            foundIndex
          ].attendees.concat(item.attendees);
        } else {
          localCart.push(item);
        }
      } else {
        localCart.push(item);
      }
    });
    localStorage.setItem(`cart_${userId}`, JSON.stringify(localCart));
    return dispatch(getCart(userId));
  };
};

const setCart = (obj, userId) => {
  const cart = [];
  Object.keys(obj).forEach((objKey) => {
    obj[objKey].forEach((item) => {
      cart.push(item);
    });
  });
  localStorage.setItem(`cart_${userId}`, JSON.stringify(cart));
};

export const deleteGroup = (cart, objKey, userId) => {
  return (dispatch) => {
    const localCart = JSON.parse(JSON.stringify(cart));
    delete localCart[objKey];
    setCart(localCart, userId);
    dispatch(
      updateCart({
        cart: localCart,
      }),
    );
    dispatch(getCart(userId));
  };
};

export const deleteCart = (cart, objKey, objIndex, userId) => {
  return (dispatch) => {
    const localCart = JSON.parse(JSON.stringify(cart));
    localCart[objKey][0].attendees.splice(objIndex, 1);
    // checks if there is no attendees left
    if (localCart[objKey][0].attendees.length === 0) {
      // if true, delete cart item
      // might break for Workshop Video Learning ??
      delete localCart[objKey];
    }
    setCart(localCart, userId);
    dispatch(
      updateCart({
        cart: localCart,
      }),
    );
    dispatch(getCart(userId));
  };
};

export const clearCart = (userId) => {
  return (dispatch) => {
    localStorage.removeItem(`cart_${userId}`);
    dispatch(emptyCart());
  };
};
