import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './footer.css';
import ModalLecturer from 'pages/home-old/lectureregistrationmodal';
import { useTranslation } from 'react-i18next';
import idn from 'assets/images/flags/idn.png';
import en from 'assets/images/flags/eng.png';

const Footer = () => {
  const { t } = useTranslation('home', {
    keyPrefix: 'home.navbar',
  });
  const language = t('language');
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  let backgroundColor = '#ffffff';
  let containerFooter = 'container mb-5';
  let classNameFooterLine = 'footer-line';

  // if (location.pathname.includes('/executive-')) {
  //   backgroundColor = '#323232';
  //   containerFooter = 'container';
  //   classNameFooterLine = 'footer-line-executive-program';
  // }

  if (location.pathname.includes('/corporate-')) {
    backgroundColor = '#323232';
    containerFooter = 'container';
    classNameFooterLine = 'footer-line-corporate-training';
  }

  const Socials = () => {
    return (
      <div>
        <div className="footer-head mb-sm-2 mb-3">FOLLOW US</div>
        <div className="mb-1">
          <a
            href="https://www.facebook.com/purwadhikadigitalschool"
            rel="noopener noreferrer"
            className="footer-link mb-1"
            target="_blank"
          >
            Facebook
          </a>
        </div>
        <div className="mb-1">
          <a
            href="https://www.instagram.com/purwadhikaschool"
            rel="noopener noreferrer"
            className="footer-link mb-1"
            target="_blank"
          >
            Instagram
          </a>
        </div>
        <div className="mb-1">
          <a
            href="https://www.youtube.com/channel/UCzDNsDnN55mXW65zSGAnHeQ"
            rel="noopener noreferrer"
            className="footer-link mb-1"
            target="_blank"
          >
            Youtube
          </a>
        </div>
        <div className="mb-1">
          <a
            href="https://open.spotify.com/show/4W5bdB2vk3ddoBFq8J9JO0"
            rel="noopener noreferrer"
            className="footer-link mb-1"
            target="_blank"
          >
            Spotify Podcast
          </a>
        </div>
        <div className="mb-1">
          <a
            href="https://medium.com/purwadhikaconnect"
            rel="noopener noreferrer"
            target="_blank"
            className="footer-link mb-1"
          >
            Medium
          </a>
        </div>
      </div>
    );
  };

  const Sitemap = () => {
    return (
      <div className="col-12 col-sm-2 footer-body order-1">
        <div className="row">
          <div className="col-6 col-sm-12">
            <div className="mt-0 mt-sm-4  mb-3 footer-head">SITEMAP</div>
            <div>
              <div className="mb-1 mb-sm-4">
                <div id="links">
                  <Link to="/courses" className="footer-link">
                    Programs
                  </Link>
                </div>
                <div className="pt-1">
                  <div id="links">
                    <Link
                      to="/alumni-stories"
                      className="footer-link"
                      onClick={() => window.scrollTo(0, 0)}
                    >
                      Student Life
                    </Link>
                  </div>
                </div>

                {/* <div className="pt-1">
                  <a
                    href="https://purwadhika-career.com/"
                    target="blank"
                    className="footer-link"
                  >
                    <div className="footer-link">Career Network</div>
                  </a>
                </div> */}
              </div>
              <div>
                <a
                  href="https://medium.com/purwadhikaconnect"
                  className="footer-link"
                  target="blank"
                >
                  Blog
                </a>
              </div>
              <div className="pt-1">
                <Link to="/our-stories" className="footer-link">
                  About us
                </Link>
              </div>
              <div className="pt-1">
                <Link to="/verify-certificate" className="footer-link">
                  Verify Certificate
                </Link>
              </div>
              <div
                className="pt-1 cursor-pointer footer-link"
                onClick={() => setIsOpen(true)}
                onKeyDown={() => setIsOpen(true)}
              >
                {/* <Link to="/not-found" className="footer-link"> */}
                Become a lecturer
                {/* </Link> */}
              </div>
            </div>
          </div>
          <div className="col-6 col-sm-12 footer-body d-sm-none d-block">
            {/* DISPLAY CONTENT ONLY ON MOBILE RESOLUTION */}
            <Socials />
          </div>
        </div>
      </div>
    );
  };

  const Address = () => {
    return (
      <div className="col-12 col-sm-3 footer-body order-3 order-sm-2">
        <div className="mt-4 mb-3 footer-head">OUR CAMPUS</div>
        <div className="row">
          <div className=" col-sm-12 mb-1">
            <a
              className="footer-link"
              href={`${process.env.PUBLIC_URL}/location/bsd`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div>Purwadhika Campus BSD</div>
            </a>
          </div>
          <div className="col-sm-12 mb-1">
            <a
              className="footer-link"
              href={`${process.env.PUBLIC_URL}/location/jakarta`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div>Purwadhika Campus Jakarta</div>
            </a>
          </div>
          <div className=" col-sm-12 mb-1">
            <a
              className="footer-link"
              href={`${process.env.PUBLIC_URL}/location/batam`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div>Purwadhika Campus Batam</div>
            </a>
          </div>
          <div className="col-sm-12 mb-1">
            <a
              className="footer-link"
              href={`${process.env.PUBLIC_URL}/location/jogja`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div>Purwadhika Campus Jogja</div>
            </a>
          </div>
          <div className="col-sm-12 mb-1">
            <a
              className="footer-link"
              href={`${process.env.PUBLIC_URL}/location/bandung`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div>Purwadhika Campus Bandung</div>
            </a>
          </div>
          {/* <div className="col-sm-6 mb-4 mb-sm-0">
            <a
              className="footer-link"
              href="https://www.google.com/maps/place/Plaza+Summarecon+Bekasi/@-6.2259425,106.9943398,17z/data=!3m1!4b1!4m5!3m4!1s0x2e698c1a3dcc8871:0x67855368508630b2!8m2!3d-6.2259478!4d106.9965285"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div>Purwadhika Campus Bekasi</div>

              <div className="d-sm-block d-none">
                <div className="pt-1">Plaza Summarecon Bekasi</div>
                <div className="pt-1">7th Floor, Bekasi</div>
              </div>
              <div className="d-sm-none d-block">
                <div className="mt-1">
                  Plaza Summarecon Bekasi, 7th Floor, Bekasi
                </div>
              </div>
            </a>
          </div> */}
        </div>
      </div>
    );
  };

  const Contact = () => {
    return (
      <div className="col-12 col-sm-3 footer-body order-2 order-sm-3">
        <div className="mt-4 mb-3 footer-head">CONTACT US</div>
        <div className="row p-0">
          <div className="col-md-12 col-6 mb-3 mb-sm-5">
            <div className="mb-1">021 5012 3161 (BSD & Batam)</div>
            <div className="mb-1">021 5050 1361 (JKT)</div>
          </div>
          <div className="col-md-12 col-6">
            <div>
              <div className="mb-1">0851 8602 9606 (Whatsapp)</div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const Hours = () => {
    return (
      <div className="col-12 col-sm-4 order-4">
        <div className="row">
          <div className="col-6 col-sm-7 footer-body d-flex flex-column mb-4">
            <div className="mt-4  mb-3 footer-head">OPERATIONAL HOURS</div>
            <div>Monday - Friday</div>
            <div className="pt-1">09.00 - 18.00</div>
          </div>
          <div className="col-6 col-sm-5 mt-4 footer-body d-sm-block d-none">
            {/* HIDE CONTENT ONLY ON MOBILE RESOLUTION */}
            <Socials />
          </div>
        </div>
      </div>
    );
  };

  const Copyright = () => {
    return (
      <div className="mt-3 mt-sm-5">
        <nav className=" d-none d-md-flex pr-3 footer-foot">
          <div style={{ textDecoration: 'none' }}>
            {language === 'Indonesian' ? (
              <img src={idn} alt="flag" />
            ) : (
              <img src={en} alt="flag" />
            )}
            <span className="logo-span"> {language}</span>
            <span
              className=" ml-auto "
              style={{ paddingLeft: '150px', textAlign: 'right' }}
            >
              © 2020 PURWADHIKA DIGITAL TECHNOLOGY SCHOOL ALL RIGHTS RESERVED
              <span className="ml-3">
                OWNED BY PT PURWADHIKA KIRANA NUSANTARA
              </span>
            </span>
          </div>
        </nav>
      </div>
    );
  };

  return (
    <div
      style={{
        backgroundColor,
      }}
    >
      <div
        className={`${containerFooter} px-md-0`}
        style={{
          backgroundColor,
          fontSize: '14px',
        }}
      >
        {isOpen && (
          <ModalLecturer open={isOpen} toggle={() => setIsOpen(false)} />
        )}
        <div className={`${classNameFooterLine}`} />
        <div className="row mt-5">
          <Sitemap />
          <Address />
          <Contact />
          <Hours />
        </div>
        <Copyright />
      </div>
    </div>
  );
};

export default Footer;
