// below rule can be safely turned off thanks to immer used by redux toolkit
/* eslint-disable no-param-reassign */

// Reducers can directly reassign state, or return a new state, BUT NOT BOTH
// Detailed docs on state reassignment in reducers here https://redux-toolkit.js.org/api/createreducer/
// There are pitfalls to immer however, which you can read here https://immerjs.github.io/immer/docs/pitfalls

import { createSlice } from '@reduxjs/toolkit';
import firebase from 'firebase/app';
import 'firebase/firestore';

const devFeatureFlag = {
  showOnlineJC: true,
  showHomeBanner: true,
  showHomeFilter: true,
  studyMethodInFilter: true,
  enableIDCardValidationInCart: false,
  showHomeBlogPost: true,
  programsWithNewBanner: [],
  showPracticeSession: true,
};

const environment = process?.env?.REACT_APP_ENVIRONMENT ?? 'staging';

const { actions, reducer } = createSlice({
  name: 'featureFlag',
  initialState: devFeatureFlag,
  reducers: {
    setFeatureFlag: (state, action) => {
      const { payload } = action;
      return { ...state, ...payload };
    },
  },
});

export default reducer;

export const { setFeatureFlag } = actions;

export const fetchFeatureFlag = () => (dispatch) => {
  if (environment === 'development') {
    dispatch(setFeatureFlag(devFeatureFlag));
  }

  const firestore = firebase.firestore();
  firestore
    .collection('featureFlag')
    .doc(environment)
    .get()
    .then((featureFlag) => {
      if (featureFlag.exists) {
        dispatch(setFeatureFlag(featureFlag.data()));
      } else {
        dispatch(setFeatureFlag(devFeatureFlag));
        console.log(
          `Feature flags for "${environment}" environment does not exist. Falling back to devFeatureFlag`,
        );
      }
    })
    .catch((err) => {
      dispatch(setFeatureFlag(devFeatureFlag));
      console.log(err);
    });
};
