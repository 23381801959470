import React from 'react';
import 'assets/css/card.css';

const SidebarCard = ({ id, key, isSticky, children, className, style }) => {
  let stickyClass = '';
  if (isSticky) {
    stickyClass = 'sticky';
  }
  return (
    <div
      id={id}
      key={key}
      className={`pwdk-card pwdk-card-secondary ${stickyClass} ${className}`}
      style={{ width: '234px', ...style }}
    >
      {children}
    </div>
  );
};

export default SidebarCard;
