import React, { useEffect, useState } from 'react';
import moment from 'moment';

export default ({ isDiscount, endDateDiscount, refreshData }) => {
  /**
   * component countdown discount on modal preview and details
   * parameter:
   * isDiscount: is still discount or no,
   * endDateDiscount: endDateDiscount from data that has a discount
   * refreshData: function to refresh all new data after difftime < 0
   */
  const [days, setDays] = useState(`00`);
  const [hours, setHours] = useState(`00`);
  const [minutes, setMinutes] = useState(`00`);

  // set interval to 1 second
  const intervalCount = 1000;

  const printCountDiscount = () => {
    /**
     * function to set days, hour and minute from endDateDiscount - today
     */

    // today in miliseconds
    const today = moment().unix();

    const countEndDiscount = moment(
      endDateDiscount,
      'YYYY-MM-DD HH:mm:ss',
    ).unix();

    const diffTime = countEndDiscount - today;

    if (diffTime > 0) {
      const duration = moment.duration(diffTime * 1000, 'milliseconds');

      let daysDisc = parseInt(moment.duration(duration).asDays(), 10);
      let hoursDisc = moment.duration(duration).hours();
      let minutesDisc = moment.duration(duration).minutes();

      daysDisc = daysDisc > 9 ? daysDisc : `0${daysDisc}`;
      hoursDisc = hoursDisc > 9 ? hoursDisc : `0${hoursDisc}`;
      minutesDisc = minutesDisc > 9 ? minutesDisc : `0${minutesDisc}`;

      setDays(daysDisc);
      setHours(hoursDisc);
      setMinutes(minutesDisc);
    }

    if (diffTime < 0 && isDiscount) {
      refreshData();
    }
  };

  useEffect(() => {
    /**
     * function to run function printCountDiscount every intervalCount value, which is 1 second.
     */
    printCountDiscount();
    const interval = setInterval(() => {
      printCountDiscount();
    }, intervalCount);
    return () => clearInterval(interval);
  }, []);

  if (isDiscount) {
    return (
      <div className="d-flex flex-column">
        <div className="d-flex flex-row f--dark-gray-default">
          <span className="f--12 pwdk-mr-2 my-auto">Berakhir dalam</span>

          <div className=" d-flex flex-row my-auto">
            <div
              style={{
                background: '#f4743b',
                padding: '5px',
                color: '#ffffff',
                borderRadius: '6px',
                margin: '0 4px',
              }}
              className="f--12"
            >
              {days}
            </div>
            <div style={{ color: '#ef767a' }} className="f--12 my-auto">
              :
            </div>
            <div
              style={{
                background: '#f4743b',
                padding: '5px',
                color: '#ffffff',
                borderRadius: '6px',
                margin: '0 4px',
              }}
              className="f--12"
            >
              {hours}
            </div>
            <div style={{ color: '#ef767a' }} className="f--12 my-auto">
              :
            </div>
            <div
              style={{
                background: '#f4743b',
                padding: '5px',
                color: '#ffffff',
                borderRadius: '6px',
                margin: '0 0px 0px 4px',
              }}
              className="f--12"
            >
              {minutes}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return null;
};
