import React from 'react';
import 'assets/css/labels.css';

const PLabel = ({ id, key, children, color = 'neutral', className, style }) => {
  return (
    <div
      id={id}
      key={key}
      className={`pwdk-label pwdk-label-${color} ${className}`}
      style={{ ...style }}
    >
      {children}
    </div>
  );
};

export default PLabel;
