import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { API_URL_1 } from 'supports/api-url/apiurl';
import axios from 'supports/api';
import { PButton } from 'components';
import { signOut } from 'redux/modules/auth';

import ic_today from 'pages/dashboard-old/images/sidebar-icon/ic_today.svg';
import ic_description from 'pages/dashboard-old/images/sidebar-icon/ic_description.svg';
import ic_person_outline from 'pages/dashboard-old/images/sidebar-icon/person_outline.svg';
import ic_lock from 'pages/dashboard-old/images/sidebar-icon/ic_lock.svg';
import ic_logout from 'pages/dashboard-old/images/ic_exit_to_app.svg';
import ic_contact from 'pages/dashboard-old/images/ic_perm_phone_msg.svg';
import ProgramPageModal from 'components/Program/ProgramPage/ProgramPageModal';
import moment from 'moment';
import ic_profile from './image/ic_profile.svg';

export default ({ handleNonActiveDropdown }) => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { firstName, lastName, email, transaction } = auth.user;
  const [programModal, setProgramModal] = useState(false);
  const [form, setForm] = useState(0);
  const { t } = useTranslation('dashboard', {
    keyPrefix: 'dashboard.navbar',
  });

  const lang = localStorage.getItem('i18nextLng');
  moment.locale(lang);

  const resendEmailVerification = () => {
    axios
      .post(`/auth/resendemailconfirmation`, { lang })
      .then((res) => {
        window.alert('Email verifikasi telah dikirim');
      })
      .catch((err) => {
        console.log(err);
        window.alert('Terjadi kesalahan saat mengirim verifikasi ulang');
      });
  };
  return (
    <>
      <ProgramPageModal
        toggle={() => setProgramModal(false)}
        formIndex={form}
        open={programModal}
        // defaultProgramID={this.state.programId}
      />
      <div className=" pwdk-pt-4">
        <div className=" profile-nav-border-bottom">
          <Link
            to="/dashboard"
            style={{ textDecoration: 'none' }}
            onClick={handleNonActiveDropdown}
          >
            <div className="d-flex flex-row pwdk-pl-4 pwdk-pr-4 pb-3 hover-profile-area">
              <img
                src={
                  auth &&
                  auth.user.profilePicture === '/defaultprofilepicture.png'
                    ? ic_profile
                    : API_URL_1 + auth.user.profilePicture
                }
                alt="ic_profile"
                className="user_profile_navbar mr-3"
              />
              <div className="d-flex flex-column">
                <div className="f-h6">{`${firstName} ${lastName}`}</div>

                <div
                  className="d-flex flex-row align-items-center mt-1"
                  style={{ position: 'relative' }}
                >
                  <div
                    className="f-body-alt f--dark-gray"
                    style={{
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      width: '150px',
                    }}
                  >
                    {email}
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
        {!auth.accountVerified ? (
          <div className="verifyBox border-top">
            <div className="f-md-h6">{t('verification.title')}</div>
            <div className="f-body-alt f--dark-gray pwdk-mt-2">
              {t('verification.subtitle')}
            </div>
            <PButton
              className="btnNavVerify"
              style={{
                backgroundColor: '#ffa630',
                border: '1px solid #ffa630',
              }}
              onClick={() => resendEmailVerification()}
            >
              {t('verification.button_send')}
            </PButton>
          </div>
        ) : (
          <>
            <div className=" profile-nav-border-bottom">
              <div className=" pwdk-pl-4 pwdk-pr-4 py-2">
                <Link
                  to="/dashboard/transaksi"
                  style={{ textDecoration: 'none' }}
                  onClick={handleNonActiveDropdown}
                >
                  <div className="tab-profile-navbar py-2 mb-2">
                    <img
                      src={ic_description}
                      className="icon_navbar mr-3"
                      alt="transaksi_icon"
                    />
                    <span className="pt-1"> {t('dropdown.transaksi')}</span>
                    {transaction?.length > 0 ? (
                      <span
                        className="notification-circle ml-auto mt-1 mr-3"
                        style={{ marginLeft: '4px' }}
                      >
                        {transaction.length}
                      </span>
                    ) : null}
                  </div>
                </Link>
                <Link
                  to="/dashboard/program"
                  style={{ textDecoration: 'none' }}
                  onClick={handleNonActiveDropdown}
                >
                  <div className="tab-profile-navbar py-2">
                    <img
                      src={ic_today}
                      className="icon_navbar mr-3"
                      alt="program_icon"
                    />
                    <span className="pt-1">Program</span>
                  </div>
                </Link>
              </div>
            </div>
            <div className=" profile-nav-border-bottom">
              <div className=" pwdk-pl-4 pwdk-pr-4 py-2">
                <Link
                  to="/dashboard/profile"
                  style={{ textDecoration: 'none' }}
                  onClick={handleNonActiveDropdown}
                >
                  <div className="tab-profile-navbar py-2 mb-2">
                    <img
                      src={ic_person_outline}
                      className="icon_navbar mr-3"
                      alt="profile_icon"
                    />
                    <span className="pt-1">{t('dropdown.edit_profil')}</span>
                  </div>
                </Link>
                <Link
                  to="/dashboard/password"
                  style={{ textDecoration: 'none' }}
                  onClick={handleNonActiveDropdown}
                >
                  <div className="tab-profile-navbar py-2">
                    <img
                      src={ic_lock}
                      className="icon_navbar mr-3"
                      alt="password_icon"
                    />
                    <span className="pt-1">Password</span>
                  </div>
                </Link>
              </div>
            </div>
          </>
        )}

        <div className=" profile-nav-border-bottom">
          <div className=" pwdk-pl-4 pwdk-pr-4 py-2">
            <div
              className="tab-profile-navbar py-2"
              onClick={() => {
                setProgramModal(true);
                setForm('Hubungi Kami');
              }}
            >
              <img
                src={ic_contact}
                className="icon_navbar mr-3"
                alt="contact_icon"
              />
              <span className="pt-1">{t('verification.need_help')}</span>
            </div>
          </div>
        </div>
        <div>
          <div className=" pwdk-pl-4 pwdk-pr-4 py-2">
            <Link
              to="/"
              style={{ textDecoration: 'none' }}
              onClick={() => {
                handleNonActiveDropdown();
                dispatch(signOut());
              }}
            >
              <div className="tab-profile-navbar py-2 mb-2">
                <img
                  src={ic_logout}
                  className="icon_navbar mr-3"
                  alt="contact_icon"
                />
                <span className="pt-1">Logout</span>
              </div>
            </Link>
          </div>
        </div>

        {/* <div className="d-flex flex-row justify-content-between pwdk-mt-2">
          <Link
            to="/dashboard"
            style={{ textDecoration: 'none' }}
            onClick={handleNonActiveDropdown}
          >
            <PButton className="btnNavDashboard">
              <img
                src={ic_dashboard}
                alt="ic_dashboard"
                className="ic-dashboard"
              />
              Dashboard
            </PButton>
          </Link>
          <a
            href="/"
            onClick={() => {
              handleNonActiveDropdown();
              dispatch(signOut());
            }}
          >
            <PButton className="btnNavLogout">Keluar</PButton>
          </a>
        </div> */}
      </div>
    </>
  );
};
