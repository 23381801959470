// below rule can be safely turned off thanks to immer used by redux toolkit
/* eslint-disable no-param-reassign */

// Reducers can directly reassign state, or return a new state, BUT NOT BOTH
// Detailed docs on state reassignment in reducers here https://redux-toolkit.js.org/api/createreducer/
// There are pitfalls to immer however, which you can read here https://immerjs.github.io/immer/docs/pitfalls

import axios from 'supports/api';
import { createSlice } from '@reduxjs/toolkit';
import { encrypt } from 'supports/helpers/encryption';
import { app } from 'supports/config';
import moment from 'moment';
import Mixpanel from 'supports/helpers/mixpanel';

const initialUser = {
  id: null,
  firstName: null,
  lastName: null,
  email: '',
  phone: null,
  profilePicture: null,
  membership: {},
  attendees: [],
  token: null,
  comments: null,
  referral: null,
  transaction: [],
  identityCard: null,
  identityCardImg: null,
  npwp: null,
  npwpImg: null,
};

export const initialState = {
  user: initialUser,
  token: null,
  accountVerified: false,
  registerCheck: false,
  registerError: false,
  justRegistered: false,
  loggingIn: false,
  loginError: null,
  loggingOut: false,
  logoutError: null,
  tokenChecked: false,
  modalIsOpen: false,
};

const { actions, reducer } = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state) => {
      state.loggingIn = true;
      state.loginError = null;
    },
    loginSuccess: (state, action) => {
      state.loggingIn = false;
      state.user = { ...state.user, ...action.payload };
      state.accountVerified = action.payload.accountVerified;
    },
    loginFail: (state, action) => {
      state.loggingIn = false;
      state.loginError = action.payload || 'Login Failed';
      state.user = initialUser;
    },
    logout: (state) => {
      state.loggingOut = true;
    },
    logoutSuccess: (state) => {
      state.loggingOut = false;
      state.user = initialUser;
    },
    logoutFail: (state) => {
      state.loggingOut = false;
      state.logoutError = 'Insert payload error here';
    },
    register: (state) => {
      state.registerCheck = true;
      state.registerError = null;
    },
    registerFail: (state, action) => {
      state.registerCheck = false;
      state.registerError = action.payload || 'Login Failed';
      state.user = initialUser;
    },
    registerSuccess: (state, action) => {
      state.registerCheck = false;
      state.justRegistered = true;
      state.user = { ...state.user, ...action.payload };
    },
    tokenValid: (state, action) => {
      state.tokenChecked = true;
      state.user = { ...state.user, ...action.payload };
      state.accountVerified = action.payload.accountVerified;
    },
    tokenInvalid: (state) => {
      state.tokenChecked = true;
      state.user = initialUser;
    },
    toggleModal: (state) => {
      if (!state.user.email) {
        state.modalIsOpen = !state.modalIsOpen;
      } else {
        state.modalIsOpen = false;
      }
    },
    closeModal: (state) => {
      state.modalIsOpen = false;
    },
    setTransaction: (state, action) => {
      state.user.transaction = action.payload;
    },
  },
});

export default reducer;

export const {
  login,
  loginSuccess,
  loginFail,
  register,
  registerFail,
  registerSuccess,
  logout,
  logoutSuccess,
  logoutFail,
  tokenValid,
  tokenInvalid,
  toggleModal,
  closeModal,
  setTransaction,
} = actions;

export const signIn = (data) => async (dispatch) => {
  try {
    const { email, password, from, isRememberMe } = data;
    const ep = encrypt(app.key, password); // encrypted password

    dispatch(login());
    const res = await axios.post(`/auth/login`, {
      email,
      ep,
      from,
      isRememberMe,
    });
    const { token } = res.data.result;

    await localStorage.setItem('token', token);
    await dispatch(loginSuccess(res.data.result));

    Mixpanel.identify(res?.data?.result?.id);
    Mixpanel.people.set({});

    dispatch(closeModal());
  } catch (err) {
    console.log(err);
    const { message } = err.response?.data || err;
    dispatch(loginFail(message));
  }
};

export const signOut = () => (dispatch) => {
  try {
    dispatch(logout());
    localStorage.removeItem('token');
    Mixpanel.reset();
    dispatch(logoutSuccess());
  } catch (err) {
    console.log(err);
    dispatch(logoutFail());
  }
};

export const onRegister = (data) => {
  const ep = encrypt(app.key, data.password); // encrypted password
  data.ep = ep;
  data.lang = localStorage.getItem('i18nextLng');
  moment.locale(data.lang);

  return async (dispatch) => {
    dispatch(register());
    try {
      const user = await axios.post(`/v2/auth/register`, {
        ...data,
        cryptoJs: false,
      });
      const {
        id,
        email,
        firstName,
        lastName,
        phone,
        profilePicture,
        membership,
        token,
      } = user.data.data;
      localStorage.setItem('token', token || '');
      Mixpanel.identify(id);
      Mixpanel.people.set({});
      dispatch(
        registerSuccess({
          id,
          email,
          firstName,
          lastName,
          phone,
          profilePicture,
          membership,
          token,
        }),
      );
    } catch (err) {
      console.log(err?.response?.data?.message);
      dispatch(registerFail(err?.response?.data?.message));
    }
  };
};

export const checkToken = () => async (dispatch) => {
  try {
    const res = await axios.get(`/auth/userinfo`);
    if (res?.data?.result?.token) {
      localStorage.setItem('token', res.data.result.token);
    }
    dispatch(tokenValid(res.data.result));
  } catch (err) {
    console.log(err);
    console.log(err?.response?.data?.message);
    dispatch(tokenInvalid());
  }
};

export const checkTransaction = () => async (dispatch) => {
  try {
    const res = await axios.get(`/transaction/bills`);
    dispatch(setTransaction(res.data.result));
  } catch (err) {
    console.log(err);
    console.log(err?.response?.data?.message);
    dispatch(tokenInvalid());
  }
};
