import React from 'react';
import 'assets/css/buttons.css';

const PButton = ({
  id,
  key,
  children,
  size = 'medium',
  color = 'primary',
  disabled = false,
  className,
  style,
  pill = false,
  onClick = () => {},
}) => {
  let cssDisabled = '';
  let cssShape = 'rectangle';
  if (disabled) {
    cssDisabled = 'pwdk-btn-disabled';
  }

  if (pill) {
    cssShape = 'pill';
  }

  const onClickWrapper = () => {
    if (!disabled) {
      onClick();
    }
  };

  return (
    <div
      id={id}
      key={key}
      className={`pwdk-btn pwdk-btn-${cssShape} pwdk-btn-${size} pwdk-btn-${color} ${cssDisabled} ${className}`}
      style={{ ...style }}
      onClick={onClickWrapper}
      onKeyDown={(e) => {
        if (e.key === 'Enter' && !disabled) {
          e.preventDefault();
          e.target.click();
        }
      }}
      role="button"
      tabIndex={0}
    >
      {children}
    </div>
  );
};

export default PButton;
