import qs from 'querystring';

export const FullTime = [
  {
    item: 'Web & Mobile Development',
    slug: '/job-connector-web-mobile-development',
  },
  {
    item: 'Digital Marketing',
    slug: '/job-connector-digital-marketing',
  },
  {
    item: 'Data Science & Machine Learning',
    slug: '/job-connector-data-science',
  },
  {
    item: 'UI/UX Design',
    slug: '/job-connector-ui-ux-design',
  },
];

export const JobConnector = [
  {
    item: 'Full Stack Web Development',
    slug: '/job-connector-full-stack-web-development',
  },
  {
    item: 'Data Science & Machine Learning',
    slug: '/job-connector-data-science',
  },
  {
    item: 'Digital Marketing',
    slug: '/job-connector-digital-marketing',
  },
  {
    item: 'UI/UX Design',
    slug: '/job-connector-ui-ux-design',
  },
];

export const AfterHour = [
  {
    item: 'Web & Mobile Development',
    slug: '/job-connector-ah-web-mobile-development',
  },
  {
    item: 'Digital Marketing',
    slug: '/job-connector-ah-digital-marketing',
  },
  {
    item: 'Data Science & Machine Learning',
    slug: '/job-connector-ah-data-science',
  },
  {
    item: 'UI/UX Design',
    slug: '/job-connector-ah-ui-ux-design',
  },
  {
    item: 'Android Native Development',
    slug: '/job-connector-ah-android-native-development',
  },
];

export const PartTime = [
  {
    item: 'Web & Mobile Development',
    slug: '/skill-accelerator-web-mobile-development',
  },
  {
    item: 'Digital Marketing',
    slug: '/skill-accelerator-digital-marketing',
  },
  {
    item: 'UX Design',
    slug: '/skill-accelerator-ux-design',
  },
  {
    item: 'Data Science',
    slug: '/skill-accelerator-data-science',
  },
];

export const FullTimeRemote = [
  {
    item: 'Web & Mobile Development',
    slug: '/job-connector-web-mobile-development',
  },
  {
    item: 'Digital Marketing',
    slug: '/job-connector-digital-marketing',
  },
  {
    item: 'Data Science & Machine Learning',
    slug: '/job-connector-data-science',
  },
  {
    item: 'UI/UX Design',
    slug: '/job-connector-ui-ux-design',
  },
];

export const AfterHourRemote = [
  {
    item: 'Web & Mobile Development',
    slug: '/job-connector-ah-web-mobile-development',
  },
  {
    item: 'Digital Marketing',
    slug: '/job-connector-ah-digital-marketing',
  },
  {
    item: 'Data Science & Machine Learning',
    slug: '/job-connector-ah-data-science',
  },
  {
    item: 'UI/UX Design',
    slug: '/job-connector-ah-ui-ux-design',
  },
];

export const PartTimeRemote = [
  {
    item: 'Web & Mobile Development',
    slug: '/skill-accelerator-web-mobile-development',
  },
  {
    item: 'Digital Marketing',
    slug: '/skill-accelerator-digital-marketing',
  },
  {
    item: 'UX Design',
    slug: '/skill-accelerator-ux-design',
  },
  {
    item: 'Data Science',
    slug: '/skill-accelerator-data-science',
  },
];

export const FulltimeOnline = [
  {
    item: 'Data Science & Machine Learning',
    slug: '/job-connector-data-science',
  },
  {
    item: 'Digital Marketing',
    slug: '/job-connector-digital-marketing',
  },
  {
    item: 'UI/UX Design',
    slug: '/job-connector-ui-ux-design',
  },
  {
    item: 'Web & Mobile Development',
    slug: '/job-connector-web-mobile-development',
  },
];

// export const ExecutiveProgram = {
//   dataScience: [
//     {
//       item: 'Data Science for Leaders',
//       slug: '/executive-program/data-science/data-science-for-leaders',
//     },
//     {
//       item: 'Data Analysis',
//       slug: '/executive-program/data-science/data-analysis',
//     },
//     {
//       item: 'Machine Learning Engineering',
//       slug: '/executive-program/data-science/machine-learning-engineering',
//     },
//   ],
//   digitalMarketing: [
//     {
//       item: 'Digital Marketing for Professional',
//       slug:
//         '/executive-program/digital-marketing/digital-marketing-for-professional',
//     },
//     {
//       item: 'Performance Marketing',
//       slug: '/executive-program/digital-marketing/performance-marketing',
//     },
//     {
//       item: 'SEO Specialist',
//       slug: '/executive-program/digital-marketing/seo-specialist',
//     },
//     {
//       item: 'Social Media Specialist',
//       slug: '/executive-program/digital-marketing/social-media-specialist',
//     },
//     {
//       item: 'Strategic Digital Marketing Program for Leader',
//       slug: '/executive-program/digital-marketing/seo-specialist',
//     },
//   ],
//   uiuxDesign: [
//     {
//       item: 'User Research',
//       slug: '/executive-program/uiux-design/user-research',
//     },
//     {
//       item: 'Mobile Design Principles',
//       slug: '/executive-program/uiux-design/mobile-design-principles',
//     },
//     {
//       item: 'Design System',
//       slug: '/executive-program/uiux-design/design-system',
//     },
//   ],
//   softwareDevelopment: [
//     {
//       item: 'DevOps Engineering',
//       slug: '/executive-program/software-development/devops-engineering',
//     },
//   ],
// };

// new executive program
export const ExecutiveProgram = [
  {
    item: 'Digital Marketing 360',
    slug: '/skill-accelerator/digital-marketing-360',
  },
  {
    item: 'Digital Marketing Ads',
    slug: '/skill-accelerator/digital-marketing-ads',
  },
  {
    item: 'Strategic Digital Marketing for Leaders',
    slug: '/skill-accelerator/strategic-digital-marketing-for-leader',
  },
  // {
  //   item: 'Social Media Listening for Business',
  //   slug: '/skill-accelerator/social-media-listening-for-business',
  //   isNew: true,
  // },
  // {
  //   item: 'Digital Marketing for B2B',
  //   slug: '/skill-accelerator/digital-marketing-for-b2b',
  //   isNew: true,
  // },
  // {
  //   item: 'Data Science for Leaders',
  //   slug: '/skill-accelerator/data-science-for-leaders',
  // },
  // {
  //   item: 'Data Analytics for Business & Marketing',
  //   slug: '/skill-accelerator/data-analytics-for-business-and-marketing',
  //   isNew: true,
  // },
  {
    item: 'Data Analytics',
    slug: '/skill-accelerator/data-analytics',
  },
  // {
  //   item: 'Machine Learning Engineering',
  //   slug: '/skill-accelerator/machine-learning-engineering',
  // },
  // {
  //   item: 'DevOps Engineering',
  //   slug: '/skill-accelerator/devops-engineering',
  // },
  // {
  //   item: 'Digital Visual Design',
  //   slug: '/skill-accelerator/digital-visual-design',
  // },
  // {
  //   item: 'NFT Blockchain Development',
  //   slug: '/skill-accelerator/nft-blockchain-development',
  // },
];

export const JobAccelerator = [
  {
    item: 'Advanced Performance Marketing',
    slug: '/executive-program/digital-marketing/advanced-performance-marketing',
  },
  // {
  //   item: 'Advanced SEO',
  //   slug: '/executive-program/digital-marketing/advanced-seo',
  // },
  // {
  //   item: 'Advanced Social Media',
  //   slug: '/executive-program/digital-marketing/advanced-social-media',
  // },
  // {
  //   item: 'User Research',
  //   slug: '/executive-program/uiux-design/user-research',
  // },
  {
    item: 'Machine Learning Engineering',
    slug: '/executive-program/data-science/machine-learning-engineering',
  },
];

export const FullTimeNew = {
  jobConnector: [
    {
      item: 'Full Stack Web Development',
      slug: '/job-connector-full-stack-web-development',
    },
    {
      item: 'Data Science & Machine Learning',
      slug: '/job-connector-data-science',
    },
    {
      item: 'Digital Marketing',
      slug: '/job-connector-digital-marketing',
    },
    {
      item: 'UI/UX Design',
      slug: '/job-connector-ui-ux-design',
    },
    {
      item: 'Product Management',
      slug: '/job-connector-product-management',
      isNew: true,
    },
  ],

  jobConnectorInternational: [
    {
      item: 'Full Stack Web Development',
      slug: '/international/full-stack-web-development-bootcamp',
    },
    {
      item: 'Data Science & Machine Learning',
      slug: '/international/data-science-machine-learning-bootcamp',
    },
    {
      item: 'Digital Marketing',
      slug: '/international/digital-marketing-bootcamp',
    },
    {
      item: 'UI/UX Design',
      slug: '/international/ui-ux-design-bootcamp',
    },
  ],
};

export const SkillAcceleratorNew = {
  data: [
    {
      item: 'Data Analytics',
      slug: '/skill-accelerator/data-analytics',
    },
    // {
    //   item: 'Data Analytics for Business & Marketing',
    //   slug: '/skill-accelerator/data-analytics-for-business-and-marketing',
    //   isNew: true,
    // },
  ],

  digitalMarketing: [
    {
      item: 'Digital Marketing 360',
      slug: '/skill-accelerator/digital-marketing-360',
    },
    {
      item: 'Digital Marketing Ads',
      slug: '/skill-accelerator/digital-marketing-ads',
    },
    {
      item: 'Strategic Digital Marketing for Leaders',
      slug: '/skill-accelerator/strategic-digital-marketing-for-leader',
    },
    {
      item: 'Social Media Organic',
      slug: '/skill-accelerator/social-media-organic',
    },
    {
      item: 'Search Engine Optimization (SEO)',
      slug: '/skill-accelerator/search-engine-optimization-(seo)',
    },
    // {
    //   item: 'Social Media Listening for Business',
    //   slug: '/skill-accelerator/social-media-listening-for-business',
    //   isNew: true,
    // },
    // {
    //   item: 'Digital Marketing for B2B',
    //   slug: '/skill-accelerator/digital-marketing-for-b2b',
    //   isNew: true,
    // },
  ],

  blockhain: [
    {
      item: 'NFT Blockchain Development',
      slug: '/skill-accelerator/nft-blockchain-development',
    },
  ],
  uiuxDesign: [
    {
      item: 'UI/UX Design',
      slug: '/skill-accelerator/ui-ux-design',
    },
  ],
};

// new event & workshop
export const EventAndWorkshop = [
  //   {
  //   item: 'Livestream',
  //   slug: `/courses/v2?${qs.stringify({
  //     program: 7,
  //     metode: 2,
  //     topic: 0,
  //     level: 0,
  //     durationId: '',
  //     priceId: 0,
  //     page: 1,
  //   })}`,
  // },
  {
    item: 'Event',
    slug: `/courses?${qs.stringify({
      topic: 0,
      format: 16,
      location: 0,
    })}`,
  },
  {
    item: 'Workshop (On Campus & Livestream)',
    slug: `/courses?${qs.stringify({
      topic: 0,
      format: 4,
      location: 0,
    })}`,
  },
  {
    item: 'Workshop (Video Learning)',
    slug: `/courses/v2?${qs.stringify({
      program: 7,
      metode: 1,
      topic: 0,
      level: 0,
      durationId: '',
      priceId: 0,
      page: 1,
    })}`,
  },
];

export const PurwadhikaCollege = {
  Bachelor: [
    {
      item: 'Degree From UK',
      slug: `/uk`,
    },
    {
      item: 'Degree From Germany',
      slug: `/germany`,
    },
  ],
  Master: [
    {
      item: `Degree From Germany`,
      slug: `/germany`,
    },
  ],
  MBA: [
    {
      item: `Degree From Germany`,
      slug: `/germany`,
    },
  ],
};

export const WorkshopsOnline = [
  {
    item: 'Web & Mobile Development',
    slug: `/courses/v2?${qs.stringify({
      program: 7,
      metode: 1,
      topic: 1,
      level: 0,
      durationId: '',
      priceId: 0,
      page: 1,
    })}`,
  },
  {
    item: 'Data Science & Machine Learning',
    slug: `/courses/v2?${qs.stringify({
      program: 7,
      metode: 1,
      topic: 2,
      level: 0,
      durationId: '',
      priceId: 0,
      page: 1,
    })}`,
  },
  {
    item: 'Digital Marketing',
    slug: `/courses/v2?${qs.stringify({
      program: 7,
      metode: 1,
      topic: 3,
      level: 0,
      durationId: '',
      priceId: 0,
      page: 1,
    })}`,
  },
  {
    item: 'UI/UX Design',
    slug: `/courses/v2?${qs.stringify({
      program: 7,
      metode: 1,
      topic: 4,
      level: 0,
      durationId: '',
      priceId: 0,
      page: 1,
    })}`,
  },
  {
    item: 'DevOps Engineering',
    slug: `/courses/v2?${qs.stringify({
      program: 7,
      metode: 1,
      topic: 5,
      level: 0,
      durationId: '',
      priceId: 0,
      page: 1,
    })}`,
  },
  {
    item: 'Business',
    slug: `/courses/v2?${qs.stringify({
      program: 7,
      metode: 1,
      topic: 6,
      level: 0,
      durationId: '',
      priceId: 0,
      page: 1,
    })}`,
  },
  {
    item: 'Kids & Teens',
    slug: `/courses/v2?${qs.stringify({
      program: 7,
      metode: 1,
      topic: 7,
      level: 0,
      durationId: '',
      priceId: 0,
      page: 1,
    })}`,
  },
  {
    item: 'Browse All Workshops',
    slug: `/courses/v2?${qs.stringify({
      program: 7,
      metode: 1,
      topic: 0,
      level: 0,
      durationId: '',
      priceId: 0,
      page: 1,
    })}`,
  },
];
