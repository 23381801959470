import { configureStore } from '@reduxjs/toolkit';
import reducer from './modules/reducer';
// import createMiddleware from './middleware/clientMiddleware';

export default () => {
  // if no middleware is set, rtk sets up default middleware such as thunks
  const store = configureStore({
    reducer,
    devTools: true,
  });

  return store;
};
