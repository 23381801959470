import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  examTestData: {},
  countUserAnswered: 0,
  questionNumber: 0,
};

const { actions, reducer } = createSlice({
  name: 'exam',
  initialState,
  reducers: {
    dispatchExamTestData: (state, action) => ({
      ...state,
      examTestData: action.payload,
    }),
    assignCountUserAnswered: (state, action) => ({
      ...state,
      countUserAnswered: action.payload,
    }),
    assignQuestionNumber: (state, action) => ({
      ...state,
      // + 1 because payload is index
      questionNumber: action.payload + 1,
    }),
  },
});

export default reducer;

export const {
  dispatchExamTestData,
  assignCountUserAnswered,
  assignQuestionNumber,
} = actions;
