import React from 'react';
import 'assets/css/video.css';

export default ({ src, title, ...props }) => {
  return (
    <div className="pwdk-video-container">
      <iframe
        title={title}
        src={src}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        className="pwdk-video"
        {...props}
      />
    </div>
  );
};
