import { library } from '@fortawesome/fontawesome-svg-core';
import { faCalendar as farCalendar } from '@fortawesome/free-regular-svg-icons';
import {
  fab,
  faInstagram,
  faFacebook,
  faLinkedin,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import {
  faCalendar,
  faSearch,
  faFileInvoice,
  faMapMarkerAlt,
  faChevronDown,
  faTimesCircle,
  faChevronUp,
  faChevronLeft,
  faChevronRight,
  faCircle,
  faArrowLeft,
  faArrowRight,
  faArrowDown,
  faPlus,
  faMinus,
  faTimes,
  faUser,
  faCheck,
  faLock,
  faCheckCircle,
  faCaretLeft,
  faCaretRight,
  faInfoCircle,
  faFileDownload,
  faRedo,
  faImage,
  faPlayCircle,
  faFileAlt,
} from '@fortawesome/free-solid-svg-icons';

export default library.add(
  fab,
  faFileInvoice,
  faSearch,
  faCalendar,
  farCalendar,
  faMapMarkerAlt,
  faCheckCircle,
  faTimesCircle,
  faChevronDown,
  faChevronUp,
  faChevronLeft,
  faChevronRight,
  faCircle,
  faArrowLeft,
  faArrowRight,
  faArrowDown,
  faPlus,
  faMinus,
  faTimes,
  faUser,
  faInstagram,
  faFacebook,
  faLinkedin,
  faYoutube,
  faCheck,
  faLock,
  faCaretLeft,
  faCaretRight,
  faInfoCircle,
  faFileDownload,
  faRedo,
  faImage,
  faPlayCircle,
  faFileAlt,
);
