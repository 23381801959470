import React from 'react';
import Select from 'react-select';
import theme from 'utils/theme';
import chevrondown from 'assets/images/svg/form/chevrondown.svg';
import { Alert } from 'reactstrap';

// ------------------------------------------------------------------- styles

const customStyles = {
  control: () => ({
    color: '#6e6e6e',
    display: 'flex',
    padding: '10px 6px',
    border: '1px solid #b3b3b3',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '18px',
    borderRadius: '6px',
    ':focus': {
      border: `1px solid ${theme.palette.grey[3]}`,
      transition: 'border 0.15s ease-in-out',
    },
  }),
  placeholder: (base) => ({
    ...base,
    color: '#b3b3b3',
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: 'none',
  }),
  dropdownIndicator: (base) => ({
    ...base,
    marginRight: '.5rem',
    backgroundImage: `url${chevrondown}`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
  }),
  menu: () => ({
    border: `.5px solid ${theme.palette.grey[3]}`,
    background: `${theme.palette.common.white}`,
    borderRadius: '8px',
  }),
  option: () => ({
    color: '#6e6e6e',
    padding: '2px 17px',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '18px',
    marginTop: 0,
    '&:hover': {
      color: `${theme.palette.common.white}`,
      cursor: 'pointer',
      background: `${theme.palette.blue[4]}`,
    },
  }),
};

// ------------------------------------------------------------------- components

const PAutocomplete = ({
  id,
  value,
  onBlur,
  onChange,
  valid = true,
  invalidMessage,
  invalidColor = 'red',
  placeholder,
  options,
  onInputChange,
  filterOption,
  isLoading,
  noOptionsMessage,
  className,
}) => {
  const RenderInvalidMessage = () => {
    if (!valid) {
      return (
        <Alert
          className={`pwdk-label pwdk-label-${invalidColor} mt-2 mb-0 w-100`}
          style={{ border: 'none' }}
        >
          {invalidMessage}
        </Alert>
      );
    }

    return null;
  };

  return (
    <>
      <Select
        id={id}
        value={value}
        styles={customStyles}
        onBlur={onBlur}
        onChange={onChange}
        onInputChange={onInputChange}
        placeholder={placeholder}
        options={options}
        isSearchable
        filterOption={filterOption} // disable the default option filter
        isLoading={isLoading}
        noOptionsMessage={noOptionsMessage}
        className={className}
      />
      <RenderInvalidMessage />
    </>
  );
};

export default PAutocomplete;
