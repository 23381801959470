import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import theme from 'utils/theme';
import { NewLocation } from './ProgramNew';

const LocationItemTitle = styled.div`
  font-weight: 900;
  font-size: 11px;
  line-height: 14px;
  color: #bdbdbd;
  margin-bottom: 1rem;
  text-transform: uppercase;
  ${theme.breakpoints.down('lg')} {
    font-weight: 900;
    font-size: 12px;
    line-height: 15px;
    color: #505050;
    text-transform: none;
  }
`;
const LocationItemList = styled.div`
  font-weight: 450;
  font-size: 14px;
  line-height: 18px;
  color: #4f4f4f;
  transition: all 0.3s;
  &:hover {
    color: #32b280;
  }
  ${theme.breakpoints.down('lg')} {
    font-size: 14px;
    line-height: 18px;
  }
`;
const NavBox = styled.div``;

// const NewLabel = styled.span`
//   color: ${theme.palette.green.primary};
//   font-weight: 900;
//   font-size: 10px;
//   background-color: ${theme.palette.primary.lighter};
//   padding: 4px;
//   margin-left: 4px;
// `;

export default ({ handleNonActiveDropdown }) => {
  return (
    <div className="pwdk-pl-4 pwdk-pr-4 pwdk-pt-4 pwdk-pb-4">
      <NavBox>
        <LocationItemTitle>Online Method</LocationItemTitle>
        <Link
          to="/location/online"
          style={{ textDecoration: 'none' }}
          onClick={handleNonActiveDropdown}
        >
          <LocationItemList>Online Learning</LocationItemList>
        </Link>
      </NavBox>
      <NavBox style={{ marginTop: '30px' }}>
        <LocationItemTitle>On Campus Method</LocationItemTitle>
        <Link
          to="/location/bsd"
          style={{ textDecoration: 'none' }}
          onClick={handleNonActiveDropdown}
        >
          <LocationItemList>BSD</LocationItemList>
        </Link>
        <Link
          to="/location/jakarta"
          style={{ textDecoration: 'none' }}
          onClick={handleNonActiveDropdown}
        >
          <LocationItemList style={{ marginTop: '10px' }}>
            Jakarta
          </LocationItemList>
        </Link>
        <Link
          to="/location/batam"
          style={{ textDecoration: 'none' }}
          onClick={handleNonActiveDropdown}
        >
          <LocationItemList style={{ marginTop: '10px' }}>
            Batam
          </LocationItemList>
        </Link>
        <Link
          to="/location/bandung"
          style={{ textDecoration: 'none' }}
          onClick={handleNonActiveDropdown}
        >
          <LocationItemList style={{ marginTop: '10px' }}>
            Bandung <NewLocation>New Location</NewLocation>
          </LocationItemList>
        </Link>
        <Link
          to="/location/jogja"
          style={{ textDecoration: 'none' }}
          onClick={handleNonActiveDropdown}
        >
          <LocationItemList style={{ marginTop: '10px' }}>
            Jogja <NewLocation>New Location</NewLocation>
          </LocationItemList>
        </Link>
      </NavBox>
    </div>
  );
};
