import React from 'react';
import { useHistory, Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toggleModal } from 'redux/modules/auth';
import { Progress } from 'reactstrap';

import { useTranslation } from 'react-i18next';
import NavItem from './NavItem';
import PButton from '../PButton';
import International from './InternationalPrograms';
import About from './About';
import Cart from './Cart';
import Profile from './Profile';
import ProfileDashboard from './ProfileDashboard';
// import VideoLearning from './VideoLearning';
import ForCorporate from './ForCorporate';
import Alumni from './Alumni';
import Locations from './Locations';

import Logo from './image/logo.svg';
import ProgramNew from './ProgramNew';

export default ({
  dropdownActive,
  setDropdownActive,
  activeMenu,
  handleActiveMenu,
  handleNonActiveDropdown,
}) => {
  const auth = useSelector((state) => state.auth);
  const quiz = useSelector((state) => state.quiz);
  const studentLearning = useSelector((state) => state.studentLearning);
  const { examTestData, questionNumber } = useSelector((state) => state.exam);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const selectedLanguage = localStorage.getItem('i18nextLng');

  const { t } = useTranslation('dashboard', { keyPrefix: 'dashboard.navbar' });

  const toggleSignIn = () => {
    dispatch(toggleModal());
  };

  // const isExecutivePath = location.pathname.includes('/executive-');
  const isCorporatePath = location.pathname.includes('/corporate-');
  const isDashboardPath = location.pathname.includes('/dashboard');
  const isVPath = location.pathname.includes('/v/');
  const isExamPath =
    location.pathname.includes('/exam/') ||
    location.pathname.includes('/exam-result/');
  const isWorkshopPath = location.pathname.includes(
    '/student-learning/workshop',
  );
  const isVideoLearningPath = location.pathname.includes(
    '/student-learning/video',
  );
  const isLivestreamPath = location.pathname.includes(
    '/student-learning/livestream',
  );
  const isPracticePath = location.pathname.includes(
    '/student-learning/practice',
  );

  const renderNavbarTitle = () => {
    // if (isExecutivePath) {
    //   return (
    //     <Link
    //       className="executive-logo-nav"
    //       to="/"
    //       style={{ textDecoration: 'none' }}
    //     >
    //       <div />
    //     </Link>
    //   );
    // }

    if (isCorporatePath) {
      return (
        <Link
          className="corporate-logo-nav"
          to="/"
          style={{ textDecoration: 'none' }}
        >
          <div />
        </Link>
      );
    }

    if (
      isVideoLearningPath ||
      isLivestreamPath ||
      isWorkshopPath ||
      isPracticePath
    ) {
      const { programId, title } = studentLearning.selectedCourse || {};

      let defaultPath = '/dashboard/program';

      if (isVideoLearningPath || isPracticePath) {
        defaultPath = `/dashboard/program/video-learning/${programId}`;
      }

      if (isLivestreamPath) {
        defaultPath = `/dashboard/program/livestream/${programId}`;
      }

      return (
        <Link
          to={defaultPath}
          style={{ textDecoration: 'none', zIndex: '1003' }}
        >
          <div className="navbarDashboard border-left">
            <img
              src={Logo}
              alt="logo"
              className="logo-nav indexMenu"
              style={{ marginRight: '24px' }}
            />
            <div
              style={{ height: '100%', width: '1px' }}
              className="border-right"
            />
          </div>
          <div
            className="f-md-body-default logoNameDashboard"
            style={{ color: '#6e6e6e' }}
          >
            {title}
          </div>
        </Link>
      );
    }

    if (isDashboardPath || isVPath) {
      return (
        <Link to="/" style={{ textDecoration: 'none', zIndex: '1003' }}>
          <div className="navbarDashboard border-right border-left">
            <img src={Logo} alt="logo" className="logo-nav indexMenu" />
          </div>
          <div className="logoNameDashboard ">
            Purwadhika Digital Technology School
          </div>
        </Link>
      );
    }

    if (isExamPath) {
      const { title } = examTestData;
      return (
        <div>
          <div className="navbarDashboard border-right border-left">
            <img src={Logo} alt="logo" className="logo-nav indexMenu" />
          </div>
          <div className="logoNameDashboard ">Exam - {title}</div>
        </div>
      );
    }

    return (
      <Link to="/" style={{ textDecoration: 'none' }}>
        <div className="nav-brand">
          <img src={Logo} alt="logo" className="logo-nav indexMenu" />
          <span className="separator" />
          <span className="d-none d-md-inline logo-name">
            Digital Technology School
          </span>
        </div>
      </Link>
    );
  };

  const renderNavbarContent = () => {
    const showWatchPageHeader =
      isVideoLearningPath ||
      isWorkshopPath ||
      isLivestreamPath ||
      isVPath ||
      isExamPath ||
      isPracticePath;
    const showProgress =
      isVideoLearningPath || isWorkshopPath || isVPath || isExamPath;

    let userProgress = 0;

    if (isVPath) {
      userProgress = Math.round(
        (quiz.userProgressionVideo.length / quiz.totalProgress.length) * 100,
      );
    } else if (isExamPath) {
      const { totalProblems } = examTestData;
      userProgress = Math.round((Number(questionNumber) / totalProblems) * 100);
    } else {
      userProgress = studentLearning.userProgress;
    }

    if (showWatchPageHeader) {
      return (
        <div className="d-flex flex-row class-content">
          <div className="class-progress p-3" style={{ width: '85%' }}>
            {showProgress && (
              <>
                <div className="d-flex flex-row">
                  <div className="f-md-body-small f--dark-gray font-weight-bold">
                    {isExamPath ? t('progres_ujian') : t('progres_kelas')}
                  </div>
                  <div className="ml-auto f-md-body-small f--light-gray">
                    {userProgress}/100
                  </div>
                </div>
                <div className="mt-1">
                  <Progress
                    value={userProgress}
                    style={{ height: '2px', width: '100%' }}
                  />
                </div>
              </>
            )}
          </div>
          <div style={{ width: '15%', marginTop: '-2px' }}>
            <NavItem
              name="onlineCourses"
              auth={auth}
              setDropdownActive={setDropdownActive}
              handleActiveMenu={handleActiveMenu}
              activeMenu={activeMenu}
              dropdownActive={dropdownActive}
              content={
                isDashboardPath && auth.user.email ? (
                  <ProfileDashboard
                    handleNonActiveDropdown={handleNonActiveDropdown}
                  />
                ) : (
                  <Profile handleNonActiveDropdown={handleNonActiveDropdown} />
                )
              }
              className={`ProfileNav ${
                isDashboardPath && auth.user.email ? 'ProfileNavDashboard' : ''
              }${
                isWorkshopPath ||
                isVideoLearningPath ||
                isLivestreamPath ||
                isVPath ||
                isPracticePath
                  ? 'ProfileNavCourses'
                  : ''
              }`}
            />
          </div>
        </div>
      );
    }

    return (
      <>
        <li>
          <NavItem
            name="Programs"
            setDropdownActive={setDropdownActive}
            handleActiveMenu={handleActiveMenu}
            activeMenu={activeMenu}
            dropdownActive={dropdownActive}
            content={
              <ProgramNew handleNonActiveDropdown={handleNonActiveDropdown} />
            }
            className="programsNav"
            isFullWidth
          />
        </li>
        {/* <li>
          <NavItem
            name="International Programs"
            setDropdownActive={setDropdownActive}
            handleActiveMenu={handleActiveMenu}
            activeMenu={activeMenu}
            dropdownActive={dropdownActive}
            content={
              <International
                handleNonActiveDropdown={handleNonActiveDropdown}
              />
            }
            className="InternationalNav"
          />
        </li> */}
        <li>
          <NavItem
            name="Locations"
            setDropdownActive={setDropdownActive}
            handleActiveMenu={handleActiveMenu}
            activeMenu={activeMenu}
            dropdownActive={dropdownActive}
            content={
              <Locations handleNonActiveDropdown={handleNonActiveDropdown} />
            }
            className={
              selectedLanguage === 'en' ? 'locationNavEng' : 'locationsNavIdn'
            }
          />
        </li>
        <li>
          <NavItem
            name="For Corporate"
            setDropdownActive={setDropdownActive}
            handleActiveMenu={handleActiveMenu}
            activeMenu={activeMenu}
            dropdownActive={dropdownActive}
            content={
              <ForCorporate handleNonActiveDropdown={handleNonActiveDropdown} />
            }
            className="corporateNav"
          />
        </li>
        <li>
          <NavItem
            name="Alumni"
            setDropdownActive={setDropdownActive}
            handleActiveMenu={handleActiveMenu}
            activeMenu={activeMenu}
            dropdownActive={dropdownActive}
            content={
              <Alumni handleNonActiveDropdown={handleNonActiveDropdown} />
            }
            className="alumniNav"
          />
        </li>
        {/* <li>
          <NavItem
            name="Remote Learning"
            setDropdownActive={setDropdownActive}
            handleActiveMenu={handleActiveMenu}
            activeMenu={activeMenu}
            dropdownActive={dropdownActive}
            content={
              <Programs
                handleNonActiveDropdown={handleNonActiveDropdown}
                name="RemoteLearning"
              />
            }
            className="programsNav"
          />
        </li> */}
        {/* <li>
          <NavItem
            name="Students"
            setDropdownActive={setDropdownActive}
            handleActiveMenu={handleActiveMenu}
            activeMenu={activeMenu}
            dropdownActive={dropdownActive}
            content={
              <Students handleNonActiveDropdown={handleNonActiveDropdown} />
            }
            className="studentsNav"
          />
        </li> */}
        {/* <li>
          <NavItem
            name="Video Learning"
            setDropdownActive={setDropdownActive}
            handleActiveMenu={handleActiveMenu}
            activeMenu={activeMenu}
            dropdownActive={dropdownActive}
            content={
              <VideoLearning
                handleNonActiveDropdown={handleNonActiveDropdown}
              />
            }
            className="videoLearningNav"
          />
        </li> */}
        <li>
          <NavItem
            name="About"
            setDropdownActive={setDropdownActive}
            handleActiveMenu={handleActiveMenu}
            activeMenu={activeMenu}
            dropdownActive={dropdownActive}
            content={
              <About handleNonActiveDropdown={handleNonActiveDropdown} />
            }
            className="aboutNav"
          />
        </li>
      </>
    );
  };

  const renderNavbarUserProfile = () => {
    if (
      isWorkshopPath ||
      isVideoLearningPath ||
      isLivestreamPath ||
      isVPath ||
      isExamPath ||
      isPracticePath
    ) {
      return <div />;
    }

    if (!auth.user.email) {
      return (
        <>
          {/* cart */}
          <div>
            <NavItem
              name="CartNav"
              setDropdownActive={setDropdownActive}
              handleActiveMenu={handleActiveMenu}
              activeMenu={activeMenu}
              dropdownActive={dropdownActive}
              content={
                <Cart handleNonActiveDropdown={handleNonActiveDropdown} />
              }
              className="CartNav"
            />
          </div>
          {/* <span className="separatornav" /> */}
          <div className="nav-secondary indexMenu">
            <div
              className="btn-login-nav mr-2"
              onClick={() => {
                handleNonActiveDropdown();
                toggleSignIn();
              }}
              onKeyDown={() => {
                handleNonActiveDropdown();
                toggleSignIn();
              }}
            >
              Login
            </div>
            <Link
              to={{
                pathname: '/sign-up',
                state: { from: history.location.pathname },
              }}
              onClick={handleNonActiveDropdown}
            >
              <PButton size="small">{t('button_register')}</PButton>
            </Link>
          </div>
        </>
      );
    }
    return (
      <>
        <div>
          <NavItem
            name="CartNav"
            setDropdownActive={setDropdownActive}
            handleActiveMenu={handleActiveMenu}
            activeMenu={activeMenu}
            dropdownActive={dropdownActive}
            content={<Cart handleNonActiveDropdown={handleNonActiveDropdown} />}
            className="CartNav"
          />
        </div>
        <div>
          <NavItem
            name="ProfileNav"
            auth={auth}
            setDropdownActive={setDropdownActive}
            handleActiveMenu={handleActiveMenu}
            activeMenu={activeMenu}
            dropdownActive={dropdownActive}
            content={
              isDashboardPath && auth.user.email ? (
                <ProfileDashboard
                  handleNonActiveDropdown={handleNonActiveDropdown}
                />
              ) : (
                <Profile handleNonActiveDropdown={handleNonActiveDropdown} />
              )
            }
            className={`ProfileNav ${
              isDashboardPath && auth.user.email ? 'ProfileNavDashboard' : ''
            }`}
          />
        </div>
      </>
    );
  };

  if (location.pathname === '/sign-up' && !auth.user.email) {
    return (
      <nav
        className="navRoot d-none d-md-flex"
        style={{ paddingRight: '17px' }}
      >
        <div className="nav-brand mr-auto">
          <Link to="/" style={{ textDecoration: 'none' }}>
            <img src={Logo} alt="logo" className="logo-nav indexMenu" />
          </Link>
          <span className="separator" />
          <span className="d-none d-md-inline logo-name">
            Digital Technology School
          </span>
        </div>
        <div className="f-body-alt f--light-gray">
          {t('already_have_an_account')}
        </div>
        <div
          className="btn-login-nav pwdk-ml-2"
          onClick={() => {
            toggleSignIn();
          }}
          onKeyDown={() => {
            toggleSignIn();
          }}
        >
          Login
        </div>
      </nav>
    );
  }
  return (
    <nav
      className={`navRoot d-none d-md-flex pr-3 ${
        isCorporatePath ? 'corporate-training-nav' : ''
      }`}
      style={{ width: '100%' }}
    >
      <div
        className="togglerNav"
        onClick={handleNonActiveDropdown}
        onKeyDown={handleNonActiveDropdown}
      />
      {renderNavbarTitle()}
      <ul className="nav-primary ml-auto">{renderNavbarContent()}</ul>
      {renderNavbarUserProfile()}
    </nav>
  );
};
