import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Modal, ModalBody, Spinner } from 'reactstrap';
import { signIn, toggleModal, closeModal } from 'redux/modules/auth';
import { PButton, PMessage, PInput, PLabel } from 'components';
import cross from 'assets/css/ic_close.svg';
import 'assets/css/messages.css';
import 'assets/css/forms.css';
import 'assets/css/fonts.css';
import './signin.css';
import { useTranslation } from 'react-i18next';

const SignInError = ({ message }) => {
  const { t } = useTranslation('authentication', { keyPrefix: 'login' });

  if (message) {
    return (
      <PLabel color="red" className="w-100 mb-1">
        {t('error_message', message)}
      </PLabel>
    );
  }
  return null;
};

const SignInButton = ({ onClick, loggingIn }) => {
  const { t } = useTranslation('authentication', { keyPrefix: 'login' });

  if (loggingIn) {
    return (
      <PButton className="w-100 mb-4 d-flex align-items-center justify-content-center">
        <Spinner color="light" style={{ width: '20px', height: '20px' }} />
      </PButton>
    );
  }
  return (
    <PButton className="w-100 mb-4 f--16 font-weight-bold" onClick={onClick}>
      {t('button_login')}
    </PButton>
  );
};

const SignInModal = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isRememberMe, setIsRememberMe] = useState(true);
  const [isShowPass, setIsShowPass] = useState(false);

  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { t } = useTranslation('authentication', { keyPrefix: 'login' });

  const onSignInClick = () => {
    dispatch(signIn({ email, password, isRememberMe }));
  };

  const onEnter = (e) => {
    if (e.which === 13) {
      onSignInClick();
    }
  };

  const toggleIsOpen = () => {
    dispatch(toggleModal());
  };

  const resetState = () => {
    setEmail('');
    setPassword('');
    setIsRememberMe(true);
  };

  const focusToInput = () => {
    document.getElementById('emailinput').focus();
  };

  return (
    <Modal
      isOpen={auth.modalIsOpen}
      toggle={toggleIsOpen}
      onOpened={focusToInput}
      onClosed={resetState}
      data-testid="sign-up-modal"
    >
      <ModalBody className="mx-3 mt-4">
        <div
          className="pwdk-cross-button"
          onClick={toggleIsOpen}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              toggleIsOpen();
            }
          }}
          role="button"
          tabIndex={0}
        >
          <img src={cross} alt="close" className="cross-button" />
        </div>
        <h3 className="mb-4 f--weight-900 f--rich-black">Login</h3>
        {/* <div className="w-100 mb-4 border-line" /> */}
        <div>
          <SignInError message={auth.loginError} />
          <form>
            <PInput
              id="emailinput"
              className="mb-3"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              placeholder={t('email_placeholder')}
              required
              onKeyPress={onEnter}
              invalid="false"
            />
            <div className="pwdk-form-icon-container mb-3">
              <PInput
                id="passwordinput"
                type={isShowPass ? 'text' : 'password'}
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                placeholder={t('password_placeholder')}
                required
                onKeyPress={onEnter}
                Checked
                style={{ paddingRight: '3rem' }}
              />
              <span
                className={`pwdk-icon-adjustment ${
                  !isShowPass
                    ? 'icon-ic_visibility_off'
                    : 'icon-ic_visibility_on'
                }`}
                style={{ cursor: 'pointer' }}
                onClick={() => setIsShowPass(!isShowPass)}
                onKeyDown={() => setIsShowPass(!isShowPass)}
              />
            </div>
          </form>
          <div className="d-flex justify-content-between mb-3">
            <div className="pwdk-checkbox-container">
              <input
                type="checkbox"
                className="pwdk-form"
                defaultChecked
                onChange={(e) => setIsRememberMe(e.target.checked)}
              />{' '}
              <label>{t('ingat_saya')}</label>
            </div>
            <Link
              to="/forgot-password"
              onClick={() => dispatch(closeModal())}
              className="f--14 f--dark-gray link"
            >
              {t('lupa_password')}
            </Link>
          </div>
          <SignInButton onClick={onSignInClick} loggingIn={auth.loggingIn} />
          <div className="w-100 mb-4 border-line" />
          <PMessage className="d-flex justify-content-center mb-4 f--14 f--light-gray">
            <span className="mr-1">{t('belum_punya_akun')}</span>
            <Link
              to="/sign-up"
              onClick={() => dispatch(closeModal())}
              className="font-weight-bold f--light-gray link"
            >
              {t('button_register')}
            </Link>
          </PMessage>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default SignInModal;
