const crypto = require('crypto');
const { instagramSecret } = require('../config');

module.exports = {
  encrypt(key, data) {
    const cipher = crypto.createCipher('aes192', key);
    let crypted = cipher.update(data, 'utf-8', 'hex');
    crypted += cipher.final('hex');

    return crypted;
  },
  generateSig(endPoint, params) {
    let sig = endPoint;
    Object.keys(params).forEach((key) => {
      sig += `|${key}=${params[key]}`;
    });
    const hash = crypto
      .createHmac('sha256', instagramSecret)
      .update(sig)
      .digest('hex');

    return hash;
  },
};
