import React from 'react';
import { Helmet } from 'react-helmet';

const AdsConversionHelmet = () => {
  return (
    <div>
      <Helmet>
        <script>
          {`
          gtag('event', 'conversion', {
            'send_to': 'AW-863236390/aySICNXaksABEKbiz5sD'
        });
          `}
        </script>
      </Helmet>
    </div>
  );
};

export default AdsConversionHelmet;
