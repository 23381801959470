module.exports = {
  app: {
    key: 'epicseven',
    baseUrl: process.env.REACT_APP_BASE_URL,
    environment: process.env.REACT_APP_ENVIRONMENT,
  },
  midtrans: {
    clientKey: process.env.REACT_APP_MIDTRANS_CLIENT_KEY,
  },
  paypal: {
    clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
  },
  jwtKey: 'LaVieEnRose',
  instagramToken: '11665571870.1677ed0.0f3aeb69d350431a87c04ec81883de48',
  instagramSecret: 'e459e335761040dc8433213fba0c362d',
  mixpanelToken: process.env.REACT_APP_MIXPANEL_TOKEN,
  gtag: {
    source: 'https://www.googletagmanager.com/gtag/js?id=AW-863236390',
    code: 'AW-863236390',
  },
};
