import React from 'react';
import { Link } from 'react-router-dom';
import Mixpanel from 'supports/helpers/mixpanel';

export default ({ handleNonActiveDropdown }) => {
  return (
    <div className="pwdk-pl-4 pwdk-pr-4 pwdk-pt-4 pwdk-pb-4">
      {/* <div className="titleNav">CORPORATE</div> */}
      <div className="subNavBox">
        <Link
          to="/corporate-training"
          style={{ textDecoration: 'none' }}
          onClick={handleNonActiveDropdown}
        >
          <div className="subtitleNav">Corporate Training</div>
        </Link>
        {/* <Link
          to="/courses?topic=0&format=4&location=0"
          style={{ textDecoration: 'none' }}
          onClick={handleNonActiveDropdown}
        >
          <div className="subtitleNav">Corporate Short Course</div>
        </Link> */}
        <a
          href="https://pcn.purwadhika.com"
          style={{ textDecoration: 'none' }}
          onClick={() => {
            Mixpanel.track('Hire Our Graduates Click', {});
            handleNonActiveDropdown();
          }}
        >
          <div className="subtitleNav">Hire Our Graduates</div>
        </a>
      </div>
    </div>
  );
};
