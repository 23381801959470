import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, Progress } from 'reactstrap';
import { PInput, PTextArea, PButton } from 'components';
import axios from 'supports/api';
import doneicon from 'assets/images/svg/ic_done.svg';
import fileicon from 'assets/images/svg/ic_file.svg';
import chevrondown from 'assets/images/svg/form/chevrondown.svg';
import onsuccessimg from 'assets/images/modalonsuccess.png';
import addicon from 'assets/images/svg/ic_add.svg';
import closeicon from 'assets/images/svg/ic_close.svg';
import deleteicon from 'assets/images/svg/ic_delete.svg';
import Stepper from 'components/Stepper';

// import banner1 from 'assets/images/banner/homebanner1.png';

const INITIAL_FORM = {
  email: {
    value: '',
    placeholder: 'Alamat email kamu',
    invalidFeedback: 'Masukkan email Anda untuk mendaftar',
    invalid: false,
  },
  phone: {
    value: '',
    placeholder: 'No. telephone',
    invalidFeedback: 'Masukkan No Telepon anda untuk mendaftar',
    invalid: false,
  },
  firstname: {
    value: '',
    placeholder: 'Nama Depan',
    invalidFeedback: 'Masukkan nama depan Anda',
    invalid: false,
  },
  lastname: {
    value: '',
    placeholder: 'Nama Belakang',
    invalidFeedback: 'Masukkan nama belakang Anda',
    invalid: false,
  },
  linkedIn: {
    value: '',
    placeholder: 'Link profile LinkedIn kamu',
    invalidFeedback: 'Masukkan profile LinkedIn Anda',
    invalid: false,
  },
  address: {
    value: '',
    placeholder: 'Alamat tempat tinggal kamu',
    invalidFeedback: 'Masukkan alamat tempat tinggal kamu',
    invalid: false,
  },

  lastCompany: {
    value: '',
    placeholder: 'Nama perusahaan kamu saat ini/sebelumnya',
    invalidFeedback: 'Masukkan nama perusahaan kamu sekarang/sebelumnya',
    invalid: false,
  },
  lastEducation: {
    value: '',
    placeholder: 'Pendidikan terakhir kamu',
    invalidFeedback: 'Masukkan pendidikan terakhir anda',
    invalid: false,
  },
  lastProfession: {
    value: '',
    placeholder: 'Pekerjaan kamu saat ini ',
    invalidFeedback: 'Masukkan pekerjaan terakhir',
    invalid: false,
  },

  lastUniversity: {
    value: '',
    placeholder: 'Nama universitas terakhir kamu',
    invalidFeedback: 'Masukkan nama universitas terakhir kamu',
    invalid: false,
  },

  desiredPosition: {
    value: '',
    placeholder: 'Pilih posisi pengajar yang diinginkan',
    invalidFeedback: 'Pilih posisi pengajar yang diinginkan',
    invalid: false,
  },
  desiredBranch: {
    value: '',
    placeholder: 'Lokasi yang diinginkan',
    invalidFeedback: 'Masukkan Cabang yang anda inginkan',
    invalid: false,
  },
  expectedSalary: {
    value: '',
    placeholder: 'Gaji yang diharapkan',
    invalidFeedback: 'Masukkan nominal gaji yang anda harapkan',
    invalid: false,
  },
};

export default ({ open, toggle }) => {
  //   const [isOpen, setIsOpen] = useState(open);
  const [activeStep, setActiveSteps] = useState(0);
  const [input, setInput] = useState({ ...INITIAL_FORM });
  const [branches, setBranches] = useState([]);
  const [fileName, setFilename] = useState('');
  const [fileObj, setFileObj] = useState({});
  const [uploadProgress, setUploadProgress] = useState(0);
  // const [underlineBrowse, setUnderlineBrowse] = useState('');
  const [animationDrag, setAnimationDrag] = useState(false);

  const getBranches = () => {
    axios
      .get(`/branch/all`)
      .then((res) => {
        // this.setState({ branches: res.data.result });
        setBranches(res.data.result);
      })
      .catch((err) => {
        console.log(JSON.stringify(err));
      });
  };

  useEffect(() => {
    // getPositions();
    getBranches();
  }, []);

  const renderBranches = () => {
    const arrJSX = [];
    branches.forEach((item) => {
      if (item.id !== 3 && item.id !== 6 && item.id !== 7) {
        arrJSX.push(<option value={item.name}>{item.name}</option>);
      }
    });
    return arrJSX;
  };

  const handleChange = (elementName, event) => {
    // console.log(text);
    setInput({
      ...input,
      [elementName]: {
        ...input[elementName],
        value: event.target.value,
      },
    });
  };

  const handleImage = (value) => {
    if (value) {
      if (value.files[0] !== undefined) {
        setFilename(value.files[0].name);
        setFileObj(value.files[0]);
      }
    }
  };

  const checkInput = (array) => {
    // console.log(array);
    for (let i = 0; i < array.length; i += 1) {
      if (!array[i]) return true;
    }
    return false;
  };

  const renderInputFile = () => {
    if (fileName !== '' && uploadProgress !== 100) {
      return (
        <div
          style={{
            backgroundColor: 'rgba(179, 179, 179, 0.08)',
            width: '100%',
            paddingTop: '1rem',
            paddingBottom: '1rem',
            height: '93px',
          }}
          className="d-flex flex-column justify-content-center px-4"
        >
          <div
            className="f-subheading-bold d-flex flex-row justify-content-center"
            style={{ width: '100%' }}
          >
            <img src={fileicon} alt="iconfile" className="pwdk-mr-1" />
            {fileName}
            <img
              src={deleteicon}
              alt="deletefile"
              className="pwdk-ml-1 cursor-pointer"
              onClick={() => {
                setFileObj({});
                setFilename('');
                setAnimationDrag(false);
              }}
            />
            {uploadProgress === 0 ? null : (
              <div className="ml-auto f-subheading-bold f--light-gray">
                {uploadProgress}%
              </div>
            )}
          </div>
          {uploadProgress === 0 ? null : (
            <Progress
              value={uploadProgress}
              style={{ height: '5px', width: '100%' }}
            >
              {/* {this.state.uploadProgress}% */}
            </Progress>
          )}
        </div>
      );
    }
    if (uploadProgress === 100 && fileName !== '') {
      return (
        <div
          style={{
            backgroundColor: 'rgba(179, 179, 179, 0.08)',

            width: '100%',
            paddingTop: '1rem',
            paddingBottom: '1rem',
            height: '93px',
          }}
          className="d-flex flex-column justify-content-center px-4"
        >
          <div
            className="f-subheading-bold d-flex flex-row justify-content-center pwdk-mb-1 pt-3"
            style={{ width: '100%' }}
          >
            <img src={doneicon} alt="iconfile" />
          </div>
          <div
            className="f-subheading-bold d-flex flex-row justify-content-center pwdk-mb-2"
            style={{ width: '100%' }}
          >
            <img src={fileicon} alt="iconfile" />
            {fileName}
          </div>
        </div>
      );
    }
    return (
      <div
        className="text-center d-flex flex-column justify-content-center"
        style={{ height: '93px' }}
        // onMouseEnter={() =>
        //   setUnderlineBrowse({ underlineBrowse: 'underline' })
        // }
        // onMouseLeave={() => setUnderlineBrowse({ underlineBrowse: 'none' })}
      >
        <input
          type="file"
          style={{
            opacity: '0',
            position: 'absolute',
            height: '93px',
            width: '100%',
            zIndex: `${animationDrag ? '99' : '0'}`,
          }}
          id="proofImage"
          name="proofImage"
          onChange={() => {
            handleImage(document.getElementById(`proofImage`));
          }}
          onDragEnter={() => setAnimationDrag({ animationDrag: true })}
          onDragLeave={() => setAnimationDrag({ animationDrag: false })}
        />
        <div className="f-body-small font-weight-bold mb-2">
          {animationDrag ? null : '.pdf, .doc, .jpg, .png (10mb)'}
        </div>
        <div className="f-body-alt f--light-gray">
          {animationDrag ? null : 'Drag file CV/Portfolio kamu ke sini untuk'}
        </div>
        <div
          className="f-body-alt f--light-gray browsed-file"
          style={{ zIndex: '10' }}
          onClick={() => document.getElementById(`proofImage`).click()}
          onKeyDown={() => document.getElementById(`proofImage`).click()}
        >
          {animationDrag
            ? 'Drag file CV/Portofolio Disini'
            : 'mengunggah atau '}
          {animationDrag ? null : (
            <span className="f--brand-color f f--underline-onhover cursor-pointer">
              Browse
            </span>
          )}
        </div>
      </div>
    );
  };

  const renderInputFileMobile = () => {
    if (fileName !== '' && uploadProgress !== 100) {
      return (
        <div
          style={{
            backgroundColor: 'rgba(179, 179, 179, 0.08)',
            height: '93px',
            width: '100%',
            paddingTop: '1rem',
            paddingBottom: '1rem',
          }}
          className="d-flex flex-column justify-content-center px-4"
        >
          <div
            className="f-subheading-bold d-flex flex-column justify-content-center pwdk-mb-2"
            style={{ width: '100%' }}
          >
            <div className="d-flex flex-row justify-content-center">
              <img src={fileicon} alt="iconfile" />
              {fileName}
              <img
                src={deleteicon}
                alt="deletefile"
                className="pwdk-ml-1 cursor-pointer"
                onClick={() => {
                  setFileObj({});
                  setFilename('');
                  setAnimationDrag(false);
                }}
              />
            </div>
            {/* {uploadProgress === 0 ? null : (
              <div className="ml-auto f-subheading-bold f--light-gray">
                {uploadProgress}%
              </div>
            )} */}
          </div>
          <div
            className="d-flex flex-row justify-content-center"
            style={{ width: '100%' }}
          >
            {uploadProgress === 0 ? null : (
              <Progress
                value={uploadProgress}
                style={{ height: '5px', width: '60%' }}
              >
                {/* {uploadProgress}% */}
              </Progress>
            )}
          </div>
        </div>
      );
    }
    if (uploadProgress === 100 && fileName !== '') {
      return (
        <div
          style={{
            backgroundColor: 'rgba(179, 179, 179, 0.08)',
            height: '100%',
            width: '100%',
            paddingTop: '1rem',
            paddingBottom: '1rem',
          }}
          className="d-flex flex-column justify-content-center px-4"
        >
          <div
            className="f-subheading-bold d-flex flex-row justify-content-center pwdk-mb-1 pt-3"
            style={{ width: '100%' }}
          >
            <img src={doneicon} alt="iconfile" />
          </div>
          <div
            className="f-subheading-bold d-flex flex-row justify-content-center pwdk-mb-2"
            style={{ width: '100%' }}
          >
            <img src={fileicon} alt="iconfile" />
            {fileName}
          </div>
        </div>
      );
    }
    return (
      <div
        className="text-center"
        style={{ paddingTop: '24px', paddingBottom: '24px', height: '100%' }}
      >
        <input
          type="file"
          style={{
            opacity: '0',
            position: 'absolute',
            height: '93px',
            width: '100%',
            left: '0',
          }}
          id="proofimageMobile"
          name="proofimageMobile"
          onChange={() => {
            handleImage(document.getElementById(`proofimageMobile`));
          }}
        />
        <div className="f-mweb-body-small f--light-gray">
          Kami hanya menerima file dengan format
        </div>
        <div className="f-mweb-subhead-bold pwdk-mb-2">
          .PDF, .DOC, .JPG, .PNG (10mb)
        </div>
        <div className="d-flex flex-row justify-content-center">
          <PButton
            style={{
              backgroundColor: '#0197f6',
              borderRadius: '20px',
              paddingLeft: '32px',
              paddingRight: '32px',
            }}
          >
            <div className="d-flex flex-row justify-content-center">
              <img src={addicon} alt="logo" />
              <div className="pt-1">Unggah File</div>
            </div>
          </PButton>
        </div>
      </div>
    );
  };

  const onSendClick = () => {
    const formData = new FormData();
    formData.append('firstName', input.firstname.value);
    formData.append('lastName', input.lastname.value);
    formData.append('address', input.address.value);
    formData.append('email', input.email.value);
    formData.append('phone', input.phone.value);
    formData.append('linkedin', input.linkedIn.value);
    formData.append('lastProfession', input.lastProfession.value);
    formData.append('lastCompany', input.lastCompany.value);
    formData.append('lastEducation', input.lastEducation.value);
    formData.append('lastUniversity', input.lastUniversity.value);
    formData.append('desiredPosition', input.desiredPosition.value);
    formData.append('desiredBranch', input.desiredBranch.value);
    formData.append('expectedSalary', input.expectedSalary.value);
    formData.append('CV', fileObj);
    const headers = {
      onUploadProgress: (progressEvent) =>
        setUploadProgress(
          Math.round((progressEvent.loaded * 100) / progressEvent.total),
        ),
    };
    axios
      .post(`/enquiry/job-application`, formData, headers)
      .then(() => {
        window.alert('Terima kasih!, Formulir yang anda kirimkan berhasil');
        setActiveSteps(3); // Completed
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const renderForms = () => {
    if (activeStep === 0) {
      return (
        <div>
          <div className="row p-0 mb-3">
            <div className="col-md-6 pr-md-2 mb-3 mb-md-0">
              <div className="pwdk-form-icon-container">
                <PInput
                  type="text"
                  id="firstname"
                  name="firstname"
                  {...INITIAL_FORM.firstname}
                  onChange={(e) => handleChange('firstname', e)}
                  value={input.firstname.value}
                  required
                />
                <span className="font-icon-adjustment icon-ic_person_outline" />
              </div>
            </div>
            <div className="col-md-6 pl-md-2">
              <div className="pwdk-form-icon-container">
                <PInput
                  type="text"
                  id="lastname"
                  required
                  name="lastname"
                  {...INITIAL_FORM.lastname}
                  onChange={(e) => handleChange('lastname', e)}
                  value={input.lastname.value}
                />
                <span className="font-icon-adjustment icon-ic_person_outline" />
              </div>
            </div>
          </div>
          <div className="pwdk-form-icon-container mb-3">
            <PInput
              type="email"
              required
              id="email"
              name="email"
              {...INITIAL_FORM.email}
              onChange={(e) => handleChange('email', e)}
              value={input.email.value}
            />
            <span className="font-icon-adjustment icon-ic_mail" />
          </div>

          <div className="pwdk-form-icon-container mb-3">
            <PInput
              type="number"
              id="phone"
              required
              name="phone"
              {...INITIAL_FORM.phone}
              onChange={(e) => handleChange('phone', e)}
              value={input.phone.value}
            />
            <span className="font-icon-adjustment icon-ic_phone" />
          </div>
          <div className="pwdk-form-icon-container mb-3">
            <PInput
              type="text"
              id="linkedIn"
              required
              name="linkedIn"
              {...INITIAL_FORM.linkedIn}
              onChange={(e) => handleChange('linkedIn', e)}
              value={input.linkedIn.value}
            />
            {/* <span className="font-icon-adjustment icon-ic_phone" /> */}
          </div>
          <div className="pwdk-form-icon-container mb-3">
            <PTextArea
              required
              id="info"
              {...INITIAL_FORM.address}
              onChange={(e) => handleChange('address', e)}
              value={input.address.value}
            />
            {/* <span className="font-icon-adjustment icon-ic_phone" /> */}
          </div>

          <div className="mt-4 ">
            <div className="d-flex flex-row justify-content-md-end">
              <PButton
                className="button-lecturer-next"
                size="medium"
                color={
                  checkInput([
                    input.firstname.value,
                    input.lastname.value,
                    input.email.value,
                    input.phone.value,
                    input.address.value,
                    input.linkedIn.value,
                  ])
                    ? 'disabled'
                    : 'primary'
                }
                disabled={checkInput([
                  input.firstname.value,
                  input.lastname.value,
                  input.email.value,
                  input.phone.value,
                  input.address.value,
                  input.linkedIn.value,
                ])}
                // className={`${
                //   checkInput([
                //     input.firstname.value,
                //     input.lastname.value,
                //     input.email.value,
                //     input.phone.value,
                //     input.corporate.value,
                //     input.job.value,
                //     input.corporateaddress.value,
                //   ])
                //     ? 'btn-step'
                //     : ''
                // }`}
                onClick={() => setActiveSteps(1)}
              >
                Selanjutnya
              </PButton>
            </div>
          </div>
        </div>
      );
    }
    if (activeStep === 1) {
      return (
        <div>
          <div className="pwdk-form-icon-container mb-3">
            <PInput
              type="text"
              id="lastCompany"
              required
              name="lastCompany"
              {...INITIAL_FORM.lastCompany}
              onChange={(e) => handleChange('lastCompany', e)}
              value={input.lastCompany.value}
            />
            {/* <span className="font-icon-adjustment icon-ic_phone" /> */}
          </div>

          <select
            required
            className="py-3 pwdk-form pwdk-form-icon"
            style={{ width: '100%', backgroundImage: `url(${chevrondown})` }}
            onChange={(e) => handleChange('lastEducation', e)}
            value={input.lastEducation.value}
          >
            <option value="" disabled selected hidden>
              {INITIAL_FORM.lastEducation.placeholder}
            </option>
            <option value="SD">SD</option>
            <option value="SMP">SMP</option>
            <option value="SMA">SMA</option>
            <option value="SMK">SMK</option>
            <option value="S1">S1</option>
            <option value="S2">S2</option>
          </select>
          <select
            required
            className="py-3 pwdk-form pwdk-form-icon mt-3"
            style={{ width: '100%', backgroundImage: `url(${chevrondown})` }}
            onChange={(e) => handleChange('lastProfession', e)}
            value={input.lastProfession.value}
          >
            <option value="" disabled selected hidden>
              {INITIAL_FORM.lastProfession.placeholder}
            </option>
            <option value="Karyawan">Karyawan</option>
            <option value="Self Employed">Self Employed</option>
            <option value="Fresh Graduate">Fresh Graduate</option>
            <option value="Freelancer">Freelancer</option>
            <option value="Mahasiswa">Mahasiswa</option>
            <option value="SMA/SMK">SMA/SMK</option>
            <option value="SMP">SMP</option>
            <option value="SD">SD</option>
            <option value="Tidak Bekerja">Tidak Bekerja</option>
          </select>
          <div className="pwdk-form-icon-container my-3">
            <PInput
              type="text"
              id="lastUniversity"
              required
              name="lastUniversity"
              {...INITIAL_FORM.lastUniversity}
              onChange={(e) => handleChange('lastUniversity', e)}
              value={input.lastUniversity.value}
            />
            {/* <span className="font-icon-adjustment icon-ic_phone" /> */}
          </div>
          <div className="mt-4 ">
            <div className="d-flex flex-row justify-content-md-end">
              <PButton
                size="medium"
                className="button-lecturer-next"
                color={
                  checkInput([
                    input.lastEducation.value,
                    input.lastCompany.value,
                    input.lastProfession.value,
                    input.lastUniversity.value,
                  ])
                    ? 'disabled'
                    : 'primary'
                }
                disabled={checkInput([
                  input.lastEducation.value,
                  input.lastCompany.value,
                  input.lastProfession.value,
                  input.lastUniversity.value,
                ])}
                onClick={() => setActiveSteps(2)}
              >
                Selanjutnya
              </PButton>
            </div>
          </div>
        </div>
      );
    }
    if (activeStep === 2) {
      return (
        <div>
          <div className="pwdk-form-icon-container mb-3">
            <select
              required
              className="py-3 pwdk-form pwdk-form-icon "
              style={{ width: '100%', backgroundImage: `url(${chevrondown})` }}
              onChange={(e) => handleChange('desiredPosition', e)}
              value={input.desiredPosition.value}
            >
              <option value="" disabled selected hidden>
                {INITIAL_FORM.desiredPosition.placeholder}
              </option>
              <option value="   Web & Mobile Development Lecturer">
                {' '}
                Web & Mobile Development Lecturer
              </option>
              <option value="Digital Marketing Lecturer">
                Digital Marketing Lecturer
              </option>
              <option value="Data Science & Machine Learning Lecturer">
                Data Science & Machine Learning Lecturer
              </option>
              <option value="UI/UX Design Lecturer">
                UI/UX Design Lecturer
              </option>
              <option value="DevOps Engineering Lecturer">
                DevOps Engineering Lecturer
              </option>
            </select>
          </div>
          <div className="pwdk-form-icon-container mb-3">
            <select
              required
              className="py-3 pwdk-form pwdk-form-icon "
              style={{ width: '100%', backgroundImage: `url(${chevrondown})` }}
              onChange={(e) => handleChange('desiredBranch', e)}
              value={input.desiredBranch.value}
            >
              <option value="" disabled selected hidden>
                {INITIAL_FORM.desiredBranch.placeholder}
              </option>
              {renderBranches()}
            </select>
            {/* <span className="font-icon-adjustment icon-ic_phone" /> */}
          </div>
          <div className="pwdk-form-icon-container mb-md-3 mb-4">
            <PInput
              type="number"
              id="expectedSalary"
              required
              name="expectedSalary"
              {...INITIAL_FORM.expectedSalary}
              onChange={(e) => handleChange('expectedSalary', e)}
              value={input.expectedSalary.value}
            />
            {/* <span className="font-icon-adjustment icon-ic_phone" /> */}
          </div>
          <div className="f-md-body-alt font-weight-bold f--dark-gray f-mweb-body-default">
            Unggah file CV/Portfolio kamu disini
          </div>
          <div className="striped-box mt-2" style={{ position: 'relative' }}>
            <div className="d-sm-block d-none" style={{ width: '100%' }}>
              {renderInputFile()}
            </div>
            <div className="d-block d-sm-none" style={{ width: '100%' }}>
              {renderInputFileMobile()}
            </div>
          </div>
          <div className="d-flex flex-row justify-content-md-end mt-4">
            <PButton
              onClick={() => onSendClick()}
              className="button-lecturer-next"
              color={
                checkInput([
                  input.desiredBranch.value,
                  input.desiredPosition.value,
                  input.expectedSalary.value,
                  fileName,
                ])
                  ? 'disabled'
                  : 'primary'
              }
              disabled={checkInput([
                input.desiredBranch.value,
                input.desiredPosition.value,
                input.expectedSalary.value,
                fileName,
              ])}
            >
              Daftarkan Diri Saya
            </PButton>
          </div>
        </div>
      );
    }
    if (activeStep === 3) {
      return (
        <div>
          <div className="f-md-h3 f-mweb-h2 text-center pt-4 px-3 px-md-4">
            Terima kasih! Data kamu telah kami terima
          </div>
          <div className="px-md-3 mt-2">
            <div className="px-md-5 px-2 ">
              <img
                src={onsuccessimg}
                className="onsuccessimage"
                alt="success"
              />
            </div>
          </div>
          <div className="px-md-3 mt-4">
            <div className="px-md-5 px-2">
              <div className="text-center f-mweb-body-small f-body-alt f--dark-gray">
                Kami akan menghubungimu apabila tim kami merasa cocok dengan
                keahlian dan pengetahuan kamu untuk bergabung bersama kami.
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <div>
      <Modal
        isOpen={open}
        // toggle={() => setIsOpen(false)}
        toggle={toggle}
        size="lg"
        className="modalLecturer"
        style={{ borderRadius: '6px', border: 'none' }}
      >
        <div
          style={{
            position: 'absolute',
            top: '10px',
            right: '18px',
            cursor: 'pointer',
          }}
          onClick={toggle}
          onKeyDown={toggle}
        >
          <img src={closeicon} alt="closeicon" className="pt-2" />
        </div>
        {activeStep !== 3 ? (
          <ModalHeader
            style={{ padding: '16px 16px 16px 32px' }}
            className="d-block"
          >
            <div className="d-flex">
              <div
                style={{ fontWeight: 'bold' }}
                className="f-md-h4 f-mweb-h2  mr-auto"
              >
                Menjadi Pengajar
              </div>
              {/* <div onClick={toggle} onKeyPress>
        <img
          alt=" closebutton"
          src={closeicon}
          className="closeModalCorporate"
        />
      </div> */}
            </div>
          </ModalHeader>
        ) : null}

        <ModalBody style={{ padding: '24px 32px 40px 32px' }}>
          {activeStep !== 3 ? (
            <Stepper
              className="mb-4"
              // stepCount={steps.length}
              currentStep={activeStep}
              onStepperClick={setActiveSteps}
              stepCompleted={[false, false, false]}
              // true for completed steps
            />
          ) : null}

          {renderForms()}
        </ModalBody>
      </Modal>
    </div>
  );
};
