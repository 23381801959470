import React from 'react';

/**
 * 
 * Component untuk video player menerima props dengan list propsnya
  * 
  * @param {{linkVideo: string, 
  * iFrameTitle: string, 
  * classNameWrapper: string, 
  * styleWrapper: Object,
  * classNameContent: string,
  * styleContent: Object,
  * propsIframe: Object }}
  }, }}   
  * linkVideo = link video yang telah di embed ex. https://videos.sproutvideo.com/embed/ea9cd7b41319e0c563/df95eb0f71e318cc
  * iFrameTitle = Judul dari iframe
  * classNameWrapper = untuk memberi class yang membungkus iframe
  * styleWrapper = Object isinya adalah property css. defaultnya dibuat untuk responsive.
  * classNameContent = untuk memberi class pada iframe
  * styleContent = Object yang isinya property css untuk bagian iframe. defaultnya dibuat untuk responsive.
  * propsIframe =  Object yang isinya adalah props untuk iframe.
 */

export default ({
  linkVideo,
  iFrameTitle = 'Teaser-Hi-Res',
  classNameWrapper = '',
  styleWrapper = {
    position: 'relative',
    height: 0,
    paddingBottom: '56.25%',
    paddingTop: '1px',
    paddingLeft: '1px',
    paddingRight: '1px',
    borderRadius: '6px',
  },
  classNameContent = '',
  styleContent = {
    position: 'absolute',
    width: '100%',
    height: '100%',
    left: 0,
    borderRadius: '6px',
    top: 0,
  },
  propsIframe = {
    frameBorder: 0,
    allowFullScreen: true,
  },
}) => {
  return (
    <div className={classNameWrapper} style={styleWrapper}>
      <iframe
        className={classNameContent}
        src={linkVideo}
        style={styleContent}
        title={iFrameTitle}
        {...propsIframe}
      />
    </div>
  );
};
