import React from 'react';
import { Link } from 'react-router-dom';

export default ({ handleNonActiveDropdown }) => {
  return (
    <div className="pwdk-pl-4 pwdk-pr-4 pwdk-pt-4 pwdk-pb-4">
      <div className="subNavBox">
        <Link
          to="/alumni-stories"
          style={{ textDecoration: 'none' }}
          onClick={handleNonActiveDropdown}
        >
          <div className="subtitleNav">Alumni Stories</div>
        </Link>
        <Link
          to={{ pathname: 'https://alumni.purwadhika.com/' }}
          target="_blank"
          style={{ textDecoration: 'none' }}
          onClick={handleNonActiveDropdown}
        >
          <div className="subtitleNav">Alumni Club</div>
        </Link>
      </div>
    </div>
  );
};
