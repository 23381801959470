import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  numberQuestion: null,
  studentAnswer: [],
  scorePerQuestion: 0,
  scoreStudent: 0,
  correctCount: 0,
  wrongCount: 0,
  showQuiz: false,
  finishQuiz: false,
  showVideo: false,
  scoreAfterQuiz: [],

  completeStatus: null,

  onlineCourseId: '',
  onlineCourseTitle: '',
  onlineCourseCategory: '',
  onlineCourseType: '',
  onlineCourseAvgRating: 0,
  onlineCourseTotalReviews: 0,
  onlineCourseTotalEnroll: 0,
  programId: '',
  online_course_topicId: '',
  videoId: null,
  securityToken: null,
  titleVideoState: '',
  durationVideoState: '',
  descriptionVideoState: '',
  speakerVideoState: [],
  codeTopic: '',
  materiLocation: '',

  allVideoTopicList: [],

  userProgressionVideo: [],
  totalProgress: [],

  openModalComplete: false,
  // userTotalProgression: 0,
};

const { actions, reducer } = createSlice({
  name: 'quiz',
  initialState,
  reducers: {
    changeTitleOnlineCourse: (state, action) => ({
      ...state,
      onlineCourseId: action.payload.onlineCourseId,
      onlineCourseTitle: action.payload.onlineCourseTitle,
      onlineCourseCategory: action.payload.onlineCourseCategory,
      onlineCourseType: action.payload.onlineCourseCategoryType,
      onlineCourseAvgRating: action.payload.onlineCourseAvgRating,
      onlineCourseTotalReviews: action.payload.onlineCourseTotalReviews,
      onlineCourseTotalEnroll: action.payload.onlineCourseTotalEnroll,
      programId: action.payload.programId,
    }),
    resetQuiz: (state) => ({
      ...state,
      numberQuestion: null,
      studentAnswer: [],
      scorePerQuestion: 0,
      scoreStudent: 0,
      correctCount: 0,
      wrongCount: 0,
      showQuiz: false,
      finishQuiz: false,
      showVideo: true,
    }),
    changeQuestion: (state) => ({
      ...state,
      numberQuestion: state.numberQuestion + 1,
    }),
    addStudentAnswer: (state, action) => {
      state.studentAnswer.push(action.payload.answer);
    },
    showVideo: (state, action) => ({
      ...state,
      showVideo: true,
      showQuiz: false,
      online_course_topicId: action.payload.topicId,
      videoId: action.payload.videoId,
      securityToken: action.payload.securityToken,
      titleVideoState: action.payload.titleVideoState,
      durationVideoState: action.payload.durationVideo,
      descriptionVideoState: action.payload.descriptionVideo,
      speakerVideoState: action.payload.speakersVideo,
      locationMateri: action.payload.locationMateri,
      codeTopic: action.payload.codeVideo,
    }),
    showQuiz: (state) => ({
      ...state,
      showVideo: false,
      showQuiz: true,
      videoId: null,
      securityToken: null,
    }),
    startQuiz: (state, action) => ({
      ...state,
      numberQuestion: 0,
      studentAnswer: [],
      scorePerQuestion: action.payload.scorePerQuestion,
    }),
    changeScore: (state, action) => ({
      ...state,
      scoreStudent: state.scoreStudent + action.payload.score,
    }),
    finishQuiz: (state) => ({
      ...state,
      finishQuiz: true,
    }),
    updateCorrect: (state) => ({
      ...state,
      correctCount: state.correctCount + 1,
    }),
    updateWrong: (state) => ({
      ...state,
      wrongCount: state.wrongCount + 1,
    }),

    updateUserProgressionVideo: (state, action) => ({
      ...state,
      userProgressionVideo: [...action.payload].sort(
        (a, b) => a.online_course_topicId - b.online_course_topicId,
      ),
    }),
    updateTotalProgression: (state, action) => ({
      ...state,
      totalProgress: action.payload,
    }),

    updateVideoTopicList: (state, action) => ({
      ...state,
      allVideoTopicList: action.payload,
    }),

    toggleModalComplete: (state, action) => ({
      ...state,
      // openModalComplete: !state.openModalComplete,
      openModalComplete: action.payload,
    }),

    completeCourseStatus: (state, action) => ({
      ...state,
      completeStatus: action.payload,
    }),

    updateScoreAfterQuiz: (state, action) => ({
      ...state,
      scoreAfterQuiz: [...action.payload].sort(
        (a, b) => a.online_course_topicId - b.online_course_topicId,
      ),
    }),
  },
});

export default reducer;

export const {
  changeTitleOnlineCourse,
  changeQuestion,
  addStudentAnswer,
  showQuiz,
  showVideo,
  startQuiz,
  resetQuiz,
  changeScore,
  finishQuiz,
  updateCorrect,
  updateWrong,
  updateUserProgressionVideo,
  updateTotalProgression,
  updateVideoTopicList,
  toggleModalComplete,
  completeCourseStatus,
  updateScoreAfterQuiz,
} = actions;
