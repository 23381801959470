import { combineReducers } from '@reduxjs/toolkit';
import { reducer as firebase } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';

import auth from './auth';
import cart from './cart';
import quiz from './quiz';
import featureFlag from './feature-flag';
import studentLearning from './studentLearning';
import exam from './exam';
import geolocation from './geolocation';

export default combineReducers({
  auth,
  cart,
  quiz,
  featureFlag,
  studentLearning,
  exam,
  geolocation,
  firebase,
  firestore: firestoreReducer,
});
