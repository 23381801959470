import React, { useState, useEffect } from 'react';
import 'assets/css/forms.css';

const StepperLine = () => <hr className="stepper-line" />;

const Stepper = ({
  className,
  stepCompleted,
  currentStep,
  onStepperClick,
  allowChangeSteps = false,
}) => {
  const [activeStep, setactiveStep] = useState(currentStep);
  const [completed, setCompleted] = useState(stepCompleted);

  useEffect(() => {
    setCompleted(stepCompleted);
    setactiveStep(currentStep);
  }, [currentStep, stepCompleted]);

  const onClickStepper = (nextIndex) => {
    if (allowChangeSteps) {
      onStepperClick(nextIndex);
    } else if (nextIndex < currentStep) {
      onStepperClick(nextIndex);
    }
  };

  const Steps = () => {
    return completed.map((step, i) => {
      return (
        <React.Fragment key={String(i)}>
          <div
            className={`pwdk-stepper-${i <= activeStep ? 'primary' : 'alt'} ${
              i < currentStep ? 'cursor-pointer' : ''
            } font-weight-bold`}
            onClick={() => onClickStepper(i)}
            onKeyDown={() => onClickStepper(i)}
          >
            {`0${i + 1}`}
          </div>
          {completed.length - 1 !== i && <StepperLine />}
        </React.Fragment>
      );
    });
  };

  return (
    <div className={`d-flex flex-row ${className}`}>
      <Steps />
    </div>
  );
};

export default Stepper;
